import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import InputControl from '../../../../../components/Input';
import InputMask from '../../../../../components/MaskedInput';
import InputDate from '../../../../../components/DateInput';
import {
  cepMask,
  cnpjMask,
  phoneMask8,
  phoneMask9,
} from '../../../../../utils/masks';

import {
  Container, InputRow, InputSolo, ContentForm,
} from './styles';
import Select from '../../../../../components/Select';
import { PartnerContext } from '../../AgencyOfficeInfo';
import { UFList } from '../../../../../@types/Address';
import { employeeCountRangeService, selectService } from '../../../../../services';
import { InputCurrency, Textarea } from '../../../../../components';
import { InfoMode } from '../../../../../@types/InfoMode';
import { EmployeeCountRange } from '../../../../../@types/EmployeeCountRanges';

const PrimaryData = (): ReactElement => {
  const { t } = useTranslation();

  const { formik, mode } = useContext(PartnerContext);
  const modeView = mode === InfoMode.VIEW;
  const agencyOffice = formik?.values;

  const [selectGender, setSelectGender] = useState();
  const [selectEmployeeCountRange, setSelectEmployeeCountRange] = useState<EmployeeCountRange[]>();

  useEffect(() => {
    setSelectGender(selectService.getGender());
    fetchEmployeeCountRange();
  }, []);

  async function fetchEmployeeCountRange(): Promise<void> {
    try {
      const content = await employeeCountRangeService.getList();
      setSelectEmployeeCountRange(content);
    } catch (error) {
      console.log(error);
    }
  }

  function fieldName(name: string): string {
    return `company.${name}`;
  }

  return (
    <Container>
      <ContentForm>
        <h2>{t('agentPrimaryData.titlePartner') || ''}</h2>
        <InputRow>
          {/* <Select
            name={fieldName('typeUser')}
            label={t('agentPrimaryData.labelInputTypeAgent') || ''}
            options={selectGender}
            value={agencyOffice.company?.typeAgent}
            onChange={(selectedOption: any) => formik.setFieldValue(fieldName('typeUser'), selectedOption)}
            isDisabled
          /> */}

          <Select
            name={fieldName('enabled')}
            label={t('agentPrimaryData.labelInputStatus') || ''}
            options={selectGender}
            value={agencyOffice.company?.enabled}
            onChange={(selectedOption: any) => formik.setFieldValue(fieldName('enabled'), selectedOption)}
            isDisabled={modeView}
          />
        </InputRow>
      </ContentForm>

      <ContentForm>
        <h2>{t('agentPrimaryData.titleRegisterData')}</h2>

        <InputRow>
          <InputMask
            name={fieldName('documentNumber')}
            label={t('agentPrimaryData.labelInputCNPJ') || ''}
            placeholder="00.000.000/0000-00"
            mask={cnpjMask}
            guide={false}
            value={agencyOffice.company?.documentNumber}
            onChange={({ target: { value } }) => formik.setFieldValue(fieldName('documentNumber'), value?.replace(/\D/g, ''))}
            disabled={modeView}
          />

          <InputControl
            name={fieldName('companyName')}
            label={t('agentPrimaryData.labelInputCompanyName') || ''}
            placeholder={
              t('agentPrimaryData.placeholderInputCompanyName') || ''
            }
            value={agencyOffice.company?.legalName}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          <InputControl
            name={fieldName('tradeName')}
            label={t('agentPrimaryData.labelInputTradeName') || ''}
            placeholder={t('agentPrimaryData.placeholderInputTradeName') || ''}
            value={agencyOffice.company?.tradeName}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          {/* <Select
            name={fieldName('descriptionActivities')}
            label={
              t('agentPrimaryData.labelInputDescriptionActivities') || ''
            }
            options={selectGender}
            value={agencyOffice.company?.descriptionActivities}
            onChange={(selectedOption: any) => formik.setFieldValue(fieldName('descriptionActivities'), selectedOption)}
            isDisabled={modeView}
          /> */}
        </InputRow>

        <InputRow>
          {/* <Select
            name={fieldName('inc')}
            label={t('agentPrimaryData.labelInputInc') || ''}
            options={selectGender}
            value={agencyOffice?.company?.inc}
            onChange={(selectedOption: any) => formik.setFieldValue(fieldName('inc'), selectedOption)}
            isDisabled={modeView}
          /> */}
        </InputRow>
      </ContentForm>

      <ContentForm>
        <h2>{t('agentPrimaryData.titleAddress')}</h2>
        <InputRow>
          {/* <InputMask
            name="address.postalCode"
            label={t('agentPrimaryData.labelInputPostalCode') || ''}
            placeholder="00000-000"
            mask={cepMask}
            guide={false}
            value={partner?.address?.postalCode}
            onChange={({ target: { value } }) => formik.setFieldValue(
              'address.postalCode',
              value?.replace(/\D/g, ''),
            )}
            disabled={modeView}
          />

          <InputControl
            name="address.street"
            label={t('agentPrimaryData.labelInputStreet') || ''}
            placeholder={t('agentPrimaryData.placeholderInputStreet') || ''}
            value={partner?.address?.street || ''}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <InputControl
            name="address.number"
            label={t('agentPrimaryData.labelInputNumber') || ''}
            placeholder={t('agentPrimaryData.placeholderInputNumber') || ''}
            value={partner?.address?.number || ''}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          <InputControl
            name="address.complement"
            label={t('agentPrimaryData.labelInputComplement') || ''}
            placeholder={
              t('agentPrimaryData.placeholderInputComplement') || ''
            }
            value={partner?.address?.complement || ''}
            onChange={formik.handleChange}
            disabled={modeView}
          /> */}
        </InputRow>

        <InputRow>
          {/* <InputControl
            name="address.neighborhood"
            label={t('agentPrimaryData.labelInputNeighborhood') || ''}
            placeholder={
              t('agentPrimaryData.placeholderInputNeighborhood') || ''
            }
            value={partner?.address?.neighborhood || ''}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <InputControl
            name="address.city"
            label={t('agentPrimaryData.labelInputCity') || ''}
            placeholder={t('agentPrimaryData.placeholderInputCity') || ''}
            value={partner?.address?.city || ''}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <Select
            name="address.state"
            label={t('agentPrimaryData.labelInputUF') || ''}
            placeholder={t('agentPrimaryData.placeholderInputUF') || ''}
            options={UFList}
            value={partner?.address?.state || ''}
            onChange={(selectedOption: any) => formik.setFieldValue('address.state', selectedOption)}
            isDisabled={modeView}
          /> */}
        </InputRow>
      </ContentForm>

      <ContentForm>
        <h2>{t('agentPrimaryData.titleContacts')}</h2>
        <InputRow>
          {/* <InputMask
            name="telephone"
            label={t('agentPrimaryData.labelInputTelephone') || ''}
            placeholder="+55 51 9999 9999"
            mask={phoneMask8}
            guide={false}
            value={agencyOffice.company?.telephone}
            onChange={({ target: { value } }) => formik.setFieldValue('telephone', value?.replace(/\D/g, ''))}
            disabled={modeView}
          />
          <InputMask
            name="phone"
            label={t('agentPrimaryData.labelInputPhone') || ''}
            placeholder="+55 51 9 9999 9999"
            mask={phoneMask9}
            guide={false}
            value={agencyOffice.company?.phone}
            onChange={({ target: { value } }) => formik.setFieldValue('phone', value?.replace(/\D/g, ''))}
            disabled={modeView}
          />

          <InputControl
            name="email"
            label={t('agentPrimaryData.labelInputEmail') || ''}
            placeholder={t('agentPrimaryData.placeholderInputEmail') || ''}
            type="email"
            value={agencyOffice.company?.email}
            onChange={formik.handleChange}
            disabled={modeView}
          /> */}
        </InputRow>
      </ContentForm>
      <ContentForm>
        <h2>{t('agentPrimaryData.titleAdditionalInformation')}</h2>
        <InputRow>
          <InputDate
            name={fieldName('incorporationDate')}
            label={t('agentPrimaryData.labelInputConstitutionDate') || ''}
            selected={agencyOffice.company?.incorporationDate}
            onChange={(value: any) => {
              formik.setFieldValue(fieldName('incorporationDate'), value);
            }}
            disabled={modeView}
          />
          <InputCurrency
            name={fieldName('shareCapital')}
            label={t('agentPrimaryData.labelInputSocialCapital') || ''}
            value={agencyOffice.company?.shareCapital}
            onStringChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
            disabled={modeView}
          />

          <InputCurrency
            name={fieldName('equity')}
            label={t('agentPrimaryData.labelInputNetWorth') || ''}
            value={agencyOffice.company?.equity}
            onStringChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
            disabled={modeView}
          />

          <Select
            name={fieldName('employeeCountRangeID')}
            label={t('agentPrimaryData.labelInputNumberEmployees') || ''}
            placeholder={
              t('agentPrimaryData.placeholderInputNumberEmployees') || ''
            }
            options={selectEmployeeCountRange}
            value={
              agencyOffice?.company?.employeeCountRangeID
                ? selectEmployeeCountRange?.find(
                  (e) => e.id === agencyOffice?.company?.employeeCountRangeID,
                )
                : null
            }
            onChange={(selectedOption: any) => formik.setFieldValue(
              fieldName('employeeCountRangeID'),
              selectedOption.id,
            )}
            isDisabled={modeView}
          />
        </InputRow>
        <InputRow>
          <Select
            name={fieldName('companyRegistrationStatusID')}
            label={t('agentPrimaryData.labelInputRegistrationStatus') || ''}
            placeholder={
              t('agentPrimaryData.placeholderInputRegistrationStatus') || ''
            }
            options={UFList}
            value={agencyOffice.company?.companyRegistrationStatusID}
            onChange={(selectedOption: any) => formik.setFieldValue(
              fieldName('companyRegistrationStatusID'),
              selectedOption,
            )}
            isDisabled={modeView}
          />

          <InputControl
            name={fieldName('stateTaxID')}
            label={t('agentPrimaryData.labelInputInscriptionState') || ''}
            placeholder={
              t('agentPrimaryData.placeholderInputInscriptionState') || ''
            }
            value={agencyOffice.company?.stateTaxID}
            onChange={formik.handleChange}
            disabled={modeView}
          />
        </InputRow>

        <InputSolo>
          <Textarea
            name={fieldName('description')}
            label={t('agentPrimaryData.labelInputObservation') || ''}
            placeholder={
              t('agentPrimaryData.placeholderInputObservation') || ''
            }
            rows={4}
            cols={50}
            value={agencyOffice.company?.description}
            onChange={formik.handleChange}
            disabled={modeView}
          />
        </InputSolo>
      </ContentForm>
    </Container>
  );
};

export default PrimaryData;
