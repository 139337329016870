import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

export const LeftContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    color: ${theme.colors.black00};
    opacity: 0.5;
    ${theme.texts.paragraphSM};

    > p {
      margin: 0 3px;
    }
  `};
`;

export const RightContent = styled.div`
  ${({ theme }) => css`
    display: flex;

    ${theme.texts.buttonSM};
    letter-spacing: -0.1px;

    > button {
      background-color: transparent;
      ${theme.texts.buttonSM};
      font-weight: 400;

      &:not(:last-child) {
        margin: 0 16px 0 0;
      }

      > svg {
        font-size: 1.6rem;
      }

      &.active {
        color: ${theme.colors.black00};
        font-weight: 600;
      }
    }
  `};
`;
