import styled from 'styled-components';

export const Container = styled.div``;

export const InputRow = styled.div`
display: flex;
  justify-content: space-between;
  flex: 1;

  margin-bottom: 24px;

  > div {
    width: 100%;

    :not(:last-child) {
      margin-right: 12px;
    }
  }

`;
