import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import InputControl from '../../../../../../components/Input';

import { InfoMode } from '../../../../../../@types/InfoMode';
import { InputCurrency, Textarea } from '../../../../../../components';
import Select from '../../../../../../components/Select';
import Switch from '../../../../../../components/Switch';
import { selectService } from '../../../../../../services';
import { ProductContext } from '../../../ProductInfo';
import { FormProductVariantProps } from './FormProductVariant.spec';
import {
  Container, ContentForm, InputRow, InputSolo,
} from './styles';

const FormProductVariant = ({
  formik,
}: FormProductVariantProps): ReactElement => {
  const { t } = useTranslation();

  const { mode } = useContext(ProductContext);

  const [selectGender, setSelectGender] = useState();

  useEffect(() => {
    setSelectGender(selectService.getGender());
  }, []);

  const modeView = mode === InfoMode.VIEW;

  return (
    <Container>
      <ContentForm>
        <InputRow>
          <InputControl
            name="name"
            label={t('productVariant.labelInputName') || ''}
            placeholder={
              t('productVariant.placeholderInputName') || ''
            }
            value={formik.values.name || ''}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <Switch
            name="enabled"
            label={t('productVariant.labelInputStatus') || ''}
            checked={formik.values.enabled}
            onChange={formik.handleChange}
            disabled={modeView}
          />
        </InputRow>
        <InputRow>
          <Select
            name="customerTypeID"
            label={t('productVariant.labelInputCustomerType') || ''}
            options={selectGender}
            value={formik.values.customerTypeID}
            onChange={(selectedOption: any) => formik.setFieldValue('customerTypeID', selectedOption)}
            isDisabled={modeView}
          />

          <InputCurrency
            name="fee"
            label={t('productVariant.labelInputRate') || ''}
            prefix="%"
            value={formik.values?.fee || ''}
            onStringChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
            disabled={mode === InfoMode.VIEW}
          />

          <Select
            name="tacIsPercentage"
            label={t('productVariant.labelInputTacType') || ''}
            options={selectGender}
            value={formik.values.tacIsPercentage}
            onChange={(selectedOption: any) => formik.setFieldValue('tacIsPercentage', selectedOption)}
            isDisabled={modeView}
          />

          <InputCurrency
            name="tac"
            label={t('productVariant.labelInputTac') || ''}
            prefix="%"
            value={formik.values.tac || ''}
            onStringChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
            disabled={mode === InfoMode.VIEW}
          />
        </InputRow>

        <InputRow>
          <InputControl
            name="minInstallmentQuantity"
            label={
              t('productVariant.labelInputMinimumInstallment') || ''
            }
            placeholder={
              t('productVariant.placeholderInputMinimumInstallment')
              || ''
            }
            type="number"
            value={formik.values.minInstallmentQuantity || ''}
            onChange={formik.handleChange}
            disabled={modeView}
            required
          />

          <InputControl
            name="maxInstallmentQuantity"
            label={
              t('productVariant.labelInputMaximumInstallment') || ''
            }
            placeholder={
              t('productVariant.placeholderInputMaximumInstallment')
              || ''
            }
            type="number"
            value={formik.values.maxInstallmentQuantity || ''}
            onChange={formik.handleChange}
            disabled={modeView}
            required
          />

          <InputCurrency
            label={t('productVariant.labelInputMinimumValue') || ''}
            name="minValue"
            value={formik.values.minValue || ''}
            onStringChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
            disabled={modeView}
            required
          />
          <InputCurrency
            label={t('productVariant.labelInputMaximumValue') || ''}
            name="maxValue"
            value={formik.values.maxValue || ''}
            onStringChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
            disabled={modeView}
            required
          />
        </InputRow>
        <InputRow>
          <InputControl
            name="gracePeriod"
            label={t('productVariant.labelInputShortageDays') || ''}
            placeholder={
              t('productVariant.placeholderInputShortageDays') || ''
            }
            type="number"
            value={
              formik.values.gracePeriod || 0
            }
            onChange={formik.handleChange}
            disabled={modeView}
          />

          <InputControl
            name="minAge"
            label={t('productVariant.labelInputMinimumAge') || ''}
            placeholder={
              t('productVariant.placeholderInputMinimumAge') || ''
            }
            type="number"
            value={formik.values.minAge || 0}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <InputControl
            name="maxAge"
            label={t('productVariant.labelInputMaximumAge') || ''}
            placeholder={
              t('productVariant.placeholderInputMaximumAge') || ''
            }
            type="number"
            value={formik.values.maxAge || 0}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          <InputCurrency
            name="insurance"
            label={t('productVariant.labelInputIndemnity') || ''}
            value={formik.values.insurance || ''}
            onStringChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
            disabled={modeView}
          />
        </InputRow>

        <InputRow>
          <Select
            name="agencyFeeIsPercentage"
            label={t('productVariant.labelInputReturnType') || ''}
            options={selectGender}
            value={
              formik.values.agencyFeeIsPercentage
            }
            onChange={(selectedOption: any) => formik.setFieldValue('agencyFeeIsPercentage', selectedOption)}
            isDisabled={modeView}
          />
          <InputCurrency
            name="agencyFee"
            label={t('productVariant.labelInputReturn') || ''}
            prefix="%"
            value={formik.values.agencyFee || ''}
            onStringChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
            disabled={modeView}
          />

          <InputCurrency
            name="incomeCommitment"
            label="Comprometimento de renda"
            prefix="%"
            value={
              formik.values.incomeCommitment

              || ''
            }
            onStringChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
            disabled={modeView}
          />
        </InputRow>

        <InputSolo>
          <Textarea
            name="description"
            label={t('productVariant.labelInputDescription') || ''}
            placeholder={
              t('productVariant.placeholderInputDescription') || ''
            }
            value={
              formik.values.description || ''
            }
            onChange={formik.handleChange}
            rows={4}
            cols={50}
            disabled={modeView}
          />
        </InputSolo>
      </ContentForm>
    </Container>
  );
};

export default FormProductVariant;
