import { WorkflowControlItemsProps } from '../@types';

export const operationDataItems: WorkflowControlItemsProps[] = [
  {
    mainTitle: {
      title: 'Dados da operação',
      checked: true,
      type: 'checkbox',
    },
    items: [
      { title: 'Valor do financiamento/empréstimo', checked: false },
      { title: 'Número de parcelas', checked: false },
      { title: 'Valor da TAC', checked: true },
      { title: 'Data do 1º vencimento', checked: false },
      { title: 'Taxa da operação', checked: true },
      { title: 'IOF ao dia', checked: true },
      { title: 'Taxa do IOF', checked: true },
      { title: 'Parcela máxima', checked: true },
      { title: 'Venda presencial', checked: false },
    ],
  },
];
