import React, { ReactElement } from 'react';
import { useDrop } from 'react-dnd';

import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core';

import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToParentElement,
} from '@dnd-kit/modifiers';

import CustomizableStepItem from '../CustomizableStepItem';

import {
  Container,
  CustomizableSteps,
  CustomizableStepItems,
  DraggableBox,
  EmptySteps,
} from './styles';

const DroppableArea = ({ item, handleChange, handleOrderChange }: any): ReactElement => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: item?.type,
    drop: (e: any) => { handleChange(e?.childrenItem, item?.id); },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  return (
    <Container ref={drop} data-testid="workflowManagement">
      {/* Custom steps dragged from steps list */}
      <CustomizableSteps>
        <small>{item?.name}</small>
        <CustomizableStepItems>
          {isActive || canDrop ? (
            <DraggableBox isActive={isActive}>
              <h3>Solte aqui</h3>
            </DraggableBox>
          ) : null}
          {!item?.items?.length && !canDrop && (
            <EmptySteps>
              <p>Nenhuma etapa adicionada nesta seção.</p>
            </EmptySteps>
          )}
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToParentElement]}
          >
            <SortableContext
              items={item?.items}
              strategy={verticalListSortingStrategy}
            >
              {item?.items?.map((it: any) => (
                <CustomizableStepItem key={it?.id} item={it} />
              ))}
            </SortableContext>
          </DndContext>
        </CustomizableStepItems>
      </CustomizableSteps>
    </Container>
  );

  function handleDragEnd(event: any): any {
    const { active, over } = event;

    if (active.id !== over.id) {
      handleOrderChange(over.id, active.id, item?.id);
    }
  }
};

export default DroppableArea;
