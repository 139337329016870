import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex: 1;
  min-height: 100%;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h1 {
      color: ${theme.colors.black00};
      letter-spacing: -0.01em;
      line-height: 3rem;
      font-size: 2.4rem;
      font-weight: 600;

      display: flex;
      flex-direction: column;

      > small {
        font-weight: 400;
        opacity: 0.5;
        font-size: 1.3rem;
        line-height: 1.6rem;
        margin-bottom: 5px;
      }
    }

    > button {
      border: 1px solid rgba(20, 18, 30, 0.1);
      border-radius: 4px;
      height: 36px;
      color: ${theme.colors.black00};
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.6rem;
      padding: 10px 18px;
      background-color: transparent;
      margin-top: auto;
    }
  `};
`;

export const SubHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 20px 0 0 0;

    > a {
      > button {
        min-width: 140px;
        width: 100%;
        max-width: 140px;
        ${theme.texts.buttonXS};
        max-height: 46px;
        height: 100%;
        border-radius: 4px;
      }
    }
  `};
`;

export const SearchInput = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    max-height: 46px;
    height: 100%;
    flex: 1;
    margin: 0 12px 0 0;

    position: relative;

    > input {
      background-color: ${theme.colors.black05};
      border-radius: 4px;
      height: 46px;
      width: 100%;
      border-radius: 4px;
      border: 0;
      padding: 12px 18px 12px 40px;
      ${theme.texts.paragraphMD};
      border: 1px solid ${theme.colors.black05};

      transition: ${theme.transitions.default};

      &::placeholder {
        color: ${theme.colors.black50};
      }

      &:focus {
        border-color: ${theme.colors.black00};
      }
    }

    > svg {
      position: absolute;
      left: 18px;
      font-size: 1.6rem;
    }
  `}
`;
