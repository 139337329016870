import { DynamicTagsProps } from '../../@types/DynamicTags';
import { CoreWorkflow } from '../../@types/ScoreParameterizations';
import { format } from '../../utils/masks';

export interface ScoreParameterizationsProps {}

export function getInitialValues(
  scoreParameterization: CoreWorkflow,
): CoreWorkflow {
  return {
    scoreParameterizations:
      scoreParameterization?.scoreParameterizations?.map?.((item: any) => ({
        id: item.id || '',
        name: item.name || '',
        scoreMinimum: item.scoreMinimum || '',
        scoreMaximum: item.scoreMaximum || '',
        classification: item.classification || '',
        fee: item.fee || '',
        maximumLoanAmount: item.maximumLoanAmount || '',
        operationEntryStatus: item.operationEntryStatus || '',
        tags: [
          { title: 'rate', value: 0, bgColor: 'red' },
        ],
      })) || [],
  };
}

export const dynamicTags: DynamicTagsProps[] = [
  {
    title: 'Score mínimo:',
    field: 'scoreMinimum',
    bgColor: '#EDFCF2',
  },
  {
    title: 'Score máximo:',
    field: 'scoreMaximum',
    bgColor: '#EDFCF2',
  },
  {
    title: 'Taxa:',
    field: 'fee',
    bgColor: '#FCEDED',
    suffix: '%',
    labelFormatter: (value: any) => format(value),
  },
  {
    title: 'Valor máx. de emprésti.:',
    field: 'maximumLoanAmount',
    bgColor: '#FCEDED',
    prefix: 'R$',
    labelFormatter: (value: any) => format(value),
  },
  {
    title: 'Status:',
    field: 'operationEntryStatus',
    bgColor: '#FCF9ED',
  }];
