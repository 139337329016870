import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.black05};
    min-height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const SignInForm = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white00};
    width: 100%;
    max-width: 537px;
    margin: 0 auto;
    border-radius: 4px;
    padding: 44px;
  `};
`;

export const FormHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > a {
      color: ${theme.colors.black00};
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.8rem;

      display: flex;
      align-items: center;

      > svg {
        font-size: 1.4rem;
        margin-left: 4px;
      }
    }
  `};
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 100%;
    /* max-width: 112px; */
  }
`;

export const Form = styled.form`
  ${({ theme }) => css`
    margin-bottom: 18px;

    > button {
      background-color: ${theme.colors.black00};
      border-radius: 4px;
      height: 60px;
      width: 100%;
      color: ${theme.colors.white00};
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 2.2rem;
      margin-top: 18px;

      transition: ${theme.transitions.default};

      display: flex;

      &:hover {
        opacity: 0.8;
      }
    }
  `};
`;

export const FormTexts = styled.div`
  ${({ theme }) => css`
    margin: 44px 0;

    > h1 {
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 2.3rem;
      letter-spacing: -0.03em;
      color: ${theme.colors.black00};
      margin-bottom: 7px;
    }

    > p {
      color: ${theme.colors.black50};
      font-size: 1.4rem;
      line-height: 2.2rem;
      font-weight: 400;
    }
  `};
`;

export const InputControl = styled.div`
  ${({ theme }) => css`
    margin-bottom: 12px;
    position: relative;

    display: flex;
    flex-direction: column;

    > small {
      color: ${theme.colors.black00};
      font-size: 1.4rem;
      line-height: 2.2rem;
      font-weight: 400;
      margin-bottom: 10px;
    }

    > input {
      border-radius: 4px;
      background-color: ${theme.colors.black05};
      height: 60px;
      border: 0;
      padding: 20px;
      border: 1px solid ${theme.colors.black05};
      font-size: 1.3rem;

      transition: ${theme.transitions.default};

      display: flex;
      align-items: center;

      &::placeholder {
        color: ${theme.colors.black00};
        opacity: 0.2;
      }

      &:active,
      &:focus {
        border-color: ${theme.colors.black00};
      }
    }

    > button {
      background-color: transparent;
      font-size: 1.3rem;
      line-height: 1.6rem;
      color: ${theme.colors.black00};

      position: absolute;
      top: calc(50% + 10px);
      right: 24px;

      > svg {
        color: ${theme.colors.black00};
      }
    }
  `};
`;

export const Support = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    > small {
      color: ${theme.colors.black00};
      opacity: 0.5;
      font-weight: 400;
      font-size: 1.3rem;
      line-height: 2.2rem;
    }
  `};
`;

export const KeepConnected = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.black00};
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2.2rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;

export const CheckBoxControl = styled.div`
  display: flex;
  align-items: center;
`;

export const ForgotPassword = styled.span`
  ${({ theme }) => css`
    > a {
      color: ${theme.colors.black00};
    }
  `};
`;
