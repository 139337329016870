import styled, { css, keyframes } from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255, 0.8);

  z-index: 99;
  
  left: 0px;
  @media (max-width: 480px) {
  }
`;

export const LoadingCircle = styled.div`
  ${() => css`

    border-radius: 50%;
    display: inline-block;

    border: 4px solid #f3f3f3;
    border-top: 4px solid #1E1E1E;

    animation: ${spinAnimation} 2s linear infinite;
    width: 64px;
    height: 64px;
  `}
`;

export const Circle = styled.div`
`;

const spinAnimation = keyframes`
  0% { transform: rotate(-360deg); }
  100% { transform: rotate(0deg); }
`;
