import React, { ReactElement, useState } from 'react';

import DroppableArea from './DroppableArea';

import { Select, ExcludeModal } from '../../../../../../components';

import {
  Container,
  ManagementHeader,
  WorkflowSelect,
  ManagementHeaderButtons,
  WorkflowManagementContent,
  // RequiredSteps,
  // RequiredStepItems,
  // RequiredStepItem,
  // RequiredStepItemTexts,
} from './styles';

interface WorkflowManagement {
  items?: any;
  handleOrderChange: (oldIndex: number, newIndex: number, id: number) => any;
  handleViewMode: () => void;
  handleChange: any;
}

const WorkflowManagement = ({
  items,
  handleOrderChange,
  handleViewMode,
  handleChange,
}: WorkflowManagement): ReactElement => {
  const [visibleExcludeModal, setVisibleExcludeModal] = useState<boolean>(false);

  return (
    <Container>
      <ManagementHeader>
        <small>Workflow:</small>
        <WorkflowSelect>
          <Select />
        </WorkflowSelect>
        <ManagementHeaderButtons>
          <button type="button">Salvar workflow</button>
          <button type="button" onClick={() => handleViewMode()}>
            Limpar workflow
          </button>
        </ManagementHeaderButtons>
      </ManagementHeader>

      <WorkflowManagementContent>
        <span>Início do workflow</span>
        {items?.map((item: any) => (
          <DroppableArea
            key={item?.name}
            item={item}
            handleChange={handleChange}
            handleOrderChange={handleOrderChange}
          />
        ))}
        <span>Fim do workflow</span>
      </WorkflowManagementContent>
      {visibleExcludeModal && (
        <ExcludeModal
          handleCloseModal={() => setVisibleExcludeModal(false)}
          handleDeleteConfirmation={() => items.splice(0, 1)}
          type="document"
        />
      )}
    </Container>
  );
};

export default WorkflowManagement;
