import styled from 'styled-components';

export const Container = styled.div``;

export const InputRow = styled.div`
  display: flex;

  flex: 1;
  flex-wrap: wrap;
  margin-bottom: 24px;
  > div {
    width: 24%;
    :not(:last-child) {
      margin-right: 12px;
    }
  }
`;

export const RowSwitch = styled.div`
  display: flex;

flex: 1;
flex-wrap: wrap;
margin-bottom: 24px;
> div {

  :not(:last-child) {
    margin-right: 12px;
  }
}
`;

export const InputSolo = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;

  margin-bottom: 24px;

  > div {
    width: 100%;
    margin-right: 12px;
  }
`;
