import React, {
  createContext,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  RiCheckboxCircleFill, RiCloseCircleFill, RiCloseFill,
} from 'react-icons/ri';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CoreFile, AgencyOfficeDTO } from '../../../../@types';
import { Breadcrumb, Button, TabNavigation } from '../../../../components';
import {
  agentsBreadCrumbItems,
} from '../../../../mock';
import {
  getInitialValues,
  PartnerContextValue,
  AgencyOfficeInfoProps,
} from './AgencyOfficeInfo.spec';

import {
  Container, Header, Form, ActionsButton, Divider,
} from './styles';
import { agencyOfficeService } from '../../../../services';
import { InfoMode } from '../../../../@types/InfoMode';
import { TabElementProps } from '../../../../@types/TabNavigation';
import PrimaryData from '../Breadcrumb/PrimaryData';
import BusinessPartner from '../Breadcrumb/Agent';
import Documents from '../Breadcrumb/Documents';
import Social from '../Breadcrumb/Social';

export const PartnerContext = createContext({} as PartnerContextValue);

const AgencyOfficeInfo = ({ mode }: AgencyOfficeInfoProps): ReactElement => {
  const { t } = useTranslation();
  const { id: agentID } = useParams();
  const navigate = useNavigate();
  const [agencyOffice, setAgencyOffice] = useState<AgencyOfficeDTO>({} as AgencyOfficeDTO);
  const [documents, setDocuments] = useState<CoreFile[]>([]);

  const initialValues = useMemo(() => getInitialValues(agencyOffice), [agencyOffice]);

  const tabNavigationItemsAgents = [
    { title: 'Dados primários', element: <PrimaryData />, enabled: true },
    {
      title: 'Sócios/representantes',
      element: <BusinessPartner />,
      enabled: mode !== InfoMode.CREATE,
    },
    {
      title: 'Documentos',
      element: <Documents />,
      enabled: mode !== InfoMode.CREATE,
    },
    {
      title: 'Social',
      element: <Social />,
      enabled: mode !== InfoMode.CREATE,
    },
  ] as TabElementProps;

  console.log(agentID);

  async function fetchData(): Promise<void> {
    try {
      if (agentID && +agentID) {
        const response = await agencyOfficeService.getOne(+agentID);
        setAgencyOffice(response);
      // if (response?.documents) {
      //   setDocuments(response?.documents);
      // }
      }
    } catch (err: any) {
      console.log(err);
    }
  }

  useEffect((): void => {
    if (mode !== InfoMode.CREATE) {
      fetchData();
    }
  }, [mode]);

  const formik = useFormik<AgencyOfficeDTO>({
    initialValues,
    enableReinitialize: true,
    async onSubmit(values: any) {
      if (mode === InfoMode.CREATE) {
        try {
          const response: any = await agencyOfficeService.create(values);
          toast.success('Agente criado com sucesso!', {
            className: 'toast-message',
            icon: RiCheckboxCircleFill,
            containerId: 'close-on',
          });
          navigate(`/registration/partners/edit/${response?.id}`);
        } catch (error) {
          console.log(error);
          toast.error('Não foi possível criar o agente', {
            className: 'toast-message',
            icon: RiCloseCircleFill,
            containerId: 'close-on',
          });
        }
      } else {
        const partnerData = Object.entries(values).reduce((acc, [key, value]) => {
          if (formik.initialValues[key as keyof AgencyOfficeDTO] !== value) {
            return {
              ...acc,
              [key]: value,
            };
          }
          return acc;
        }, {} as Partial<AgencyOfficeDTO>);

        try {
          if (agentID && +agentID) {
            await agencyOfficeService.update(+agentID, partnerData);
            navigate('/registration/partners');
          }
        } catch {
          toast.error('Não foi possível editar o agente', {
            className: 'toast-message',
            icon: RiCloseCircleFill,
            containerId: 'close-on',
          });
        }
      }
    },
  });

  return (
    <Container>
      <Header>
        <h1>
          <Breadcrumb breadCrumbItems={agentsBreadCrumbItems} />
          {t(`partnerInfo.${mode}.title`)}
        </h1>

        <Link to="/registration/partners">
          <RiCloseFill />
        </Link>
      </Header>
      <PartnerContext.Provider
        value={{
          formik,
          documents,
          setDocuments,
          mode,
        }}
      >
        <Form onSubmit={formik.handleSubmit}>
          <TabNavigation tabNavigationItems={tabNavigationItemsAgents} type="agente" />
          <Divider />
          <ActionsButton>
            <p>
              {/* <RiInformationFill />
              {' '}
              Os sócios/representantes cadastrados não
              receberão acesso ao sistema. Dados somente para registro interno. */}
            </p>
            {mode !== InfoMode.VIEW ? (
              <Button type="submit" message={t(`partnerInfo.${mode}.button`)} />
            ) : (
              <Link to={`/registration/partners/edit/${agentID}`}>
                {t(`partnerInfo.${mode}.button`)}
              </Link>
            )}
          </ActionsButton>
        </Form>
      </PartnerContext.Provider>
    </Container>
  );
};

export default AgencyOfficeInfo;
