export const TRANSLATIONS_PT_BR = {
  buttonLoadingMessage: 'Carregando...',
  sidebar: {
    home: 'Home',
    registration: 'Cadastros',
    users: 'Usuários',
    agencyOffice: 'Agentes',
    representatives: 'Representantes',
    products: 'Produtos',
    customers: 'Clientes',
    funds: 'Fundos',
    operations: 'Operacional',
    management: 'Gerenciamento',
    workflows: 'Workflows',
    help: 'Ajuda',
    logout: 'Sair',
  },
  signIn: {
    back: 'Voltar a homepage',
    title: 'Entre em sua conta',
    subTitle: 'Faça login e gerencie sua inteligência de crédito.',
    show: 'Mostrar',
    email: 'E-mail corporativo',
    emailPlaceholder: 'nome@empresa.com.br',
    password: 'Senha',
    accessText: 'Entrar',
    supportText: 'Não possui acesso? Solicite ao administrador.',
    keepConnected: 'Permanecer conectado',
    forgotPassword: 'Esqueceu sua senha?',
  },
  forgotPassword: {
    back: 'Voltar ao login',
    title: 'Esqueceu sua senha?',
    subTitle: 'Não se preocupe. Enviaremos um link para seu e-mail cadastrado com instruções de como resetar sua senha.',
    email: 'E-mail corporativo',
    emailPlaceholder: 'nome@empresa.com.br',
    button: 'Resetar senha',
  },
  userInfo: {
    CREATE: { title: 'Novo usuário', button: 'Criar usuário' },
    EDIT: { title: 'Editar usuário', button: 'Salvar' },
    VIEW: { title: 'Usuário', button: 'Fechar' },
  },
  newPassword: {
    back: 'Voltar ao login',
    title: 'Nova senha',
    subTitle: 'Crie sua nova senha para realizar seu login. A senha deve ser diferente da senha usada atualmente.',
    button: 'Criar nova senha',
    label: 'Nova senha',
    repeatLabel: 'Repetir nova senha',
  },
  userPrimaryData: {
    labelInputTypeUser: 'Tipo de usuário',
    labelInputPartner: 'Vinculo de agente',
    labelInputStatus: 'Status',
    labelInputName: 'Nome completo',
    labelInputCPF: 'CPF',
    labelInputCivilStatus: 'Estado civil',
    labelInputCEP: 'CEP',
    labelInputAddress: 'Endereço',
    labelInputNumber: 'Número',
    labelInputComplement: 'Complemento',
    labelInputNeighborhood: 'Bairro',
    labelInputCity: 'Cidade',
    labelInputUF: 'UF',
    labelInputTelephone: 'Telefone',
    labelInputPhone: 'Celular',
    labelInputNationality: 'Nacionalidade',
    labelInputBirthDate: 'Data de nascimento',
    labelInputGender: 'Sexo',
    labelInputRG: 'RG',
    labelInputIssuingAgency: 'Órgão expedidor',
    labelInputShippingDate: 'Data de expedição',
    labelInputEducation: 'Escolaridade',
    labelInputEmail: 'E-mail',
    labelInputMotherName: 'Nome da mãe',
    labelInputFatherName: 'Nome do pai',
    labelInputNaturalness: 'Naturalidade',
    labelInputUFNaturalness: 'UF (Naturalidade)',
    labelInputJurisdiction: 'Alçada do usuário',
    placeholderInputName: 'Digite seu nome completo',
    placeholderInputCPF: 'Digite seu CPF',
    placeholderInputCivilStatus: 'Selecione',
    placeholderInputCEP: 'Digite seu CEP',
    placeholderInputAddress: 'Digite seu endereço',
    placeholderInputNumber: 'Digite o número',
    placeholderInputComplement: 'Apto',
    placeholderInputNeighborhood: 'Digite o bairro',
    placeholderInputCity: 'Digite a cidade',
    placeholderInputUF: 'Selecione',
    placeholderInputTelephone: 'Digite o telefone',
    placeholderInputPhone: 'Digite o celular',
    placeholderInputNationality: 'Digite a nacionalidade',
    placeholderInputBirthDate: 'Digite a data de nascimento',
    placeholderInputGender: 'Selecione',
    placeholderInputRG: 'Digite o RG',
    placeholderInputIssuingAgency: 'Digite o órgão expedidor',
    placeholderInputShippingDate: 'Digite a data de expedição',
    placeholderInputEducation: 'Selecione',
    placeholderInputEmail: 'Digite o e-mail',
    placeholderInputMotherName: 'Digite o nome da mãe',
    placeholderInputFatherName: 'Digite o nome do pai',
    placeholderInputNaturalness: 'Digite a naturalidade',
    placeholderInputUFNaturalness: 'Selecione',
    titleUser: 'Usuário',
    titlePersonalData: 'Dados pessoais',
    titleJurisdiction: 'Alçada',
    titleAddress: 'Endereço',
    titleContacts: 'Contatos',
    titleFamily: 'Família',
  },
  userAccessControl: {
    titleAccessData: 'Dados de acesso',
    labelInputAccessDays: 'Dias de acesso',
    labelInputAccessTime: 'Horário de acesso',
    labelInputAccessExternal: 'Acesso externo',
    labelInputChangeRates: 'Alterar taxas',
  },
  uploadDocumentComponent: {
    titleDocument: 'Upload de arquivos',
    textDocument: 'Arraste seus documentos para esta área de transferência ou abra suas pastas clicando no botão abaixo:',
    sizeMaxDocument: 'Tamanho máximo: 50 MB',
    titleListDocument: 'Lista de documentos',
    textNoAttachment: 'Nenhum documento adicionado',
  },
  selectComponent: {
    placeholder: 'Selecione',
    noOptionsMessage: 'Não há opção',
  },
  productInfo: {
    CREATE: { title: 'Novo produto', button: 'Criar produto' },
    EDIT: { title: 'Editar produto', button: 'Salvar' },
    VIEW: { title: 'Produto', button: 'Editar' },
  },
  productData: {
    labelInputStatus: 'Status do produto',
    labelInputName: 'Nome do produto',
    labelInputMinimumInstallment: 'Parcela mínima',
    labelInputMaximumInstallment: 'Parcela máxima',
    labelInputMinimumValue: 'Valor mínimo',
    labelInputMaximumValue: 'Valor máximo',
    labelInputVehicleWarranty: 'Garantia veículos',
    labelInputGraceDays: 'Carência veículos',
    labelInputCustomer: 'Cliente',
    labelInputRate: 'Taxa',
    labelInputTac: 'TAC',
    labelInputIndemnity: 'Seguro',
    labelInputMinimumAge: 'Idade mínima',
    labelInputMaximumAge: 'Idade máxima',
    labelInputEntryValue: 'Valor de entrada',
    labelInputModality: 'Modalidade',
    labelInputSubmodality: 'Submodalidade',
    labelInputDescription: 'Descrição do produto',
    placeholderInputName: 'Digite o nome do produto',
    placeholderInputMinimumInstallment: '0,00',
    placeholderInputMaximumInstallment: '0,00',
    placeholderInputMinimumValue: '0,00',
    placeholderInputMaximumValue: '0,00',
    placeholderInputVehicleWarranty: '0,00',
    placeholderInputGraceDays: '0',
    placeholderInputCustomer: 'Selecione',
    placeholderInputRate: '0,00',
    placeholderInputTac: '0,00',
    placeholderInputIndemnity: '0,00',
    placeholderInputMinimumAge: '0',
    placeholderInputMaximumAge: '0',
    placeholderInputEntryValue: '0,00',
    placeholderInputModality: 'Selecione',
    placeholderInputSubmodality: 'Selecione',
    placeholderInputDescription: 'Mais detalhes sobre o produto',
  },
  productVariant: {
    labelInputStatus: 'Status da parametrização',
    labelInputName: 'Nome da parametrização',
    labelInputMinimumInstallment: 'Quantidade mínima de parcelas',
    labelInputMaximumInstallment: 'Quantidade máxima de parcelas',
    labelInputMinimumValue: 'Valor mínimo',
    labelInputMaximumValue: 'Valor máximo',
    labelInputShortageDays: 'Carência em dias',
    labelInputCustomerType: 'Tipo de cliente',
    labelInputRate: 'Taxa',
    labelInputTacType: 'Tipo de TAC',
    labelInputTac: 'TAC',
    labelInputIndemnity: 'Seguro',
    labelInputMinimumAge: 'Idade mínima',
    labelInputMaximumAge: 'Idade máxima',
    labelInputReturnType: 'Tipo de retorno',
    labelInputReturn: 'Retorno',
    labelInputDescription: 'Descrição da parametrização',
    placeholderInputName: 'Digite o nome da parametrização',
    placeholderInputMinimumInstallment: '0',
    placeholderInputMaximumInstallment: '0',
    placeholderInputMinimumValue: '0,00',
    placeholderInputMaximumValue: '0,00',
    placeholderInputVehicleWarranty: '0,00',
    placeholderInputShortageDays: '0',
    placeholderInputCustomer: 'Selecione',
    placeholderInputRate: '0,00',
    placeholderInputTac: '0,00',
    placeholderInputIndemnity: '0,00',
    placeholderInputMinimumAge: '0',
    placeholderInputMaximumAge: '0',
    placeholderInputEntryValue: '0,00',
    placeholderInputDescription: 'Mais detalhes sobre a parametrização',
    titleVariant: 'Lista de parametrizações',
    textFoundVariant: 'parametrização(ões) encontrada(s).',
    titleNoVariant: 'Nenhuma parametrização cadastrada',
    textNoVariant: 'Comece adicionando alguma parametrização através do botão a sua direita.',
  },
  partnerInfo: {
    CREATE: { title: 'Novo agente', button: 'Criar agente' },
    EDIT: { title: 'Editar agente', button: 'Salvar' },
    VIEW: { title: 'Agente', button: 'Fechar' },
  },
  agentPrimaryData: {
    labelInputTypeAgent: 'Tipo de agente',
    labelInputStatus: 'Status',
    labelInputCNPJ: 'CNPJ',
    labelInputCompanyName: 'Razão social',
    labelInputTradeName: 'Nome fantasia',
    labelInputDescriptionActivities: 'Descrição das atividades',
    labelInputInc: 'Porte',
    labelInputPostalCode: 'CEP',
    labelInputStreet: 'Endereço comercial',
    labelInputNumber: 'Número',
    labelInputComplement: 'Complemento',
    labelInputNeighborhood: 'Bairro',
    labelInputCity: 'Cidade',
    labelInputUF: 'UF',
    labelInputEmail: 'E-mail',
    labelInputTelephone: 'Telefone',
    labelInputPhone: 'Celular',
    labelInputConstitutionDate: 'Data da constituição',
    labelInputSocialCapital: 'Capital social',
    labelInputNetWorth: 'Patrimônio líquido',
    labelInputNumberEmployees: 'Número de funcionários',
    labelInputRegistrationStatus: 'Situação cadastral',
    labelInputInscriptionState: 'Inscrição estadual',
    labelInputObservation: 'Observações',
    placeholderInputCNPJ: 'Digite o CNPJ da empresa',
    placeholderInputCompanyName: 'Empresa LTDA',
    placeholderInputTradeName: 'Empresa LTDA',
    placeholderInputPostalCode: 'Digite o seu CEP',
    placeholderInputStreet: 'Digite seu endereço',
    placeholderInputNumber: 'Digite o número',
    placeholderInputComplement: 'Apto',
    placeholderInputNeighborhood: 'Digite o bairro',
    placeholderInputCity: 'Digite a cidade',
    placeholderInputUF: 'Selecione',
    placeholderInputEmail: 'Digite o e-mail',
    placeholderInputTelephone: 'Digite o telefone',
    placeholderInputPhone: 'Digite o celular',
    placeholderInputInscriptionState: 'Digite a inscrição estadual',
    placeholderInputObservation: 'Mais detalhes sobre o agente',
    titlePartner: 'Agente',
    titleRegisterData: 'Dados de cadastro',
    titleAddress: 'Endereço',
    titleContacts: 'Contatos',
    titleAdditionalInformation: 'Informações adicionais',
  },
  partnerSocial: {
    labelInputUrlCompany: 'Site da empresa',
    labelInputUrlFacebook: 'Facebook',
    labelInputUrlInstagram: 'Instagram',
    labelInputUrlLinkedIn: 'LinkedIn',
    placeholderInputUrlCompany: 'https://empresa.com.br',
    placeholderInputUrlFacebook: 'facebook.com/empresa',
    placeholderInputUrlInstagram: 'instagram.com/empresa',
    placeholderInputUrlLinkedIn: 'linkedin.com/company/empresa',
  },
  partnerAgent: {
    labelInputName: 'Nome completo',
    labelInputCPF: 'CPF',
    labelInputCivilStatus: 'Estado civil',
    labelInputCEP: 'CEP',
    labelInputAddress: 'Endereço',
    labelInputNumber: 'Número',
    labelInputComplement: 'Complemento',
    labelInputNeighborhood: 'Bairro',
    labelInputCity: 'Cidade',
    labelInputUF: 'UF',
    labelInputTelephone: 'Telefone',
    labelInputPhone: 'Celular',
    labelInputNationality: 'Nacionalidade',
    labelInputBirthDate: 'Data de nascimento',
    labelInputGender: 'Sexo',
    labelInputRG: 'RG',
    labelInputIssuingAgency: 'Órgão expedidor',
    labelInputShippingDate: 'Data de expedição',
    labelInputEducation: 'Escolaridade',
    labelInputEmail: 'E-mail',
    labelInputMotherName: 'Nome da mãe',
    labelInputFatherName: 'Nome do pai',
    labelInputNaturalness: 'Naturalidade',
    labelInputUFNaturalness: 'UF (Naturalidade)',
    labelInputResponsibility: 'Responsabilidade',
    labelInputObservation: 'Observações',
    placeholderInputName: 'Digite seu nome completo',
    placeholderInputCPF: 'Digite seu CPF',
    placeholderInputCivilStatus: 'Selecione',
    placeholderInputCEP: 'Digite seu CEP',
    placeholderInputAddress: 'Digite seu endereço',
    placeholderInputNumber: 'Digite o número',
    placeholderInputComplement: 'Apto',
    placeholderInputNeighborhood: 'Digite o bairro',
    placeholderInputCity: 'Digite a cidade',
    placeholderInputUF: 'Selecione',
    placeholderInputTelephone: 'Digite o telefone',
    placeholderInputPhone: 'Digite o celular',
    placeholderInputNationality: 'Digite a nacionalidade',
    placeholderInputBirthDate: 'Digite a data de nascimento',
    placeholderInputGender: 'Selecione',
    placeholderInputRG: 'Digite o RG',
    placeholderInputIssuingAgency: 'Digite o órgão expedidor',
    placeholderInputShippingDate: 'Digite a data de expedição',
    placeholderInputEducation: 'Selecione',
    placeholderInputEmail: 'Digite o e-mail',
    placeholderInputMotherName: 'Digite o nome da mãe',
    placeholderInputFatherName: 'Digite o nome do pai',
    placeholderInputNaturalness: 'Digite a naturalidade',
    placeholderInputUFNaturalness: 'Selecione',
    placeholderInputObservation: 'Mais detalhes sobre o agente',
    titlePersonalData: 'Dados pessoais',
    titleTypeBond: 'Tipo de vínculo',
    titleAddress: 'Endereço',
    titleContacts: 'Contatos',
    titleAdditionalInformation: 'Informações adicionais',
    titleAgent: 'Lista de sócios/representantes',
    textFoundAgent: 'sócio(s)/representante(s) encontrado(s).',
    titleNoAgent: 'Nenhum sócio/representante cadastrado',
    textNoAgent: 'Comece adicionando algum sócio/representante da sua organização através do botão a sua direita.',
  },
  customerInfo: {
    CREATE: { title: 'Novo cliente', button: 'Criar cliente' },
    EDIT: { title: 'Editar cliente', button: 'Salvar' },
    VIEW: { title: 'Cliente', button: 'Fechar' },
  },
  customerPrimaryData: {
    labelInputTypeUser: 'Tipo de cliente',
    labelInputStatus: 'Status',
    labelInputName: 'Nome completo',
    labelInputCPF: 'CPF',
    labelInputCivilStatus: 'Estado civil',
    labelInputCEP: 'CEP',
    labelInputAddress: 'Endereço',
    labelInputNumber: 'Número',
    labelInputComplement: 'Complemento',
    labelInputNeighborhood: 'Bairro',
    labelInputCity: 'Cidade',
    labelInputUF: 'UF',
    labelInputPhone: 'Celular',
    labelInputBirthDate: 'Data de nascimento',
    labelInputEmail: 'E-mail',
    labelInputOccupation: 'Profissão',
    labelInputIncome: 'Renda',
    labelInputNetWorth: 'Patrimônio líquido',
    labelInputOwnCompany: 'Possui empresa?',
    labelInputCNPJ: 'CNPJ',
    labelInputCompanyName: 'Razão social',
    placeholderInputName: 'Digite seu nome completo',
    placeholderInputCPF: 'Digite seu CPF',
    placeholderInputCivilStatus: 'Selecione',
    placeholderInputCEP: 'Digite seu CEP',
    placeholderInputAddress: 'Digite seu endereço',
    placeholderInputNumber: 'Digite o número',
    placeholderInputComplement: 'Apto',
    placeholderInputNeighborhood: 'Digite o bairro',
    placeholderInputCity: 'Digite a cidade',
    placeholderInputUF: 'Selecione',
    placeholderInputPhone: 'Digite o celular',
    placeholderInputBirthDate: 'Digite a data de nascimento',
    placeholderInputEmail: 'Digite o e-mail',
    placeholderInputCNPJ: 'Digite o CNPJ da empresa',
    placeholderInputCompanyName: 'Empresa LTDA',
    titleUser: 'Cliente',
    titlePersonalData: 'Dados pessoais',
    titleAddress: 'Endereço',
    titleContacts: 'Contatos',
    titleOffice: 'Trabalho',
    titleMyCompany: 'Empresa em seu nome',
  },
  customerGuarantors: {
    labelInputName: 'Nome completo',
    labelInputCPF: 'CPF',
    labelInputCivilStatus: 'Estado civil',
    labelInputPhone: 'Celular',
    labelInputBirthDate: 'Data de nascimento',
    labelInputEmail: 'E-mail',
    labelInputOccupation: 'Profissão',
    labelInputIncome: 'Renda',
    labelInputNetWorth: 'Patrimônio líquido',
    labelInputOwnCompany: 'Cônjuge',
    placeholderInputName: 'Digite seu nome completo',
    placeholderInputCPF: 'Digite seu CPF',
    placeholderInputCivilStatus: 'Selecione',
    placeholderInputPhone: 'Digite o celular',
    placeholderInputBirthDate: 'Digite a data de nascimento',
    placeholderInputEmail: 'Digite o e-mail',
    titleGuarantors: 'Lista de avalista(s)',
    textFoundGuarantors: 'avalista(s) encontrado(s).',
    titleNoGuarantor: 'Nenhum avalista cadastrado',
    textNoGuarantor: 'Comece adicionando algum avalista através do botão a sua direita.',
  },
  representativeInfo: {
    CREATE: { title: 'Novo representante', button: 'Criar representante' },
    EDIT: { title: 'Editar representante', button: 'Salvar' },
    VIEW: { title: 'Representante', button: 'Fechar' },
  },
  excludeModal: {
    user: {
      title: 'excluir esse usuário?',
      text: 'Deseja excluir o usuário',
      button: 'Excluir usuário',
    },
    product: {
      title: 'excluir esse produto?',
      text: 'Deseja excluir o produto',
      button: 'Excluir produto',
    },
    guarnator: {
      title: 'excluir esse avalista?',
      text: 'Deseja excluir o avalista',
      button: 'Excluir avalista',
    },
    agent: {
      title: 'excluir esse sócio/represent.?',
      text: 'Deseja excluir o sócio/represent.',
      button: 'Excluir sócio/represent.',
    },
    variant: {
      title: 'excluir essa parametrização?',
      text: 'Deseja excluir a parametrização',
      button: 'Excluir parametrização',
    },
    document: {
      title: 'excluir esse arquivo?',
      text: 'Deseja excluir o arquivo',
      button: 'Excluir arquivo',
    },
    representatives: {
      title: 'excluir esse representante?',
      text: 'Deseja excluir o representante',
      button: 'Excluir representante',
    },
    partner: {
      title: 'excluir esse agente?',
      text: 'Deseja excluir o agente',
      button: 'Excluir agente',
    },
    customer: {
      title: 'excluir esse cliente?',
      text: 'Deseja excluir o cliente',
      button: 'Excluir cliente',
    },
    scoreParameterization: {
      title: 'excluir essa parametrização?',
      text: 'Deseja excluir a parametrização',
      button: 'Excluir parametrização',
    },
    workflow: {
      title: 'excluir esse workflow?',
      text: 'Deseja excluir o workflow',
      button: 'Excluir workflow',
    },
  },
  scoreParameterizations: {
    titleParameterization: 'Lista de parametrizações',
    textFoundParameterization: 'parametrização(ões) encontrada(s).',
    titleNoParameterization: 'Nenhuma parametrização cadastrada',
    textNoParameterization: 'Comece adicionando alguma parametrização através do botão a sua direita.',
    labelInputName: 'Nome da parametrização',
    labelInputMinimumScore: 'Score mínimo',
    labelInputMaximumScore: 'Score máximo',
    labelInputClassification: 'Classificação',
    labelInputRate: 'Taxa',
    labelInputMaximumLoanAmount: 'Valor máximo de empréstimo',
    labelInputOperationEntryStatus: 'Status de entrada da operação',
    placeholderInputName: 'Digite o nome da parametrização',
    placeholderInputMinimumScore: '0',
    placeholderInputMaximumScore: '1000',
  },
};
