import { FormikProps } from 'formik';
import { CoreRepresentative } from '../../../../@types';
import { InfoMode } from '../../../../@types/InfoMode';

export interface RepresentativeInfoProps {
  mode: InfoMode;
}

export interface RepresentativeContextValue {
  formik: FormikProps<CoreRepresentative>;
  mode: InfoMode;
}

export function getInitialValues(representative: CoreRepresentative): CoreRepresentative {
  return {
    id: representative?.id || '',
    name: representative?.name || '',
    cpf: representative?.cpf || '',
    partnerCompany: representative?.partnerCompany || '',
    email: representative?.email || '',
    phone: representative?.phone || '',
    cellPhone: representative?.cellPhone || '',
    observations: representative?.observations || '',
  };
}
