import React, { useEffect, useState } from 'react';

import { SummaryItemProps } from '../../@types';

import Navbar from '../../components/Navbar';
import Card from '../../components/Card';

import { summaryService } from '../../services';

import {
  Container,
  Header,
  InfoCardsList,
  MainTable,
  MainTableHeader,
} from './styles';

const Home: React.FC = () => {
  const [summary, setSummary] = useState<SummaryItemProps[] | any>([]);

  useEffect(() => {
    getSummary();
  }, []);

  function getSummary(): void {
    const summaryItems = summaryService.get();
    setSummary(summaryItems);
  }

  return (
    <Container>
      <Header>
        <h1>
          <small>Página Inicial</small>
          Resumo
        </h1>
        <Navbar />
      </Header>

      <InfoCardsList>
        {summary.length ? summary.map((item: SummaryItemProps) => (
          <Card
            key={item.id}
            title={item.title}
            number={item.number}
            value={item.value}
          />
        )) : (
          <small>Nenhuma informação a exibir no sumário.</small>
        )}
      </InfoCardsList>

      <Header>
        <h1>
          <small>Página Inicial</small>
          Últimos lançamentos
        </h1>

        <button type="button">Ver todas</button>
      </Header>

      <MainTable>
        <MainTableHeader>
          <p>Tabela de lançamentos | Em breve..</p>
        </MainTableHeader>
      </MainTable>
    </Container>
  );
};

export default Home;
