import React, { ReactElement, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { InfoMode } from '../../../../../@types/InfoMode';
import InputControl from '../../../../../components/Input';

import { PartnerContext } from '../../AgencyOfficeInfo';

import {
  Container, InputRow,

} from './styles';

const Social = (): ReactElement => {
  const { t } = useTranslation();

  const { formik, mode } = useContext(PartnerContext);

  const { social } = formik.values;

  const modeView = mode === InfoMode.VIEW;

  return (
    <Container>

      <InputRow>
        <InputControl
          name="inscriptionState"
          label={t('partnerSocial.labelInputUrlCompany') || ''}
          placeholder={t('partnerSocial.placeholderInputUrlCompany') || ''}
          type="inscriptionState"
          value={social?.urlCompany}
          onChange={formik.handleChange}
          disabled={modeView}
        />
        <InputControl
          name="inscriptionState"
          label={t('partnerSocial.labelInputUrlFacebook') || ''}
          placeholder={t('partnerSocial.placeholderInputUrlFacebook') || ''}
          type="inscriptionState"
          value={social?.urlFacebook}
          onChange={formik.handleChange}
          disabled={modeView}
        />
        <InputControl
          name="inscriptionState"
          label={t('partnerSocial.labelInputUrlInstagram') || ''}
          placeholder={t('partnerSocial.placeholderInputUrlInstagram') || ''}
          type="inscriptionState"
          value={social?.urlInstagram}
          onChange={formik.handleChange}
          disabled={modeView}
        />
        <InputControl
          name="inscriptionState"
          label={t('partnerSocial.labelInputUrlLinkedIn') || ''}
          placeholder={t('partnerSocial.placeholderInputUrlLinkedIn') || ''}
          type="inscriptionState"
          value={social?.urlLinkedIn}
          onChange={formik.handleChange}
          disabled={modeView}
        />
      </InputRow>

    </Container>
  );
};

export default Social;
