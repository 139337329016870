import React, { ReactElement } from 'react';

import { BreadCrumbProps, BreadCrumbItemProps } from '../../@types/BreadcrumbItems';

import {
  Container,
  Item,
} from './styles';

const Breadcrumb = ({ breadCrumbItems }: BreadCrumbProps): ReactElement => (
  <Container>
    {breadCrumbItems?.map((item: BreadCrumbItemProps) => (
      <Item key={item?.title}>
        {item?.title}
      </Item>
    ))}
  </Container>
);

export default Breadcrumb;
