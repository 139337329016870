import React, { ReactElement } from 'react';
import { RiCheckboxCircleFill } from 'react-icons/ri';

import { useDrag } from 'react-dnd';

import { Container } from './styles';

export interface BoxProps {
  name: string;
}

interface DropResult {
  name: string;
}

interface AccordionItemBoxPrps {
  childrenItem?: {
    id?: number;
    title?: string;
    icon?: any;
  }
  handleChange: (props: string | any | undefined) => any;
  canDrag?: boolean;
  checked?: boolean;
  type: string;
}

export const AccordionItemBox = function Box({
  childrenItem, handleChange, canDrag, checked, type,
}: AccordionItemBoxPrps): ReactElement {
  const [{ isDragging }, drag] = useDrag(() => ({
    type,
    item: { childrenItem },
    end: (item, monitor) => {
      const dropResult = monitor?.getDropResult<DropResult>();
      if (item && dropResult) {
        handleChange(childrenItem);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
    canDrag,
  }), [canDrag]);

  return (
    <Container ref={drag} data-testid="box" isDragging={isDragging} checked={checked}>
      {checked && <RiCheckboxCircleFill />}
      {childrenItem?.icon}
      <h4>{childrenItem?.title}</h4>
    </Container>
  );
};
