import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;  
    background-color: ${theme.colors.white00};
    overflow: hidden;
    clear: both;
  `};
`;

export const ManagementHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > small {
      ${theme.texts.paragraphXXS};
      color: ${theme.colors.black00};
    }
  `};
`;

export const WorkflowSelect = styled.div`
  flex: 1;
  margin: 0 14px;
`;

export const ManagementHeaderButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > button {
      border-radius: 4px;
      height: 100%;
      max-height: 42px;
      padding: 10px 20px;
      border: 1px solid;
      max-width: 145px;
      ${theme.texts.buttonXS};
      
      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }

      &:first-child {
        margin: 0 14px 0 0;
        background-color: ${theme.colors.black00};
        color: ${theme.colors.white00};
        border-color: ${theme.colors.black00};
      }

      &:last-child {
        background-color: transparent;
        border-color: ${theme.colors.error00};
        color: ${theme.colors.error00};
      }
    }
  `};
`;

export const WorkflowManagementContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > span {
      text-align: center;
      color: ${theme.colors.black30};
      ${theme.texts.buttonXS};
      width: 100%;
      margin: 24px auto;

      display: grid;
      grid-template-columns: 1fr auto 1fr;
      gap: 12px;
      max-width: 180px;

      &::after, &::before {
        content: "";
        border-top: 1px solid ${theme.colors.black30};
        align-self: center;
      }
    }
  `};
`;

export const RequiredSteps = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > small {
      color: ${theme.colors.black00};
      ${theme.texts.paragraphSM};
      margin: 0 0 14px 0;
    }
  `};
`;

export const RequiredStepItems = styled.div`
  margin: 0 0 0 0;
`;

export const RequiredStepItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: ${theme.colors.black05};
    border-radius: 6px;
    padding: 14px 24px 14px 18px;
    height: 72px;

    > button {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      border: 1px solid ${theme.colors.black10};
      background-color: transparent;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: ${theme.transitions.default};

      > svg {
        font-size: 1.2rem;
      }

      &:hover {
        border-color: ${theme.colors.black00};
      }
    }
  `};
`;

export const RequiredStepItemTexts = styled.div`
  ${({ theme }) => css`
    > h3 {
      color: ${theme.colors.black00};
      ${theme.texts.buttonSM};
    }

    > span {
      color: ${theme.colors.black50};
      ${theme.texts.paragraphXXS};
    }
  `};
`;
