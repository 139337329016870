import { faker } from '@faker-js/faker';
import { CoreUser } from '../@types';

const jurisdictionTitles = ['Operador', 'Gerente', 'Diretor', 'Comercial', 'Parceiro'];
const userTypes = ['Pessoa física', 'Pessoa jurídica'];
// Table fields header & content
export const tableHeaderTitles = ['Nome', 'E-mail', 'Tipo de usuário', 'Alçada'];
export const tableContentKeys = ['name', 'email', 'type', 'jurisdiction'];

export const listSelect = [{ id: '1', name: 'Item01' }, { id: '2', name: 'Item02' }];

export const userItems: CoreUser[] = [];
export const userMe: CoreUser = createRandomUser();

export function createRandomUserItem(): any {
  return {
    id: faker.datatype.uuid(),
    name: faker.name.fullName(),
    email: faker.internet.email(),
    type: faker.helpers.arrayElement(userTypes),
    jurisdiction: faker.helpers.arrayElement(jurisdictionTitles),
  };
}

Array.from({ length: 4 }).forEach(() => {
  userItems.push(createRandomUserItem());
});

export function createRandomUser(): any {
  return {

    id: faker.datatype.uuid(),
    typeUser: faker.helpers.arrayElement(listSelect),
    partner: faker.helpers.arrayElement(listSelect),
    status: faker.helpers.arrayElement(listSelect),
    name: faker.name.fullName(),
    cpf: faker.phone.number('9999999999'),
    civilStatus: faker.helpers.arrayElement(listSelect),
    telephone: faker.phone.number(),
    phone: faker.phone.number(),
    nationality: faker.helpers.fake('Brasileiro'),
    birthDate: faker.date.birthdate(),
    gender: faker.helpers.arrayElement(listSelect),
    rg: faker.phone.number('9999999999'),
    issuingAgency: faker.helpers.fake('SSI'),
    shippingDate: faker.date.birthdate(),
    education: faker.helpers.arrayElement(listSelect),
    email: faker.internet.email(),
    motherName: faker.name.fullName(),
    fatherName: faker.name.fullName(),
    naturalness: faker.helpers.fake('Brasileiro'),
    ufNaturalness: faker.helpers.fake('RS'),
    jurisdiction: faker.helpers.arrayElement(listSelect),

    address: {
      postalCode: faker.address.zipCode(),
      street: faker.address.street(),
      number: faker.address.buildingNumber(),
      complement: faker.address.street(),
      neighborhood: faker.address.stateAbbr(),
      city: faker.address.city(),
      state: faker.address.state(),
    },
    documents: [
      // {
      //   id: faker.datatype.number(),
      //   filename: faker.system.fileName(),
      //   size: faker.datatype.number(),
      //   mimeType: faker.system.mimeType(),
      // },
    ],
    accessControl: {
      accessDays: faker.helpers.arrayElement(listSelect),
      accessTime: faker.helpers.arrayElement(listSelect),
      accessExternal: faker.helpers.arrayElement(listSelect),
      changeRates: faker.helpers.arrayElement(listSelect),
    },
  };
}

export default userItems;
