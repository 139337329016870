export const TRANSLATIONS_EN = {
  buttonLoadingMessage: 'Loading...',
  sidebar: {
    home: 'Home',
    registration: 'Registration',
    users: 'Users',
    agencyOffice: 'Agency Office',
    representatives: 'Representatives',
    products: 'Products',
    customers: 'Customers',
    funds: 'Funds',
    operations: 'Operations',
    management: 'Management',
    workflows: 'Workflows',
    help: 'Help',
    logout: 'Logout',
  },
  signIn: {
    back: 'Back to homepage',
    title: 'Login to your account',
    subTitle: 'Log in and manage your credit intelligence.',
    show: 'Show',
    email: 'Corporate email',
    emailPlaceholder: 'name@company.com',
    password: 'Password',
    accessText: 'Sign In',
    supportText: "Don't have access? Ask the administrator.",
    keepConnected: 'Keep connected',
    forgotPassword: 'Forgot your password?',
  },
  forgotPassword: {
    back: 'Back to login',
    title: 'Forgot your password?',
    subTitle: "Don't worry. We will send a link to your registered email with instructions on how to reset your password.",
    email: 'Corporate email',
    emailPlaceholder: 'name@company.com',
    button: 'Reset password',
  },
  newPassword: {
    back: 'Back to login',
    title: 'New password',
    subTitle: 'Create your new password to login. The password must be different from the currently used password.',
    button: 'Create new password',
    label: 'New password',
    repeatLabel: 'Repeat new password',
  },
  userPrimaryData: {
    labelInputName: 'Name full',
    labelInputCPF: 'CPF',
    labelInputCivilStatus: 'Estado civil',
    labelInputCEP: 'CEP',
    labelInputAddress: 'Endereço',
    labelInputNumber: 'Número',
    labelInputComplement: 'Complemento',
    labelInputNeighborhood: 'Bairro',
    labelInputCity: 'Cidade',
    labelInputUF: 'UF',
    labelInputTelephone: 'Telefone',
    labelInputPhone: 'Celular',
    labelInputNationality: 'Nacionalidade',
    labelInputBirthDate: 'Data de nascimento',
    labelInputGender: 'Sexo',
    labelInputRG: 'RG',
    labelInputIssuingAgency: 'Órgão expedidor',
    labelInputShippingDate: 'Data de expedição',
    labelInputEducation: 'Escolaridade',
    labelInputEmail: 'E-mail',
    labelInputMotherName: 'Nome da mãe',
    labelInputFatherName: 'Nome do pai',
    labelInputNaturalness: 'Naturalidade',
    labelInputUFNaturalness: 'UF (Naturalidade)',
    placeholderInputName: 'Digite seu nome completo',
    placeholderInputCPF: 'Digite seu CPF',
    placeholderInputCivilStatus: 'Selecione',
    placeholderInputCEP: 'Digite seu CEP',
    placeholderInputAddress: 'Digite seu endereço',
    placeholderInputNumber: 'Digite o número',
    placeholderInputComplement: 'Apto',
    placeholderInputNeighborhood: 'Digite o bairro',
    placeholderInputCity: 'Digite a cidade',
    placeholderInputUF: 'Selecione',
    placeholderInputTelephone: 'Digite o telefone',
    placeholderInputPhone: 'Digite o celular',
    placeholderInputNationality: 'Digite a nacionalidade',
    placeholderInputBirthDate: 'Digite a data de nascimento',
    placeholderInputGender: 'Selecione',
    placeholderInputRG: 'Digite o RG',
    placeholderInputIssuingAgency: 'Digite o órgão expedidor',
    placeholderInputShippingDate: 'Digite a data de expedição',
    placeholderInputEducation: 'Selecione',
    placeholderInputEmail: 'Digite o e-mail',
    placeholderInputMotherName: 'Digite o nome da mãe',
    placeholderInputFatherName: 'Digite o nome do pai',
    placeholderInputNaturalness: 'Digite a naturalidade',
    placeholderInputUFNaturalness: 'Selecione',
    titlePersonalData: 'Dados Pessoais',
    titleJurisdiction: 'Alçada',
    titleAddress: 'Endereço',
    titleContacts: 'Contatos',
    titleFamily: 'Família',
  },
};
