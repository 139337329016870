import React, { ReactElement } from 'react';

import AnalysisIllustration from '../../assets/AnalysisIllustration.svg';

import { AnalysisCostModalProps } from './AnalysisCostModal.spec';

import {
  Content,
  AnalysisTable,
  AnalysisTableItem,
  Total,
  TotalTexts,
} from './styles';

const AnalysisCostModal = ({
  handleCloseModal,
}: AnalysisCostModalProps): ReactElement => (
  <Content onMouseLeave={() => handleCloseModal()}>
    <img src={AnalysisIllustration} alt="AnalysisIllustration" />
    <h3>Custo de análise</h3>
    <p>
      Considerando as integrações adicionadas a jornada de análise do produto,
      essa operação terá um custo de:
    </p>

    <AnalysisTable>
      <AnalysisTableItem>
        <b>Análise SCR</b>
        <small>R$ 0,12</small>
      </AnalysisTableItem>
      <AnalysisTableItem>
        <b>Análise Quod</b>
        <small>R$ 0,24</small>
      </AnalysisTableItem>
      <AnalysisTableItem>
        <b>Tarifa Céos</b>
        <small>R$ 0,5</small>
      </AnalysisTableItem>
    </AnalysisTable>

    <Total>
      <TotalTexts>
        <b>Total</b>
        <small>por análise</small>
      </TotalTexts>
      <h4>R$ 0,41</h4>
    </Total>
  </Content>
);

export default AnalysisCostModal;
