import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { InfoMode } from '../../../../../@types/InfoMode';
import Select from '../../../../../components/Select';
import { selectService } from '../../../../../services';
import { UserContext } from '../../UserInfo';

import {
  Container, ContentForm, InputRow,
} from './styles';

const AccessControl = (): ReactElement => {
  const { t } = useTranslation();

  const { formik, mode } = useContext(UserContext);

  const { accessControl } = formik.values;

  const [selectAccessDays, setSelectAccessDays] = useState();
  const [selectAccessTime, setSelectAccessTime] = useState();
  const [selectAccessExternal, setSelectAccessExternal] = useState();
  const [selectChangeRates, setSelectChangeRates] = useState();

  useEffect(() => {
    setSelectAccessDays(selectService.getAccessDays());
    setSelectAccessTime(selectService.getAccessTime());
    setSelectAccessExternal(selectService.getAccessExternal());
    setSelectChangeRates(selectService.getChangeRates());
  }, []);

  const modeView = mode === InfoMode.VIEW;

  return (
    <Container>

      <ContentForm>
        <h2>{t('userAccessControl.titleAccessData')}</h2>
        <InputRow>
          <Select
            name="accessControl.accessDays"
            label={t('userAccessControl.labelInputAccessDays')}
            options={selectAccessDays}
            value={accessControl?.accessDays}
            onChange={(selectedOption: any) => formik.setFieldValue('accessControl.accessDays', selectedOption)}
            isDisabled={modeView}
          />
          <Select
            name="accessControl.accessTime"
            label={t('userAccessControl.labelInputAccessTime')}
            options={selectAccessTime}
            value={accessControl?.accessTime}
            onChange={(selectedOption: any) => formik.setFieldValue('accessControl.accessTime', selectedOption)}
            isDisabled={modeView}
          />
          <Select
            name="accessControl.accessExternal"
            label={t('userAccessControl.labelInputAccessExternal')}
            options={selectAccessExternal}
            value={accessControl?.accessExternal}
            onChange={(selectedOption: any) => formik.setFieldValue('accessControl.accessExternal', selectedOption)}
            isDisabled={modeView}
          />
          <Select
            name="accessControl.changeRates"
            label={t('userAccessControl.labelInputChangeRates')}
            options={selectChangeRates}
            value={accessControl?.changeRates}
            onChange={(selectedOption: any) => formik.setFieldValue('accessControl.changeRates', selectedOption)}
            isDisabled={modeView}
          />
        </InputRow>
      </ContentForm>

    </Container>
  );
};

export default AccessControl;
