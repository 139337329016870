import React, { useState } from 'react';
import { RiArrowRightUpLine } from 'react-icons/ri';

import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';

import Logo from '../../assets/LogoWLabel.svg';

import {
  Container,
  SignInForm,
  FormHeader,
  Form,
  FormTexts,
  InputControl,
  Brand,
} from './styles';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleLogin(): Promise<void> {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }

  return (
    <Container>
      <SignInForm>
        <FormHeader>
          <Brand>
            <img src={Logo} alt="Logo" />
          </Brand>
          <a href="/">
            {t('forgotPassword.back')}
            <RiArrowRightUpLine />
          </a>
        </FormHeader>
        <FormTexts>
          <h1>{t('forgotPassword.title')}</h1>
          <p>{t('forgotPassword.subTitle')}</p>
        </FormTexts>

        <Form>
          <InputControl>
            <small>{t('forgotPassword.email')}</small>
            <input type="email" placeholder={t('forgotPassword.emailPlaceholder') ?? ''} />
          </InputControl>

          <Button
            message={t('forgotPassword.button')}
            type="button"
            onClick={() => {
              setIsLoading(true);
              handleLogin();
            }}
            isLoading={isLoading}
            loadingMessage={t('buttonLoadingMessage')}
          />
        </Form>
      </SignInForm>
    </Container>
  );
};

export default ForgotPassword;
