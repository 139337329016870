import React from 'react';
import { Link } from 'react-router-dom';
import { RiSearchLine } from 'react-icons/ri';

import {
  Navbar,
  Button,
  Breadcrumb,
} from '../../../components';

import {
  agentsBreadCrumbItems,
} from '../../../mock';

import {
  Container, Header, SubHeader, SearchInput,
} from './styles';

import TableComponent from '../../../components/ServiceTable';

import { TableColumn } from '../../../components/ServiceTable/table.spec';

import { agencyOfficeService } from '../../../services';
import { AgencyOfficeSDTO } from '../../../@types/AgencyOffice';

const Agents: React.FC = () => {
  const agentColumns: TableColumn<AgencyOfficeSDTO>[] = [
    {
      title: 'Nome',
      propName: 'agencyID',
    },
    {
      title: 'E-mail',
      propName: 'companyID',
    },
    // {
    //   title: 'Tipo de agente',
    //   propName: 'type',
    // },
  ];

  return (
    <Container>
      <Header>
        <h1>
          <Breadcrumb breadCrumbItems={agentsBreadCrumbItems} />
          Agentes
        </h1>
        <Navbar />
      </Header>

      <SubHeader>
        <SearchInput>
          <RiSearchLine />
          <input type="text" placeholder="Buscar agente" />
        </SearchInput>
        <Link to="/registration/partners/create">
          <Button message="Novo agente" />
        </Link>
      </SubHeader>

      <TableComponent
        service={agencyOfficeService}
        tableColumns={agentColumns}
        excludeModalTexts={{
          type: 'partner',
          visibleValue: 'name',
        }}
        itemBaseURL="/registration/partners"
      />
    </Container>
  );
};

export default Agents;
