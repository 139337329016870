import React from 'react';

import {
  operationDataItems,
  customerDataItems,
  addressDataItems,
  guarantorDataItems,
  documentsItems,
} from '../../mock';

import { Container, Content } from './styles';

import WorkflowControlModal from '../../components/WorkflowControlModal';

const ProductModals: React.FC = () => (
  <Container>
    <Content>
      <WorkflowControlModal
        title="Dados da operação"
        workflowItems={operationDataItems}
      />
    </Content>
    <Content>
      <WorkflowControlModal
        title="Dados do cliente"
        workflowItems={customerDataItems}
      />
    </Content>
    <Content>
      <WorkflowControlModal title="Endereço" workflowItems={addressDataItems} />
    </Content>
    <Content>
      <WorkflowControlModal
        title="Avalista"
        workflowItems={guarantorDataItems}
      />
    </Content>
    <Content>
      <WorkflowControlModal
        title="Anexos/documentos"
        workflowItems={documentsItems}
      />
    </Content>
  </Container>
);

export default ProductModals;
