import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > label {
      ${theme.texts.paragraphXXS};
      margin: 0 0 8px 0;

      > small {
        color: ${theme.colors.black50};
        font-style: italic;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
      }
    }

    > select {
      border-radius: 4px;
      background-color: ${theme.colors.black05};
      border: 0;
      height: 42px;
      padding: 12px 12px;
      transition: ${theme.transitions.default};
      ${theme.texts.paragraphXS};
      color: ${theme.colors.black30};

      > option {
        color: ${theme.colors.black00};
      }

      &::placeholder {
        color: ${theme.colors.black30};
      }
    }

    .react-select__control {
      background-color: ${theme.colors.black05};
    }

    .react-select__control--is-disabled {
      background-color: ${theme.colors.black20};
    }

    .react-select__single-value {
      color: ${theme.colors.black00};
    }

    .react-select__placeholder {
      color: ${theme.colors.black30};
    }

    .react-select__option {
     color: ${theme.colors.black00};
    background-color: ${theme.colors.white00};
    }

    &.error {
      > small {
        color: ${theme.colors.error};
      }
      > input {
        border-color: ${theme.colors.error};
      }
    }
  `};
`;
