import React, { ReactElement, useEffect, useState } from 'react';

import { Container, SwitchContent, SwitchRow } from './styles';
import { SwitchProps } from './Switch.spec';

const Switch = ({
  label = '',
  name,
  checked,
  onChange,
  required,
  field,
  onStatus = 'Ativado',
  offStatus = 'Desativado',
  position = 'right',
  disabled,
}: SwitchProps): ReactElement => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(field?.checked || checked);
  }, [field?.checked, checked]);
  return (
    <Container>
      {label && (
        <label>
          {label}
          {' '}
          {required && <small>(obrigatório)</small>}
        </label>
      )}
      <SwitchContent>
        {position === 'left' && (
          <small className="left">{checked || isChecked ? onStatus : offStatus}</small>
        )}
        <SwitchRow className="switch">
          <input
            type="checkbox"
            {...field}
            name={name}
            onChange={(e) => {
              setIsChecked(!isChecked);
              field?.onChange?.(e);
              onChange?.(e);
            }}
            checked={checked || field?.value || isChecked}
            disabled={disabled}
          />
          <span className="slider" />
        </SwitchRow>
        {position === 'right' && (
          <small className="right">{checked || isChecked ? onStatus : offStatus}</small>
        )}
      </SwitchContent>
    </Container>
  );
};

export default Switch;
