import React, { ReactElement, useContext } from 'react';
import UploadDocument from '../../../../../components/UploadDocument';
import { CustomerContext } from '../../CustomerInfo';

import { Container } from './styles';

const Documents = (): ReactElement => {
  const {
    setDocuments, documents, formik, mode,
  } = useContext(CustomerContext);

  return (
    <Container>
      <UploadDocument
        documents={documents}
        setDocuments={(file) => {
          formik.setFieldValue('documents', file);
          setDocuments(file);
        }}
        mode={mode}
      />
    </Container>
  );
};

export default Documents;
