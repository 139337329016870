import axios from 'axios';
import i18next from 'i18next';
import qs from 'qs';

const baseURL = process.env.REACT_APP_BASE_URL || '';

const api = axios.create({
  baseURL,
  paramsSerializer(params) {
    return qs.stringify(params);
  },
});

api.interceptors.request.use((config) => ({ ...config, headers: { ...config?.headers, 'accept-language': i18next.language } }));

export default api;
