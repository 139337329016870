import React, { ReactElement, useState } from 'react';
import {
  RiEyeLine,
  // RiMessage2Line,
  // RiMoreFill,
  RiDeleteBinLine,
  RiEditLine,
} from 'react-icons/ri';

import { Link } from 'react-router-dom';
import { TableProps } from './table.spec';

import EmptyTable from '../../assets/EmptyTable.svg';

import {
  Container,
  MainTableHeader,
  MainTableHeaderItem,
  MainTableContent,
  MainTableContentRow,
  MainTableContentRowItem,
  MainTableActionsTab,
  EmptyTableState,
} from './styles';
import { ExcludeModal } from '..';
import { CoreDTO } from '../../@types/Core';
import { CoreService } from '../../services/coreService';
import { usePaginationHelper } from '../../hooks/paginationHelper';
import ServiceTablePagination from '../ServiceTablePagination';

const ServiceTable = <
  E extends CoreDTO,
  I extends CoreDTO,
  S extends CoreService<E, I>,
>({
    tableColumns,
    service,
    itemBaseURL,
    excludeModalTexts,
  }: TableProps<E, I, S>): ReactElement => {
  const {
    page, previous, next, goTo, remove,
  } = usePaginationHelper<E, I, S>(service);
  const [activeActionsIndex, setActiveActionsIndex] = useState<number | null>(null);
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [isExcludeModalVisible, setIsExcludeModalVisible] = useState<boolean>(false);
  return (
    <Container>
      <MainTableHeader>
        {tableColumns.map(({ title, propName }) => (
          <MainTableHeaderItem key={String(propName)}>
            {title}
          </MainTableHeaderItem>
        ))}
      </MainTableHeader>

      <MainTableContent>
        {page?.content?.length ? page.content.map((item, index) => (
          <MainTableContentRow
            key={item?.id}
            onMouseOver={() => setActiveActionsIndex(index)}
            onMouseLeave={() => setActiveActionsIndex(null)}
          >
            {tableColumns.map(({ propName, labelFormatter }) => (
              <MainTableContentRowItem key={String(propName)}>
                {labelFormatter
                  ? labelFormatter(item[propName], item, propName)
                  : item[propName]}
              </MainTableContentRowItem>
            ))}

            {activeActionsIndex === index && (
              <MainTableActionsTab>
                <Link to={`${itemBaseURL}/view/${item?.id}`}>
                  <RiEyeLine />
                </Link>

                <Link to={`${itemBaseURL}/edit/${item?.id}`}>
                  <RiEditLine />
                </Link>

                <button
                  type="button"
                  onClick={() => {
                    setSelectedItem(item);
                    setIsExcludeModalVisible(true);
                  }}
                >
                  <RiDeleteBinLine />
                </button>
              </MainTableActionsTab>
            )}
          </MainTableContentRow>
        )) : (
          <EmptyTableState>
            <img src={EmptyTable} alt="EmptyTable" />
            <h4>Nenhum dado encontrado</h4>
            <p>Esta tabela não possui nenhum tipo de dado para ser exibido.</p>
          </EmptyTableState>
        )}
      </MainTableContent>

      {isExcludeModalVisible && (
        <ExcludeModal
          {...excludeModalTexts}
          handleCloseModal={() => setIsExcludeModalVisible(false)}
          handleDeleteConfirmation={() => {
            remove(selectedItem?.id);

            setIsExcludeModalVisible(false);
          }}
          visibleValue={selectedItem[excludeModalTexts?.visibleValue || 'name']}
        />
      )}
      {page?.content?.length ? (
        <ServiceTablePagination
          pagination={page}
          previous={previous}
          next={next}
          goTo={goTo}
        />
      ) : ''}
    </Container>
  );
};

export default ServiceTable;
