import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import ErrorMessage from '../ErrorMessage';
import InputControl from '../Input';
import { InputCurrencyProps } from './CurrencyInput.spec';

import { Container, Content } from './styles';

const InputCurrency = ({
  label = '',
  prefix = 'R$',
  className,
  message,
  value,
  onStringChange,
  decimalSeparator = ',',
  groupSeparator = '.',
  decimalsLimit = 2,
  name,
  field,
  ...props
}: InputCurrencyProps): ReactElement => {
  const [internalValue, setInternalValue] = useState<string | undefined>();
  const [outputValue, setOutputValue] = useState<string>();

  const format = useCallback(
    (val: string | undefined, ds = '.', gs = ''): string | undefined => {
      if (!val) {
        return '';
      }

      const stringValue = `${'0'.repeat(decimalsLimit)}${val
        ?.toString()
        .replace(/\D/g, '')}`
        .replace(new RegExp(`0+(\\d{${decimalsLimit + 1},})$`), '$1')
        .replace(new RegExp(`(\\d+)(\\d{${decimalsLimit}})`), `$1${ds}$2`);
      if (!gs) {
        return stringValue;
      }
      const integerPart = stringValue
        .slice(0, -(decimalsLimit + ds.length))
        .split('')
        .map((v, i, { length }) => {
          const reverseIndex = length - i - 1;
          return reverseIndex !== 0 && reverseIndex % 3 === 0 ? `${v}${gs}` : v;
        })
        .join('');

      return integerPart + stringValue.slice(-(decimalsLimit + ds.length));
    },
    [decimalsLimit],
  );

  useEffect(() => {
    if (value !== outputValue) {
      setInternalValue(
        format(
          parseFloat(value?.toString() || '').toFixed(decimalsLimit),
          decimalSeparator,
          groupSeparator,
        ),
      );
    }
  }, [value, decimalSeparator, groupSeparator, format, outputValue, decimalsLimit]);

  useEffect(() => {
    const formattedValue = format(internalValue);

    if (formattedValue !== outputValue) {
      setOutputValue(formattedValue);
      onStringChange?.(formattedValue || '', name || '');
    }
  }, [internalValue, onStringChange, format, name, outputValue]);

  return (
    <Container className={className}>
      <Content>
        <p>{prefix}</p>
        <InputControl
          {...field}
          {...props}
          label={label}
          className={className}
          placeholder="0,00"
          onChange={(e) => setInternalValue(
            format(e.target.value, decimalSeparator, groupSeparator),
          )}
          value={internalValue}
        />
        {/* <CurrencyInput
          {...field}
          {...props}
          decimalSeparator={decimalSeparator}
          groupSeparator={groupSeparator}
          decimalsLimit={decimalsLimit}
          onValueChange={(a, b, values) => {
            if (values) {
              setInternalValue(values.float || undefined);
              onNumberChange?.(values.float, props.name || '');
              onStringChange?.(values.value, props.name || '');
            }
          }}
          value={format(internalValue?.toString() || '0', '.')}
        /> */}
      </Content>
      <ErrorMessage message={message} />
    </Container>
  );
};

export default InputCurrency;
