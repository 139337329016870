import React, { ReactElement } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { RiDeleteBin5Line, RiSettings2Line } from 'react-icons/ri';

import Drag from '../../../../../../../assets/Drag.svg';

import { Switch } from '../../../../../../../components';

import {
  Container,
  CustomizableStepItemTexts,
  CustomizableStepButtons,
  SwitchControl,
} from './styles';

const CustomizableStepItem = ({ item }: any): ReactElement => {
  const {
    attributes, listeners, setNodeRef, transform, transition,
  } = useSortable({ id: item?.id, disabled: item?.required || false });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Container
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      required={item?.required ?? false}
    >
      {item?.required ?? <img src={Drag} alt="Drag" />}
      <CustomizableStepItemTexts>
        <h3>{item?.title}</h3>
        <span>Descrição do campo</span>
      </CustomizableStepItemTexts>
      {(item?.required || item?.default) ?? (
        <SwitchControl>
          <Switch
            name="businessPartner"
            onStatus="Ativada"
            offStatus="Desativada"
            position="left"
            checked={false}
          />
        </SwitchControl>
      )}
      <CustomizableStepButtons>
        <button type="button">
          <RiSettings2Line />
        </button>
        {(item?.required || item?.default) ?? (
          <button
            type="button"
            onClick={() => {
              // handleDeleteTrigger();
            }}
          >
            <RiDeleteBin5Line />
          </button>
        )}
      </CustomizableStepButtons>
    </Container>
  );
};

export default CustomizableStepItem;
