import { CoreRepresentative } from '../@types';
import representativeItems, { getOne } from '../mock/representatives';

export default {
  get(): any {
    try {
      return representativeItems;
    } catch (error) {
      return console.log('Unable to list representative items!');
    }
  },

  async getOne(): Promise<any> {
    try {
      return getOne;
    } catch (error) {
      return console.log('Unable to list a specific representative!');
    }
  },

  edit(value: Partial<CoreRepresentative>): any {
    try {
      return console.log(value);
    } catch (error) {
      return console.log('Unable edit a specific representative!');
    }
  },
};
