import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    > p {
      ${theme.texts.paragraphXXS};
      margin-bottom: 14px;
    }
  `}
`;

export const UploadDocumentContent = styled.div`
  ${({ theme }) => css`
    margin-bottom: 24px;
    > div {
      width: 100%;
      height: 296px;
      text-align: center;
      background-color: ${theme.colors.black05};
      border: 1px dashed ${theme.colors.black50};
      border-radius: 12px;

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      > svg {
        font-size: 5.3rem;
      }

      > p {
        ${theme.texts.paragraphMD};
        max-width: 412px;
        margin: 24px 0;
      }
    }
  `}
`;

export const ChooseFile = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 165.5px;

    > button {
      ${theme.texts.buttonXXXS};
      padding: 10px 16px;
      border-radius: 4px;

      display: block;
      text-align: start;

      margin-bottom: 12px;

      > div {
        top: 12px;
      }
    }

    > span {
      ${theme.texts.paragraphSM};
      color: ${theme.colors.black50};
    }
  `}
`;

export const ListDocumentContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > p {
      ${theme.texts.paragraphMD};
      color: ${theme.colors.black50};
      margin: 0 auto 14px;
    }
  `}
`;
