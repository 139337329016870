import React, {
  createContext, ReactElement, useState, useEffect, useMemo,
} from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Link, useParams } from 'react-router-dom';
import { RiCloseFill } from 'react-icons/ri';
import { Button, TabNavigation, Breadcrumb } from '../../../../components';

import { userBreadCrumbItems } from '../../../../mock';

import {
  Container, Form, ActionsButton, Header, Divider,
} from './styles';

import { CoreUser, CoreFile } from '../../../../@types';
import {
  UserInfoProps, UserContextValue, getInitialValues,
} from './UserInfo.spec';
import { userService } from '../../../../services';
import { InfoMode } from '../../../../@types/InfoMode';
import { UserDTO } from '../../../../@types/User';
import PrimaryData from '../Breadcrumb/PrimaryData';
import Documents from '../Breadcrumb/Documents';
import AccessControl from '../Breadcrumb/AccessControl';
import { TabElementProps } from '../../../../@types/TabNavigation';

export const UserContext = createContext({} as UserContextValue);

const UserInfo = ({ mode }: UserInfoProps): ReactElement => {
  const { t } = useTranslation();

  const { id: userID } = useParams();

  const [user, setUser] = useState<CoreUser | any>();
  const [documents, setDocuments] = useState<CoreFile[]>([]);

  const initialValues = useMemo(() => getInitialValues(user), [user]);

  const tabNavigationItemsUsers = [
    {
      title: 'Dados primários',
      element: <PrimaryData />,
      enabled: true,
    },
    {
      title: 'Documentos',
      element: <Documents />,
      enabled: mode !== InfoMode.CREATE,
    },
    {
      title: 'Acesso',
      element: <AccessControl />,
      enabled: mode !== InfoMode.CREATE,
    },
  ] as TabElementProps;

  useEffect((): void => {
    if (mode !== InfoMode.CREATE) {
      fetchData();
    }
  }, [mode]);

  async function fetchData(): Promise<void> {
    try {
      if (userID && +userID) {
        // const response = await userService.getOne(+userID);
        // setUser(response);
        // setDocuments(response.documents || []);
      }
    } catch (err: any) {
      console.log(err);
    }
  }

  const formik = useFormik<UserDTO>({
    initialValues,
    enableReinitialize: true,
    onSubmit(values: any) {
      if (mode === InfoMode.CREATE) {
        return;
      }
      const userData = Object.entries(values).reduce((acc, [key, value]) => {
        if (formik.initialValues[key as keyof UserDTO] !== value) {
          return {
            ...acc,
            [key]: value,
          };
        }
        return acc;
      }, {} as Partial<UserDTO>);

      try {
        if (userID && +userID) {
          // const data = userService.update(+userID, userData);
          // setUser(data);
          // fetchData();
        }
      } catch (err: any) {
        console.log(err);
      }
    },
  });

  return (
    <Container>
      <Header>
        <h1>
          <Breadcrumb breadCrumbItems={userBreadCrumbItems} />
          {t(`userInfo.${mode}.title`)}
        </h1>
        <Link to="/registration/users">
          <RiCloseFill />
        </Link>
      </Header>

      <UserContext.Provider
        value={{
          formik,
          documents,
          setDocuments,
          mode,
        }}
      >
        <Form onSubmit={formik.handleSubmit}>
          <TabNavigation tabNavigationItems={tabNavigationItemsUsers} type="usuário" />
          <Divider />
          <ActionsButton>
            {mode !== InfoMode.VIEW ? (
              <Button type="button" message={t(`userInfo.${mode}.button`)} />
            ) : (
              <Link to={`/registration/users/edit/${userID}`}>
                {t(`userInfo.${mode}.button`)}
              </Link>
            )}
          </ActionsButton>
        </Form>
      </UserContext.Provider>
    </Container>
  );
};

export default UserInfo;
