import React, {
  createContext,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { RiCloseFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { CoreRepresentative } from '../../../../@types';
import { Breadcrumb, Button } from '../../../../components';
import GeneralData from '../GeneralData';
import { representativesBreadCrumbItems } from '../../../../mock';
import {
  getInitialValues,
  RepresentativeContextValue,
  RepresentativeInfoProps,
} from './RepresentativeInfo.spec';

import {
  Container, Header, Form, ActionsButton, Divider,
} from './styles';
import { representativesService } from '../../../../services';
import { InfoMode } from '../../../../@types/InfoMode';

export const RepresentativeContext = createContext(
  {} as RepresentativeContextValue,
);

const RepresentativeInfo = ({
  mode,
}: RepresentativeInfoProps): ReactElement => {
  const { t } = useTranslation();
  const [representative, setRepresentative] = useState<CoreRepresentative>(
    {} as CoreRepresentative,
  );

  const initialValues = useMemo(
    () => getInitialValues(representative),
    [representative],
  );

  useEffect((): void => {
    if (mode !== InfoMode.CREATE) {
      fetchData();
    }
  }, [mode]);

  async function fetchData(): Promise<void> {
    try {
      const response = await representativesService.getOne();
      setRepresentative(response);
    } catch (err: any) {
      console.log(err);
    }
  }

  const formik = useFormik<CoreRepresentative>({
    initialValues,
    enableReinitialize: true,
    onSubmit(values: any) {
      if (mode === InfoMode.CREATE) {
        return;
      }

      const representativeData = Object.entries(values).reduce((acc, [key, value]) => {
        if (formik.initialValues[key as keyof CoreRepresentative] !== value) {
          return {
            ...acc,
            [key]: value,
          };
        }
        return acc;
      }, {} as Partial<CoreRepresentative>);

      try {
        const data = representativesService.edit(representativeData);
        setRepresentative(data);
        fetchData();
      } catch (err: any) {
        console.log(err);
      }
    },
  });

  return (
    <Container>
      <Header>
        <h1>
          <Breadcrumb breadCrumbItems={representativesBreadCrumbItems} />
          {t(`representativeInfo.${mode}.title`)}
        </h1>

        <Link to="/registration/representatives">
          <RiCloseFill />
        </Link>
      </Header>
      <RepresentativeContext.Provider
        value={{
          formik,
          mode,
        }}
      >
        <Form onSubmit={formik.handleSubmit}>
          <GeneralData />
          <Divider />
          <ActionsButton>
            {mode === InfoMode.VIEW ? (
              <Button
                type="button"
                message={t(`representativeInfo.${mode}.button`)}
              />
            ) : (
              <Button
                type="submit"
                message={t(`representativeInfo.${mode}.button`)}
              />
            )}
          </ActionsButton>
        </Form>
      </RepresentativeContext.Provider>
    </Container>
  );
};

export default RepresentativeInfo;
