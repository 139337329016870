import { DynamicTagsProps } from '../../../../../@types/DynamicTags';
import { ProductVariantDTO } from '../../../../../@types/ProductVariant';
import { format } from '../../../../../utils/masks';

export function getInitialValues(variant: ProductVariantDTO, productID: number): ProductVariantDTO {
  return {
    name: variant?.name || '',
    enabled: variant?.enabled || false,
    customerTypeID: variant?.customerTypeID || null,
    minInstallmentQuantity: variant?.minInstallmentQuantity || 0,
    maxInstallmentQuantity: variant?.maxInstallmentQuantity || 0,
    minValue: variant?.minValue || null,
    maxValue: variant?.maxValue || null,
    gracePeriod: variant?.gracePeriod || 0,
    fee: variant?.fee || null,
    tac: variant?.tac || null,
    tacIsPercentage: variant?.tacIsPercentage || null,
    minAge: variant?.minAge || 0,
    maxAge: variant?.maxAge || 0,
    insurance: variant?.insurance || null,
    agencyFee: variant?.agencyFee || null,
    agencyFeeIsPercentage: variant?.agencyFeeIsPercentage || null,
    incomeCommitment: variant?.incomeCommitment || '',
    description: variant?.description || '',
    productID: variant?.productID || productID,
  };
}

export const dinymacTags: DynamicTagsProps[] = [
  {
    title: '',
    field: 'customerTypeID',
    bgColor: '#EDF7FC',
  },
  {
    title: 'Taxa:',
    field: 'fee',
    bgColor: '#EDFCF2',
    suffix: '%',
    labelFormatter: (value: any) => format(value),
  },
  {
    title: 'TAC:',
    field: 'tac',
    bgColor: '#EDFCF2',
    suffix: '%',
    labelFormatter: (value: any) => format(value),
  },
  {
    title: 'Parc. mín:',
    field: 'minInstallmentQuantity',
    bgColor: '#FCEDED',
  },
  {
    title: 'Parc. máx:',
    field: 'maxInstallmentQuantity',
    bgColor: '#FCEDED',
  },
  {
    title: 'Valor mín:',
    field: 'minValue',
    prefix: 'R$',
    bgColor: '#FCF9ED',
    labelFormatter: (value: any) => format(value),
  },
  {
    title: 'Valor máx:',
    field: 'maxValue',
    prefix: 'R$',
    bgColor: '#FCF9ED',
    labelFormatter: (value: any) => format(value),
  },
];
