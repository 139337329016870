import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RiSearchLine } from 'react-icons/ri';

import {
  Navbar,
  Button,
  Breadcrumb,
  Table,
} from '../../../components';

import { userService } from '../../../services';
import {
  tableContentKeys,
  tableHeaderTitles,
  userBreadCrumbItems,
} from '../../../mock';

import {
  Container, Header, SubHeader, SearchInput,
} from './styles';

import { UserSDTO } from '../../../@types/User';
import { TableColumn } from '../../../components/ServiceTable/table.spec';
import TableComponent from '../../../components/ServiceTable';

const Users: React.FC = () => {
  const [users, setUsers] = useState<UserSDTO[]>([]);
  const userColumns: TableColumn<UserSDTO>[] = [
    {
      title: 'Nome',
      propName: 'person',
    },
    {
      title: 'E-mail',
      propName: 'person',
    },
    {
      title: 'Tipo de usuário',
      propName: 'personID',
    },
    {
      title: 'Alçada',
      propName: 'person',
    },
  ];

  useEffect(() => {
    setUsers(userService.get());
  }, []);

  function handleDeleteUsers(id: number): void {
    users.splice(id, 1);
  }

  return (
    <Container>
      <Header>
        <h1>
          <Breadcrumb breadCrumbItems={userBreadCrumbItems} />
          Usuários
        </h1>
        <Navbar />
      </Header>

      <SubHeader>
        <SearchInput>
          <RiSearchLine />
          <input type="text" placeholder="Buscar usuário" />
        </SearchInput>
        <Link to="/registration/users/create">
          <Button message="Novo usuário" />
        </Link>
      </SubHeader>

      {/* <TableComponent
        service={userService}
        tableColumns={userColumns}
        excludeModalTexts={{
          type: 'user',
          visibleValue: 'name',
        }}
        itemBaseURL="/registration/users"
      /> */}

      <Table
        tableHeader={tableHeaderTitles}
        tableKeys={tableContentKeys}
        tableData={users}
        handleDeleteItem={(id: number) => {
          handleDeleteUsers(id);
        }}
        excludeModalTexts={{
          type: 'users',
          visibleValue: 'name',
        }}
      />
    </Container>
  );
};

export default Users;
