import React, { ReactElement } from 'react';

import {
  RiBodyScanFill,
  RiCloseFill,
  RiGroupFill,
  RiMapPin2Fill,
  RiMoneyDollarCircleFill,
  RiProfileFill,
  RiUser2Fill,
} from 'react-icons/ri';

import { Checkbox } from '..';
import Switch from '../Switch';

import {
  Container,
  ProductDataParamsModalContent,
  ProductDataParamsHeader,
  ProductDataParams,
  ProductDataParamsItems,
  ProductDataParamsItem,
  SwitchBox,
} from './styles';

const WorkflowControlModal = ({ workflowItems, title }: any): ReactElement => {
  const iconsList = [
    <RiMapPin2Fill />,
    <RiProfileFill />,
    <RiBodyScanFill />,
    <RiMoneyDollarCircleFill />,
    <RiUser2Fill />,
    <RiGroupFill />,
  ];

  return (
    <Container>
      <ProductDataParamsModalContent>
        <ProductDataParamsHeader>
          <h2>{title}</h2>
          <button type="button">
            <RiCloseFill />
          </button>
        </ProductDataParamsHeader>
        {workflowItems?.length ? (
          workflowItems?.map((item: any) => (
            <ProductDataParams key={item?.mainTitle?.title}>
              <h3>
                {item?.mainTitle?.title}
                {item?.mainTitle?.checked !== undefined && (
                  <Checkbox
                    checked={item?.mainTitle?.checked}
                    disabled={item?.mainTitle?.checked}
                  />
                )}
              </h3>
              {item?.mainTitle?.type === 'checkbox' ? (
                <ProductDataParamsItems>
                  {item?.items?.map((childItem: any) => (
                    <ProductDataParamsItem
                      key={childItem?.title}
                      disabled={childItem?.checked}
                    >
                      <Checkbox
                        checked={childItem?.checked}
                        disabled={childItem?.checked}
                      />
                      {childItem?.title}
                    </ProductDataParamsItem>
                  ))}
                </ProductDataParamsItems>
              ) : (
                <>
                  {item?.items?.map((childItem: any, index: number) => (
                    <SwitchBox key={childItem?.title}>
                      <p>
                        {iconsList[index]}
                        {childItem?.title}
                      </p>
                      <Switch
                        name="businessPartner"
                        onStatus="Obrigatório"
                        offStatus="Não obrigatório"
                        position="left"
                        checked={childItem?.checked}
                      />
                    </SwitchBox>
                  ))}
                </>
              )}
            </ProductDataParams>
          ))
        ) : (
          <h3>Sem dados a serem exibidos.</h3>
        )}
      </ProductDataParamsModalContent>
    </Container>
  );
};

export default WorkflowControlModal;
