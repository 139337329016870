import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProductDataParamsModalContent = styled.div`
  ${({ theme }) => css`
    border-radius: 4px;
    background-color: ${theme.colors.white00};
    padding: 24px;
    max-width: 1000px;
    width: 100%;

    > h3 {
      color: ${theme.colors.black00};
      ${theme.texts.paragraphMD};
    }
  `};
`;

export const ProductDataParams = styled.div`
  ${({ theme }) => css`
    > h3 {
      color: ${theme.colors.black00};
      ${theme.texts.headingXXS};

      display: flex;
      align-items: center;

      > label {
        margin: 0 0 0 12px;
      }

      .checkbox {
        width: 14px;
        height: 14px;
      }
    }
  `};
`;

export const ProductDataParamsItems = styled.div`
  margin: 24px 0 0 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
`;

export const ProductDataParamsItem = styled.div<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    color: ${theme.colors.black00};
    ${theme.texts.paragraphSM};

    display: flex;
    align-items: center;

    .checkbox {
      width: 14px;
      height: 14px;
    }

    ${disabled
    && css`
      opacity: 0.5;
    `};
  `};
`;

export const ProductDataParamsHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 0 24px 0;

    > h2 {
      color: ${theme.colors.black00};
      ${theme.texts.headingSM};
    }

    > button {
      width: 40px;
      height: 40px;
      border: 1px solid ${theme.colors.black10};
      border-radius: 4px;
      background-color: transparent;

      transition: ${theme.transitions.default};

      > svg {
        font-size: 2rem;
      }

      &:hover {
        border-color: ${theme.colors.black00};
      }
    }
  `};
`;

export const SwitchBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;

    > p {
      ${theme.texts.paragraphXS};

      display: flex;
      align-items: center;
    
      > svg {
        margin-right: 10px;
        font-size: 1.4rem;
      }
    }
  `}
`;
