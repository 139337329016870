import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  Home,
  Users,
  Products,
  Partners,
  Representatives,
  Customers,
} from '../pages';

import UserInfo from '../pages/Registration/Users/UserInfo';
import ProductInfo from '../pages/Registration/Products/ProductInfo';
import PartnerInfo from '../pages/Registration/AgencyOffice/AgencyOfficeInfo';
import CustomerInfo from '../pages/Registration/Customers/CustomerInfo';
import RepresentativeInfo from '../pages/Registration/Representatives/RepresentativeInfo';
import ProductModals from '../pages/ProductModals';
import Workflow from '../pages/Management/Workflow';

import { InfoMode } from '../@types/InfoMode';
import ScoreParameterizations from '../components/ScoreParameterizations';

import DashboardLayout from '../layouts/DashboardLayout';
import Operations from '../pages/Operational/Operations';

const AuthenticatedRoutes: React.FC = () => (
  <Routes>
    {/* Temporary route */}
    <Route path="parametrizacao-score" element={<ScoreParameterizations />} />
    <Route path="products-modals" element={<ProductModals />} />

    <Route
      path=""
      element={(
        <DashboardLayout>
          <Home />
        </DashboardLayout>
      )}
    />
    <Route path="registration">
      <Route
        index
        element={(
          <DashboardLayout>
            <Users />
          </DashboardLayout>
        )}
      />
      {/* Registration -> Users routes */}
      <Route path="users">
        <Route
          path=""
          element={(
            <DashboardLayout>
              <Users />
            </DashboardLayout>
          )}
        />
        <Route path="view/:id" element={<UserInfo mode={InfoMode.VIEW} />} />
        <Route path="create" element={<UserInfo mode={InfoMode.CREATE} />} />
        <Route path="edit/:id" element={<UserInfo mode={InfoMode.EDIT} />} />
      </Route>
      {/* Registration -> Products routes */}
      <Route path="products">
        <Route
          index
          path=""
          element={(
            <DashboardLayout>
              <Products />
            </DashboardLayout>
          )}
        />
        <Route path="view/:id" element={<ProductInfo mode={InfoMode.VIEW} />} />
        <Route path="create" element={<ProductInfo mode={InfoMode.CREATE} />} />
        <Route path="edit/:id" element={<ProductInfo mode={InfoMode.EDIT} />} />
      </Route>
      {/* Registration -> Partners routes */}
      <Route path="partners">
        <Route
          index
          path=""
          element={(
            <DashboardLayout>
              <Partners />
            </DashboardLayout>
          )}
        />
        <Route path="view/:id" element={<PartnerInfo mode={InfoMode.VIEW} />} />
        <Route path="create" element={<PartnerInfo mode={InfoMode.CREATE} />} />
        <Route path="edit/:id" element={<PartnerInfo mode={InfoMode.EDIT} />} />
      </Route>
      {/* Registration -> Representatives routes */}
      <Route path="representatives">
        <Route
          index
          path=""
          element={(
            <DashboardLayout>
              <Representatives />
            </DashboardLayout>
          )}
        />
        <Route
          path="view/:id"
          element={<RepresentativeInfo mode={InfoMode.VIEW} />}
        />
        <Route
          path="create"
          element={<RepresentativeInfo mode={InfoMode.CREATE} />}
        />
        <Route
          path="edit/:id"
          element={<RepresentativeInfo mode={InfoMode.EDIT} />}
        />
      </Route>
      {/* Registration -> Customers routes */}
      <Route path="customers">
        <Route
          index
          path=""
          element={(
            <DashboardLayout>
              <Customers />
            </DashboardLayout>
          )}
        />
        <Route
          path="view/:id"
          element={<CustomerInfo mode={InfoMode.VIEW} />}
        />
        <Route
          path="create"
          element={<CustomerInfo mode={InfoMode.CREATE} />}
        />
        <Route
          path="edit/:id"
          element={<CustomerInfo mode={InfoMode.EDIT} />}
        />
      </Route>
    </Route>
    {/* Management -> Workflow routes */}
    <Route path="management">
      <Route
        path=""
        index
        element={(
          <DashboardLayout>
            <Workflow />
          </DashboardLayout>
        )}
      />
      <Route
        index
        path="workflows"
        element={(
          <DashboardLayout>
            <Workflow />
          </DashboardLayout>
        )}
      />
    </Route>

    {/* Operational -> Operations routes */}
    <Route path="operations">
      <Route
        index
        path=""
        element={(
          <DashboardLayout>
            <Operations />
          </DashboardLayout>
        )}
      />
    </Route>
  </Routes>
);

export default AuthenticatedRoutes;
