import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RiSearchLine } from 'react-icons/ri';

import {
  Navbar,
  Button,
  Breadcrumb,
  Table,
} from '../../../components';

import { representativesService } from '../../../services';

import {
  representativeTableContentKeys,
  representativeTableHeaderTitles,
  representativesBreadCrumbItems,
} from '../../../mock';

import {
  Container, Header, SubHeader, SearchInput,
} from './styles';

import { RepresentativeProps } from '../../../@types';

const Representatives: React.FC = () => {
  const [representatives, setRepresentatives] = useState<RepresentativeProps[]>([]);

  useEffect(() => {
    setRepresentatives(representativesService.get());
  }, []);

  function handleDeleteRepresentatives(id: number): void {
    representatives.splice(id, 1);
  }

  return (
    <Container>
      <Header>
        <h1>
          <Breadcrumb breadCrumbItems={representativesBreadCrumbItems} />
          Representantes
        </h1>
        <Navbar />
      </Header>

      <SubHeader>
        <SearchInput>
          <RiSearchLine />
          <input type="text" placeholder="Buscar representante" />
        </SearchInput>
        <Link to="/registration/representatives/create">
          <Button message="Novo representante" />
        </Link>
      </SubHeader>

      <Table
        tableHeader={representativeTableHeaderTitles}
        tableData={representatives}
        tableKeys={representativeTableContentKeys}
        handleDeleteItem={(id: number) => {
          handleDeleteRepresentatives(id);
        }}
        excludeModalTexts={{
          type: 'representatives',
          visibleValue: 'name',
        }}
      />
    </Container>
  );
};

export default Representatives;
