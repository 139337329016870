import React, { ReactElement, useMemo } from 'react';
import { RiArrowDropLeftFill, RiArrowDropRightFill } from 'react-icons/ri';
import { Page } from '../../@types/Core';

import { Container, LeftContent, RightContent } from './styles';

interface Props {
  pagination: Pick<Required<Page<any>>, 'number' | 'size' | 'totalPages' | 'totalElements' | 'numberOfElements' | 'first' | 'last'>;
  previous: () => void;
  next: () => void;
  goTo: (pageNumber: number) => void;
}

const ServiceTablePagination = ({
  pagination, previous, next, goTo,
}: Props): ReactElement => {
  const {
    number, size, totalPages, totalElements, numberOfElements, first, last,
  } = pagination;

  const pageNumbers = useMemo((): Array<number> => {
    if (size >= totalPages) {
      return new Array(totalPages).fill(null).map((_, index) => index + 1);
    }
    if (number < size - 3) {
      const numbers = new Array(size)
        .fill(null)
        .map<number>((_, index) => index + 1);
      numbers[numbers.length - 2] *= -1;
      numbers[numbers.length - 1] = totalPages;
      return numbers;
    }
    if (number > totalPages - size + 3) {
      const numbers = new Array(size)
        .fill(null)
        .map<number>((_, index) => index + totalPages - size + 1);
      numbers[0] = 1;
      numbers[1] *= -1;
      return numbers;
    }
    const middlePages = size - 4;
    const startPage = number - Math.floor(middlePages / 2);

    const numbers = [
      1,
      -2,
      ...new Array(middlePages).fill(null).map((_, index) => index + startPage),
      1 - totalPages,
      totalPages,
    ];
    return numbers;
  }, [size, totalPages, number]);
  return (
    <Container>
      <LeftContent>
        Exibindo
        <p>{numberOfElements}</p>
        resultado(s) de
        <p>{totalElements}</p>
        ao total
      </LeftContent>
      <RightContent>
        <button
          type="button"
          onClick={previous}
          disabled={first}
        >
          <RiArrowDropLeftFill />
        </button>
        {pageNumbers.map((num) => (num > 0 ? (
          <button
            type="button"
            key={num - 1}
            onClick={() => goTo(num - 1)}
            className={`${number === num - 1 ? 'active' : ''}`}
            disabled={number === num - 1}
          >
            {num}
          </button>
        ) : (
          <span key={num}>…</span>
        )))}
        <button
          type="button"
          onClick={next}
          disabled={last}
        >
          <RiArrowDropRightFill />
        </button>
      </RightContent>
    </Container>
  );
};

export default ServiceTablePagination;
