import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RiSearchLine } from 'react-icons/ri';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  Navbar, Button, Breadcrumb, Table,
} from '../../../components';

import {
  Container,
  Header,
  SubHeader,
  SearchInput,
  Filters,
  SelectControl,
} from './styles';

import { operationsBreadCrumbItems } from '../../../mock/breadCrumbItems';
import TableComponent from '../../../components/ServiceTable';
import { TableColumn } from '../../../components/ServiceTable/table.spec';
import { OperationSDTO } from '../../../@types';
import { operationService } from '../../../services';
import { operationTableContentKeys, operationTableHeaderTitles } from '../../../mock/operation';

const Operations: React.FC = () => {
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates: any): any => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const [operations, setOperations] = useState<OperationSDTO[]>([]);

  const operationColumns: TableColumn<OperationSDTO>[] = [
    {
      title: 'Produto',
      propName: 'productName',
    },
    {
      title: 'Operação',
      propName: 'operationID',
    },
    {
      title: 'Cliente',
      propName: 'customer',
    },
    {
      title: 'Valor',
      propName: 'value',
    },
    {
      title: 'Status',
      propName: 'enabled',
    },
    {
      title: 'Data',
      propName: 'date',
    },
    {
      title: 'Agente',
      propName: 'agencyOffice',
    },
  ];

  useEffect(() => {
    setOperations(operationService.get());
  }, []);

  function handleDeleteOperations(id: number): void {
    operations.splice(id, 1);
  }

  return (
    <Container>
      <Header>
        <h1>
          <Breadcrumb breadCrumbItems={operationsBreadCrumbItems} />
          Operações
        </h1>
        <Navbar />
      </Header>

      <SubHeader>
        <SearchInput>
          <RiSearchLine />
          <input type="text" placeholder="Buscar operação" />
        </SearchInput>
        <Link to="/operational/operations/create">
          <Button message="Incluir operação" />
        </Link>
      </SubHeader>

      <Filters>
        <SelectControl>
          <small>Tipo de produto</small>
          <select
            name="status"
            value={status}
            onChange={({ target: { value } }) => setStatus(value)}
          >
            <option value="">Todos</option>
            <option value="approved">Aprovadas</option>
            <option value="rejected">Rejeitadas</option>
            <option value="pending">Aguardando aprovacao</option>
          </select>
        </SelectControl>
        <SelectControl>
          <small>Valor</small>
          <select
            name="status"
            value={status}
            onChange={({ target: { value } }) => setStatus(value)}
          >
            <option value="">Todos</option>
            <option value="approved">Aprovadas</option>
            <option value="rejected">Rejeitadas</option>
            <option value="pending">Aguardando aprovacao</option>
          </select>
        </SelectControl>
        <SelectControl>
          <small>Status</small>
          <select
            name="status"
            value={status}
            onChange={({ target: { value } }) => setStatus(value)}
          >
            <option value="">Todos</option>
            <option value="approved">Aprovadas</option>
            <option value="rejected">Rejeitadas</option>
            <option value="pending">Aguardando aprovacao</option>
          </select>
        </SelectControl>
        <SelectControl>
          <small>Período</small>
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            dateFormat="dd/MM/yy"
          />
        </SelectControl>
        <SelectControl>
          <small>Agente</small>
          <select
            name="status"
            value={status}
            onChange={({ target: { value } }) => setStatus(value)}
          >
            <option value="">Todos</option>
            <option value="approved">Aprovadas</option>
            <option value="rejected">Rejeitadas</option>
            <option value="pending">Aguardando aprovacao</option>
          </select>
        </SelectControl>
      </Filters>

      {/* <TableComponent
        service={operationService}
        tableColumns={operationColumns}
        excludeModalTexts={{
          type: 'operation',
          visibleValue: 'name',
        }}
        itemBaseURL="/operational/operations"
      /> */}

      <Table
        tableHeader={operationTableHeaderTitles}
        tableData={operations}
        tableKeys={operationTableContentKeys}
        handleDeleteItem={(id: number) => {
          handleDeleteOperations(id);
        }}
        excludeModalTexts={{
          type: 'operations',
          visibleValue: 'name',
        }}
      />
    </Container>
  );
};

export default Operations;
