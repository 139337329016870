import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${() => css`
    padding: 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    > h1 {
      ${theme.texts.headingMD};
    }

    > a {
      border: 1px solid ${theme.colors.black20};
      border-radius: 4px;

      width: 54px;
      height: 54px;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: ${theme.transitions.default};

      :hover { 
        border: 1px solid ${theme.colors.black00};
      }

      > svg {
        font-size: 2rem;
        color: ${theme.colors.black00};
      }
    }
  `}
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ActionsButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    align-items: center;
    padding-top: 24px;

    > p {
      ${theme.texts.buttonXS};
      color: ${theme.colors.black00};
      display: flex;
      align-items: center;

      > svg {
        font-size: 16px;
        margin-right: 10px;
      }
    }

    > button {
      height: 42px;
      border-radius: 4px;
      margin-left: auto;
      padding: 10px 20px;
      width: fit-content;
      
      ${theme.texts.buttonXS};
    }
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.black10};
    padding: 24px 0 0;
    width: 100%;
    position: absolute;
    bottom: 66px;
    left: 0;
  `}
`;
