import React, { ReactElement } from 'react';
import {
  Container, LoadingCircle, Circle, Icon,
} from './styles';

import { ButtonsProps } from './button.spec';

const Button = ({
  message = '',
  variant = 'primary',
  icon = null,
  iconPosition = 'right',
  onClick,
  isLoading = false,
  loadingMessage = 'Carregando...',
  disabled,
  ...rest
}: ButtonsProps): ReactElement => (
  <Container disabled={disabled} variant={variant} onClick={onClick} {...rest}>
    {!isLoading ? (
      <>
        <Icon position={iconPosition}>{icon}</Icon>
        <span>{message}</span>
      </>
    ) : (
      <>
        {loadingMessage}
        <LoadingCircle>
          <Circle />
        </LoadingCircle>
      </>
    )}
  </Container>
);

export default Button;
