import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  max-width: 430px;
  width: 100%;
  margin: 0 24px 0 0;
  
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DraggableArea = styled.div``;

export const DraggableAreaContent = styled.div``;

export const DragList = styled.div``;

export const ItemsList = styled.ul``;

export const Item = styled.ul`
  
`;

export const NestedContainer = styled.div`
  flex: 1;

  display: flex;
  width: 100%;
  /* justify-content: space-between; */
`;

export const SidebarStages = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.black05};
    max-width: 430px;
    width: 100%;
    border-radius: 6px;
    padding: 32px 32px 0 32px;
    /* position: sticky;
    top: 24px; */

    /* position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden; */

    display: flex;
    flex-direction: column;

    > h3 {
      ${theme.texts.headingXXS};
    }
  `};
`;

export const SearchInput = styled.div`
  ${({ theme }) => css`
    margin: 24px 0 12px 0;
    position: relative;

    display: flex;
    align-items: center;

    > svg {
      position: absolute;
      left: 18px;
      font-size: 1.6rem;
    }

    > input {
      background-color: ${theme.colors.white};
      border-radius: 4px;
      height: 42px;
      padding: 10px 18px 10px 44px;
      ${theme.texts.paragraphMD};
      border: 1px solid transparent;
      width: 100%;
      transition: ${theme.transitions.default};

      &::placeholder {
        color: ${theme.colors.black50};
      }

      &:focus {
        border-color: ${theme.colors.black00};
      }
    }
  `};
`;

export const AccordionItems = styled.div`
  flex: 1;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const AccordionItem = styled.div`
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 32px;
  }
`;

export const AccordionItemHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    > svg {
      font-size: 1.4rem;
      color: ${theme.colors.black00};
    }

    > small {
      color: ${theme.colors.black00};
      ${theme.texts.paragraphSM};
      margin: 14px 0;
    }
  `};
`;

export const AccordionItemContent = styled.div<{ opened?: boolean }>`
  ${({ opened }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: 14px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 0;
    padding: 0;

    transition: height 300ms ease-in-out;

    ${opened
    && css`
      height: auto;
      white-space: unset;
    `};
  `};
`;

export const WorkflowOnBoarding = styled.div`
  ${({ theme }) => css`
    max-width: 340px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    > img {
      max-width: 98px;
      width: 100%;
      margin: 0 auto;
    }

    > h2 {
      text-align: center;
      ${theme.texts.buttonLG};
      max-width: 234px;
      margin: 24px auto;
      letter-spacing: -0.2px;
    }
    
    > button {
      margin: 14px 0 0 0;
      height: 42px;
      ${theme.texts.buttonXS};
      border-radius: 4px;
    }
  `};
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    ${theme.texts.buttonXS};
    color: ${theme.colors.black50};
    text-align: center;
    margin: 14px 0 0 0;

    display: flex;
    align-items: center;
    justify-content: center;

    &::before, &::after {
      content: '';
      max-width: 20px;
      width: 100%;
      border-bottom: 1px solid ${theme.colors.black50};
    }

    &:not(:empty)::before {
      margin-right: 12px;
    }

    &:not(:empty)::after {
      margin-left: 12px;
    }
  `};
`;

// export const DraggableArea = styled.div`
//   width: 100%;

//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 4px;
//   margin-left: 12px;

//   border: 2px dashed #1c1c1c;
// `;
