import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > label {
      ${theme.texts.paragraphXXS};
      margin: 0 0 8px 0;

      > small {
        color: ${theme.colors.black50};
        font-style: italic;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
      }
    }

    > input {
      border-radius: 4px;
      background-color: ${theme.colors.black05};
      border: 0;
      height: 42px;
      padding: 12px 16px;
      transition: ${theme.transitions.default};
      ${theme.texts.paragraphXS}
    
      &::placeholder {
        color: ${theme.colors.black30};
      }

      &:disabled {
        color: ${theme.colors.black00};
        background-color: ${theme.colors.white80};
      }
    }

    &.error {
      > small {
        color: ${theme.colors.error};
      }
      > input {
        border-color: ${theme.colors.error};
      }
    }
  `};
`;
