import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { InfoMode } from '../../../../../@types/InfoMode';
import { ProductSubType } from '../../../../../@types/ProductSubType';
import { ProductType } from '../../../../../@types/ProductType';
import { Input, Select, Textarea } from '../../../../../components';
import Switch from '../../../../../components/Switch';
import TooltipComponent from '../../../../../components/Tooltip';
import {
  productSubTypeService,
  productTypeService,
} from '../../../../../services';
import { ProductContext } from '../../ProductInfo';

import {
  Container, InputRow, InputSolo, RowSwitch,
} from './styles';

const ProductData = (): ReactElement => {
  const { t } = useTranslation();

  const { formik, mode } = useContext(ProductContext);
  const product = formik?.values;

  const [selectProductType, setSelectProductType] = useState<ProductType[]>();
  const [selectProductSubType, setSelectProductSubType] = useState<ProductSubType[]>();

  useEffect((): void => {
    fetchTypes();
    fetchSubTypes();
  }, []);

  async function fetchTypes(): Promise<void> {
    try {
      const content = await productTypeService.getList();
      setSelectProductType(content);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchSubTypes(): Promise<void> {
    try {
      const content = await productSubTypeService.getList();
      setSelectProductSubType(content);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      <RowSwitch>
        {mode === InfoMode.VIEW ? (
          <TooltipComponent
            text="Você está no modo de visualização, para editar os dados desse produto, clique em “Editar”."
            tooltip={(
              <Switch
                name="enabled"
                label={t('productData.labelInputStatus') || ''}
                checked={product.enabled}
                value={product.enabled}
                onChange={formik.handleChange}
                disabled={mode === InfoMode.VIEW}
              />
            )}
          />
        ) : (
          <Switch
            name="enabled"
            label={t('productData.labelInputStatus') || ''}
            checked={product.enabled}
            value={product.enabled}
            onChange={formik.handleChange}
          />
        )}
      </RowSwitch>
      <InputRow>
        <Input
          name="name"
          value={product.name}
          onChange={formik.handleChange}
          label={t('productData.labelInputName') || ''}
          placeholder={t('productData.placeholderInputName') || ''}
          disabled={mode === InfoMode.VIEW}
          required
        />

        <Select
          name="typeID"
          label={t('productData.labelInputModality') || ''}
          onChange={(selectedOption: any) => {
            formik.setFieldValue('subtypeID', null);
            formik.setFieldValue('typeID', selectedOption.id);
          }}
          value={
            product.typeID
              ? selectProductType?.find((e) => e.id === product.typeID)
              : selectProductType?.find(
                (e) => e.id
                    === selectProductSubType?.find((i) => i.id === product.subtypeID)
                      ?.typeID,
              )
          }
          isDisabled={mode === InfoMode.VIEW}
          options={selectProductType}
        />

        <Select
          name="subtypeID"
          label={t('productData.labelInputSubmodality') || ''}
          onChange={(selectedOption: any) => formik.setFieldValue('subtypeID', selectedOption.id)}
          value={
            product.subtypeID
              ? selectProductSubType?.find((e) => e.id === product.subtypeID)
              : null
          }
          isDisabled={mode === InfoMode.VIEW}
          options={selectProductSubType?.filter(
            (e) => e.typeID === product?.typeID,
          )}
        />
      </InputRow>

      <InputSolo>
        <Textarea
          name="description"
          label={t('productData.labelInputDescription') || ''}
          placeholder={t('productData.placeholderInputDescription') || ''}
          rows={4}
          cols={50}
          value={product.description}
          onChange={formik.handleChange}
          disabled={mode === InfoMode.VIEW}
        />
      </InputSolo>
    </Container>
  );
};

export default ProductData;
