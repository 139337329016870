import { OperationDTO, OperationSDTO } from '../@types/Operation';
import operationItems from '../mock/operation';
import { BaseService } from './baseService';

// class OperationService extends BaseService<OperationDTO, OperationSDTO> {
//   baseURL = '/operations';
// }

// export default new OperationService();

export default {
  get(): any {
    try {
      return operationItems;
    } catch (error) {
      return console.log('Unable to list operation items!');
    }
  },
};
