import React, { ReactElement } from 'react';

import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { RiFolder5Fill, RiUpload2Line } from 'react-icons/ri';

import Button from '../Button';

import { Props } from './UploadDocument.spec';

import {
  Container,
  UploadDocumentContent,
  ChooseFile,
  ListDocumentContent,
} from './styles';
import { CoreFile } from '../../@types';
import ListDocument from './ListDocument';
import { InfoMode } from '../../@types/InfoMode';

const UploadDocument = ({
  documents,
  setDocuments,
  mode,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: mode === InfoMode.VIEW,
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
      'application/doc': ['.docx', '.doc'],
      'application/xls': ['.xlsx', '.xls'],
    },
  });

  const modeView = mode !== InfoMode.VIEW;

  function onDrop(acceptedFiles: File[]): void {
    if (acceptedFiles.length) {
      setDocuments([
        ...acceptedFiles.map(
          (file): CoreFile => ({
            id: -Math.random(),
            filename: file.name,
            size: file.size,
            mimeType: file.type,
            file,
          }),
        ),
        ...documents,
      ]);
    }
  }

  const removeFile = (userFile: CoreFile): void => {
    if (userFile.id < 0) {
      setDocuments(documents.filter((e) => e.id !== userFile.id));
    }
  };

  return (
    <Container>
      {modeView && (
        <>
          <p>{t('uploadDocumentComponent.titleDocument')}</p>
          <UploadDocumentContent>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <RiFolder5Fill />
              <p>{t('uploadDocumentComponent.textDocument')}</p>

              <ChooseFile>
                <Button
                  type="button"
                  message="Escolher arquivo(s)"
                  icon={<RiUpload2Line />}
                />
                <span>{t('uploadDocumentComponent.sizeMaxDocument')}</span>
              </ChooseFile>
            </div>
          </UploadDocumentContent>
        </>
      )}

      <p>{t('uploadDocumentComponent.titleListDocument')}</p>
      <ListDocumentContent>
        {documents.length > 0 ? (
          <>
            {documents.map((file: CoreFile) => (
              <ListDocument
                key={file?.id}
                file={file}
                onRemove={removeFile}
                mode={mode}
              />
            ))}
          </>
        ) : (
          <p>{t('uploadDocumentComponent.textNoAttachment')}</p>
        )}
      </ListDocumentContent>
    </Container>
  );
};

export default UploadDocument;
