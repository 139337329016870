import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  margin: 0 0 6px 0;
`;

export const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    ${theme.texts.paragraphSM};

    &:not(:last-child) {
      opacity: 0.5;

      &:after {
        content: '>';
        margin: 0 4px;
        font-size: 1rem;

        display: flex;
        align-items: center;
      }
    }
  `};
`;
