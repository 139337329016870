import { faker } from '@faker-js/faker';
import { CoreSelect } from '../@types';

export const selectJurisdictionItems: CoreSelect[] = [];
export const selectEducationItems: CoreSelect[] = [];
export const selectGenderItems: CoreSelect[] = [];
export const selectAccessDays: CoreSelect[] = [];
export const selectAccessTime: CoreSelect[] = [];
export const selectAccessExternal: CoreSelect[] = [];
export const selectChangeRates: CoreSelect[] = [];
export const selectCustomers: CoreSelect[] = [];
export const selectModality: CoreSelect[] = [];
export const selectSubmodality: CoreSelect[] = [];

export function createRandomSelectItem(): any {
  return {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
  };
}

const options = [
  selectJurisdictionItems,
  selectEducationItems,
  selectGenderItems,
  selectAccessDays,
  selectAccessTime,
  selectAccessExternal,
  selectChangeRates,
  selectCustomers,
  selectModality,
  selectSubmodality,
];

Array.from({ length: 4 }).forEach(() => {
  options.forEach((element) => element.push(createRandomSelectItem()));
});
