import React, { ReactElement } from 'react';
import { RiCloseFill, RiArrowLeftLine } from 'react-icons/ri';

import { ModalProps } from './Modal.spec';

import {
  Container,
  MainContent,
  Header,
} from './styles';

const Modal = ({
  title, onClose, children, hasPrev, maxWidth,
}: ModalProps): ReactElement => (
  <Container>
    <MainContent maxWidth={maxWidth}>
      <Header>
        {hasPrev && (
          <button type="button">
            <RiArrowLeftLine />
          </button>
        )}
        <h2>{title}</h2>
        <button type="button" onClick={onClose}>
          <RiCloseFill />
        </button>
      </Header>
      {children}
    </MainContent>
  </Container>
);

export default Modal;
