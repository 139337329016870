import React from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import {
  Breadcrumb, Button, Navbar,
} from '../../../components';

import {
  productService,
} from '../../../services';

import { productsBreadCrumbItems } from '../../../mock';

import {
  Container, Header, SearchInput, SubHeader,
} from './styles';

import { ProductSDTO } from '../../../@types/Product';
import TableComponent from '../../../components/ServiceTable';
import { TableColumn } from '../../../components/ServiceTable/table.spec';

const Products: React.FC = () => {
  const productColumns: TableColumn<ProductSDTO>[] = [
    {
      title: 'Nome',
      propName: 'name',
    },
    {
      title: 'Modalidade',
      propName: 'typeText',
    },
    {
      title: 'Submodalidade',
      propName: 'subtypeText',
    },
  ];

  return (
    <Container>
      <Header>
        <h1>
          <Breadcrumb breadCrumbItems={productsBreadCrumbItems} />
          Produtos
        </h1>
        <Navbar />
      </Header>

      <SubHeader>
        <SearchInput>
          <RiSearchLine />
          <input type="text" placeholder="Buscar produto" />
        </SearchInput>
        <Link to="/registration/products/create">
          <Button message="Novo produto" />
        </Link>
      </SubHeader>

      <TableComponent
        service={productService}
        tableColumns={productColumns}
        excludeModalTexts={{
          type: 'product',
          visibleValue: 'name',
        }}
        itemBaseURL="/registration/products"
      />
    </Container>
  );
};

export default Products;
