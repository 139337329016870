import React, {
  createContext, ReactElement, useState, useEffect, useMemo,
} from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RiCloseFill } from 'react-icons/ri';
import { Button, TabNavigation, Breadcrumb } from '../../../../components';

import { InfoMode } from '../../../../@types/InfoMode';

import {
  Container, Form, ActionsButton, Header, Divider,
} from './styles';
import { CustomerContextValue, CustomerInfoProps, getInitialValues } from './CustomerInfo.spec';
import { CoreCustomer, CoreFile } from '../../../../@types';
import { tabNavigationItemsCustomers } from '../../../../mock';
import { customersBreadCrumbItems } from '../../../../mock/breadCrumbItems';
import { customerService } from '../../../../services';

export const CustomerContext = createContext({} as CustomerContextValue);

const CustomerInfo = ({ mode }: CustomerInfoProps): ReactElement => {
  const { t } = useTranslation();

  const [customer, setCustomer] = useState<CoreCustomer | any>();
  const [documents, setDocuments] = useState<CoreFile[]>([]);

  const initialValues = useMemo(() => getInitialValues(customer), [customer]);

  useEffect((): void => {
    if (mode !== InfoMode.CREATE) {
      fetchData();
    }
  }, [mode]);

  async function fetchData(): Promise<void> {
    try {
      const response = await customerService.getMe();
      setCustomer(response);
      setDocuments(response.documents);
    } catch (err: any) {
      console.log(err);
    }
  }

  const formik = useFormik<CoreCustomer>({
    initialValues,
    enableReinitialize: true,
    onSubmit(values: any) {
      if (mode === InfoMode.CREATE) {
        return;
      }
      const customerData = Object.entries(values).reduce((acc, [key, value]) => {
        if (formik.initialValues[key as keyof CoreCustomer] !== value) {
          return {
            ...acc,
            [key]: value,
          };
        }
        return acc;
      }, {} as Partial<CoreCustomer>);

      try {
        const data = customerService.edit(customerData);
        setCustomer(data);
        fetchData();
      } catch (err: any) {
        console.log(err);
      }
    },
  });

  return (
    <Container>
      <Header>
        <h1>
          <Breadcrumb breadCrumbItems={customersBreadCrumbItems} />
          {t(`customerInfo.${mode}.title`)}
        </h1>

        <Link to="/registration/customers">
          <RiCloseFill />
        </Link>
      </Header>

      <CustomerContext.Provider
        value={{
          formik,
          documents,
          setDocuments,
          mode,
        }}
      >
        <Form onSubmit={formik.handleSubmit}>
          <TabNavigation tabNavigationItems={tabNavigationItemsCustomers} />
          <Divider />
          <ActionsButton>
            {mode === InfoMode.VIEW ? (
              <Button type="button" message={t(`customerInfo.${mode}.button`)} />
            ) : (
              <Button type="submit" message={t(`customerInfo.${mode}.button`)} />
            )}
          </ActionsButton>
        </Form>
      </CustomerContext.Provider>
    </Container>
  );
};

export default CustomerInfo;
