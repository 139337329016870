import React, {
  createContext, ReactElement, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { RiAddFill } from 'react-icons/ri';
import { FormikProps, useFormik } from 'formik';
import Button from '../Button';
import DynamicList from '../DynamicList';
import Modal from '../Modal';
import HandshakeIcon from '../../assets/HandshakeIcon.svg';
import FormScoreParameterizations from './FormScoreParameterizations';

import {
  Container, HeaderBox, BoxLeft, BoxRight, NoContent,
} from './styles';
import { CoreWorkflow } from '../../@types/ScoreParameterizations';
import { getInitialValues } from './ScoreParameterizations.spec';
import { dinymacTags } from '../../pages/Registration/Products/Breadcrumb/ProductVariant/ProductVariant.spec';

export const ScoreContext = createContext({} as ScoreContextValue);

interface ScoreContextValue {
  formik: FormikProps<any>;
}

const ScoreParameterizations = (): ReactElement => {
  const { t } = useTranslation();
  const [scoreParameterization] = useState<CoreWorkflow | any>();
  const initialValues = useMemo(
    () => getInitialValues(scoreParameterization),
    [scoreParameterization],
  );

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit(values: any) {
      console.log(values);
    },
  });

  const { scoreParameterizations } = formik.values as any;

  const removeParameterizations = (item: any) => () => {
    formik.setFieldValue(
      'scoreParameterizations',
      scoreParameterizations?.filter((e: any) => e !== item),
    );
  };

  const onCancel = (id: any, index: number): void => {
    if (id > 0) {
      formik.setFieldValue('scoreParameterizations', [
        ...formik.initialValues.scoreParameterizations[index],
      ]);
    }
  };

  const duplicate = (index: number): void => {
    const newItem = formik.values.scoreParameterizations[index];
    formik.setFieldValue('scoreParameterizations', [
      ...formik.values.scoreParameterizations,
      {
        ...newItem,
        id: -Math.random(),
        name: `${formik.values.scoreParameterizations[index].name} (Cópia)`,
      },
    ]);
  };

  const onSubmitParameterization = (index: number): void => {
    const data = formik.values.scoreParameterizations[index];
    console.log(data);
  };

  return (
    <Modal
      title="Parametrização de score"
      onClose={() => console.log(false)}
      maxWidth="1000px"
    >
      <ScoreContext.Provider value={{ formik }}>
        <Container onSubmit={formik.handleSubmit}>
          <HeaderBox>
            <BoxLeft>
              <h2>{t('scoreParameterizations.titleParameterization')}</h2>
              <p>
                <span>{scoreParameterizations.length || 0}</span>
                {' '}
                {t('scoreParameterizations.textFoundParameterization')}
                {' '}
              </p>
            </BoxLeft>
            <BoxRight>
              <Button
                type="button"
                message="Nova parametrização"
                icon={<RiAddFill />}
                onClick={() => formik.setFieldValue('scoreParameterizations', [
                  ...formik.values.scoreParameterizations,
                  {
                    id: -Math.random(),
                    name: `Parametrização ${
                      scoreParameterizations.length + 1
                    }`,
                    operationEntryStatus: { id: 0, name: 'Pré-aprovado' },
                  },
                ])}
              />
            </BoxRight>
          </HeaderBox>

          {scoreParameterizations.length > 0 ? (
            <>
              {scoreParameterizations?.map(
                (parameterization: any, index: number) => (
                  <DynamicList
                    item={parameterization}
                    key={parameterization.id}
                    onRemove={removeParameterizations(parameterization)}
                    onSubmit={() => onSubmitParameterization(index)}
                    onCancel={() => onCancel(parameterization.id, index)}
                    duplicate={() => duplicate(index)}
                    type="scoreParameterization"
                    visibleValue={parameterization.name}
                    dynamicTags={dinymacTags}
                  >
                    <FormScoreParameterizations index={index} parameterization={parameterization} />
                  </DynamicList>
                ),
              )}
            </>
          ) : (
            <NoContent>
              <img src={HandshakeIcon} alt="Handshake Icon" />
              <h2>{t('scoreParameterizations.titleNoParameterization')}</h2>
              <p>{t('scoreParameterizations.textNoParameterization')}</p>
            </NoContent>
          )}
        </Container>
      </ScoreContext.Provider>
    </Modal>
  );
};

export default ScoreParameterizations;
