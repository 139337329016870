import styled, { css } from 'styled-components';

export const Container = styled.div<{
  checked?: boolean;
  isDragging?: boolean;
}>`
  ${({ theme, checked, isDragging }) => css`
    background-color: ${theme.colors.white00};
    border-radius: 6px;
    flex: 1;
    padding: 0 18px;
    height: 120px;
    min-width: 176px;
    max-width: 176px;
    border: 1px solid ${theme.colors.white00};
    position: relative;
    float: left;
    cursor: move;
    opacity: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transition: ${theme.transitions.default};

    z-index: 1000;
    transform: translate(0, 0);

    > img {
      max-height: 42px;
      margin: 0 0 12px 0;
      pointer-events: none;
    }

    > svg {
      font-size: 4.2rem;
      margin: 0 0 12px 0;
    }

    > h4 {
      color: ${theme.colors.black00};
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 2.2rem;
      ${theme.texts.buttonXS};
      text-align: center;
    }

    ${checked
    && css`
      opacity: 1;
      background-color: ${theme.colors.success05};
      border: 1px solid ${theme.colors.success00};

      > svg {
        &:first-child {
          color: ${theme.colors.success00};
          position: absolute;
          top: 13px;
          right: 13px;
          font-size: 1.4rem;
        }
      }
    `};

    ${isDragging && css`
      background-color: ${theme.colors.black10};
      border-color: ${theme.colors.black10};
      transition: ${theme.transitions.default};
      transform: translate(0, 0);
    `}
  `};
`;
