import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const Content = styled.div`
  padding: 46px 36px 46px 40px;
  width: 100%;
`;
