import styled from 'styled-components';

interface ContainerProps {
  toggled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  flex: 1;
  transition: 0.5s ease-out;
`;

export const Content = styled.div`
  min-height: 100vh;
  position: relative;
`;
