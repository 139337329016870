import React from 'react';
import MaskedInput from 'react-text-mask';
import ErrorMessage from '../ErrorMessage';
import { InputMaskProps } from './MaskedInput.spec';
import { Container } from './styles';

const InputMask = ({
  label = '', required, className, message, field, ...inputProps
}: InputMaskProps): any => (
  <Container className={className}>
    <label>
      {label}
      {' '}
      {required && (
      <small>
        (obrigatório)
      </small>
      )}

    </label>
    <MaskedInput inputMode="numeric" {...inputProps} {...field} />
    <ErrorMessage message={message} />
  </Container>
);

InputMask.defaultProps = {
  field: {},
};

export default InputMask;
