import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 14px 0;
`;

export const DynamicItem = styled.div`
  ${({ theme }) => css`
    padding: 14px 18px;
    border: 1px solid ${theme.colors.black20};
    border-radius: 4px;
  `}
`;

export const HeaderItem = styled.div`
  ${() => css`
    display: flex;
    align-items: center;

    > svg {
      font-size: 18px;
      margin-right: 18px;
    }
  `}
`;

export const ItemContent = styled.div`
  ${({ theme }) => css`
    > h2 {
      ${theme.texts.paragraphMD};
      color: ${theme.colors.black00};
    }

    > span {
      ${theme.texts.paragraphXXXS};
      color: ${theme.colors.black50};
    }
  `}
`;

// function gerarCor(opacidade = 0.1): string {
//   const r = Math.random() * 255;
//   const g = Math.random() * 255;
//   const b = Math.random() * 255;

//   return `rgba(${r}, ${g}, ${b}, ${opacidade})`;
// }

export const TagContent = styled.div`
  ${({ theme }) => css`
    margin-top: 10px;
    > span {
      ${theme.texts.paragraphXXXS};

      padding: 3px 6px;
      border-radius: 4px;
      color: ${theme.colors.black00};

      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  `}
`;

export const ActionsButton = styled.div`
  ${({ theme }) => css`
    margin-left: auto;
    display: flex;
    align-items: center;

    .cancel {
      background-color: transparent;
      color: ${theme.colors.black00};
      width: 100%;
      max-width: 56px;
      margin-right: 18px;

      ${theme.texts.buttonXS};
    }

    > p {
      ${theme.texts.paragraphXXXS};
      color: ${theme.colors.black00};
      margin-right: 18px;
    }

    > button {
      height: 30px;
      width: 30px;
      padding: 9px;

      border-radius: 4px;

      &.delete {
        background-color: ${theme.colors.error00};
        margin-left: 6px;
      }

      &.parameterization {
        margin-left: 6px;
      }

      > div {
        right: 9px;

        > svg {
          font-size: 1.2rem;
        }
      }
    }

    > svg {
      margin: 0 6px 0 12px;
      font-size: 18px;

      cursor: pointer;
    }
  `}
`;

export const BodyItem = styled.div`
  ${() => css`
    margin-top: 24px;
  `}
`;

export const TagItem = styled.span<{ bgColor?: string }>`
  ${({ bgColor }) => css`
    background-color: ${bgColor};
  `};
`;
