import React, { ReactElement, useState } from 'react';
import {
  RiSearchLine,
  // RiCheckboxCircleFill,
  RiArrowDownSFill,
  RiArrowUpSFill,
} from 'react-icons/ri';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { arrayMove } from '@dnd-kit/sortable';

import WorkflowIllustration from '../../../../../assets/WorkflowIllustration.svg';

import { Select, Button } from '../../../../../components';

import { workflowItems, initialWorkflowItems } from '../../../../../mock';

import WorkflowManagement from './WorkflowManagement';
import { AccordionItemBox } from './AccordionItemBox';

import { ToggledItemsProps } from '../../../../../@types';

import {
  Container,
  NestedContainer,
  SidebarStages,
  SearchInput,
  AccordionItems,
  AccordionItem,
  AccordionItemContent,
  AccordionItemHeader,
  WorkflowOnBoarding,
  Divider,
} from './styles';

const Workflow = (): ReactElement => {
  const [toggledItems, setToggledItems] = useState<ToggledItemsProps>({});
  const [viewMode, setViewMode] = useState<string>('default');
  const [dynamicItems, setDynamicItems] = useState(initialWorkflowItems) as any;

  return (
    <NestedContainer>
      <DndProvider backend={HTML5Backend}>
        <Container>
          <SidebarStages>
            <h3>Etapas disponíveis</h3>
            <SearchInput>
              <RiSearchLine />
              <input type="text" placeholder="Buscar etapa" />
            </SearchInput>
            <AccordionItems>
              {workflowItems.map((item) => (
                <AccordionItem key={item?.id}>
                  <AccordionItemHeader
                    onClick={() => {
                      setToggledItems({
                        ...toggledItems,
                        [item?.id]: !toggledItems[item?.id],
                      });
                    }}
                  >
                    <small>{item?.mainTitle}</small>
                    {!toggledItems[item?.id] ? (
                      <RiArrowUpSFill />
                    ) : (
                      <RiArrowDownSFill />
                    )}
                  </AccordionItemHeader>
                  <AccordionItemContent opened={!toggledItems[item?.id]}>
                    {item?.items.map((childrenItem) => {
                      console.log(childrenItem);
                      const findIndex = dynamicItems.findIndex(
                        (dItem: any) => dItem?.type === childrenItem?.type,
                      );

                      return (
                        <AccordionItemBox
                          key={childrenItem?.title}
                          childrenItem={childrenItem}
                          handleChange={() => {
                            console.log('');
                          }}
                          type={childrenItem?.type || ''}
                          checked={dynamicItems[findIndex]?.items.some(
                            (e: any) => e.id === childrenItem?.id,
                          )}
                          canDrag={
                            !dynamicItems[findIndex]?.items.some(
                              (e: any) => e.id === childrenItem?.id,
                            )
                          }
                        />
                      );
                    })}
                  </AccordionItemContent>
                </AccordionItem>
              ))}
            </AccordionItems>
          </SidebarStages>
        </Container>
        {viewMode !== 'default' ? (
          <WorkflowOnBoarding>
            <img src={WorkflowIllustration} alt="WorkflowIllustration" />
            <h2>Utilize um workflow existente ou crie um novo</h2>
            <Select label="Workflow" />
            <Divider>ou</Divider>
            <Button
              message="Criar um workflow"
              onClick={() => setViewMode('workflow')}
            />
          </WorkflowOnBoarding>
        ) : (
          <WorkflowManagement
            items={dynamicItems}
            handleOrderChange={(overID, activeID, id) => {
              setDynamicItems((newItems: any) => {
                const targetId = newItems.findIndex(
                  (item: any) => item.id === id,
                );
                const oldIndex = newItems[targetId]?.items?.findIndex(
                  (el: any) => el.id === activeID,
                );
                const newIndex = newItems[targetId]?.items?.findIndex(
                  (el: any) => el.id === overID,
                );
                const sortedItemsArray = arrayMove(
                  newItems[targetId]?.items,
                  oldIndex,
                  newIndex,
                );
                const oldItems = [...newItems];
                oldItems[targetId] = {
                  ...oldItems[targetId],
                  items: sortedItemsArray,
                };
                return oldItems;
              });
            }}
            handleViewMode={() => setViewMode('default')}
            handleChange={(a: any, b: any) => {
              setDynamicItems((prevState: any) => {
                const targetId = prevState.findIndex(
                  (item: any) => item.id === b,
                );
                const items = [...prevState];
                items[targetId] = {
                  ...items[targetId],
                  items: [...items[targetId].items, a],
                };
                return items;
              });
            }}
          />
        )}
      </DndProvider>
    </NestedContainer>
  );
};

export default Workflow;
