import React, { ReactElement, useContext } from 'react';

// import { useTranslation } from 'react-i18next';

import {
  Input, MaskedInput, Textarea, Select,
} from '../../../../components';

import { partnerCompanyList } from '../../../../mock/representatives';

import { cpfMask, phoneMask8, phoneMask9 } from '../../../../utils/masks';

import { RepresentativeContext } from '../RepresentativeInfo';

import { InfoMode } from '../../../../@types/InfoMode';

import {
  Container, InputRow, InputSolo, ContentForm,
} from './styles';
import InputMask from '../../../../components/MaskedInput';

const GeneralData = (): ReactElement => {
  // const { t } = useTranslation();

  const { formik, mode } = useContext(RepresentativeContext);

  const representative = formik?.values;

  const modeView = mode === InfoMode.VIEW;

  return (
    <Container>
      <ContentForm>
        <h2>Dados pessoais</h2>
        <InputRow>
          <Input
            name="name"
            label="Nome completo"
            placeholder="João da Silva Aguiar"
            value={representative.name}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          <InputMask
            name="cpf"
            label="CPF"
            placeholder="000.000.000-00"
            mask={cpfMask}
            guide={false}
            value={representative.cpf}
            onChange={({ target: { value } }) => formik.setFieldValue('cpf', value?.replace(/\D/g, ''))}
            disabled={modeView}
          />
        </InputRow>
      </ContentForm>

      <ContentForm>
        <h2>Empresa</h2>
        <InputRow>
          <Select
            name="partnerCompany"
            label="Conveniada"
            placeholder="Selecione"
            options={partnerCompanyList}
            value={representative?.partnerCompany}
            onChange={(selectedOption: any) => formik.setFieldValue('partnerCompany', selectedOption)}
            isDisabled={modeView}
          />
        </InputRow>
      </ContentForm>

      <ContentForm>
        <h2>Contato</h2>
        <InputRow>
          <Input
            name="email"
            label="E-mail"
            placeholder="email@meuemail.com.br"
            type="email"
            disabled={modeView}
            value={representative.email}
            onChange={formik.handleChange}
          />
          <MaskedInput
            name="phone"
            label="Telefone"
            placeholder="+55 51 9999 9999"
            mask={phoneMask8}
            guide={false}
            value={representative.phone}
            onChange={({ target: { value } }) => formik.setFieldValue('phone', value?.replace(/\D/g, ''))}
            disabled={modeView}
          />
          <MaskedInput
            name="cellPhone"
            label="Celular"
            placeholder="+55 51 9 9999 9999"
            mask={phoneMask9}
            guide={false}
            value={representative.cellPhone}
            onChange={({ target: { value } }) => formik.setFieldValue('cellPhone', value?.replace(/\D/g, ''))}
            disabled={modeView}
          />
        </InputRow>
      </ContentForm>

      <ContentForm>
        <h2>Informações adicionais</h2>
        <InputSolo>
          <Textarea
            name="observations"
            label="Observações"
            placeholder="Mais detalhes sobre o representante"
            rows={4}
            cols={50}
            value={representative.observations}
            onChange={formik.handleChange}
            disabled={modeView}
          />
        </InputSolo>
      </ContentForm>
    </Container>
  );
};

export default GeneralData;
