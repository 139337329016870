import styled, { css } from 'styled-components';

export const Container = styled.div<{ required?: boolean }>`
  ${({ theme, required }) => css`
    display: flex;
    align-items: center;

    border: 1px solid ${theme.colors.black20};
    border-radius: 6px;
    height: 72px;
    padding: 14px 24px 14px 18px;
    cursor: move;
    background-color: ${theme.colors.white00};
    -webkit-touch-callout: none;
    user-select: none;

    > img {
      margin: 0 18px 0 0;
      max-width: 16px;
      width: 100%;
      pointer-events: none;
      cursor: pointer;
    }

    &:not(:last-child) {
      margin: 0 0 14px 0;
    }

    ${required && css`
      background-color: ${theme.colors.black05};
      border-color: ${theme.colors.black05};
      cursor: not-allowed;

      > img {
        opacity: 0.2;
      }
    `};
  `};
`;

export const CustomizableStepItemTexts = styled.div`
  ${({ theme }) => css`
    margin: 0 auto 0 0;

    > h3 {
      color: ${theme.colors.black00};
      ${theme.texts.buttonSM};             
    }

    > span {
      color: ${theme.colors.black50};
      ${theme.texts.paragraphXXS};
    }
  `};
`;

export const CustomizableStepButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > button {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      border: 1px solid;
      background-color: transparent;

      transition: ${theme.transitions.default};

      > svg {
        font-size: 1.2rem;
      }
      
      &:first-child {
        border-color: ${theme.colors.black10};

        &:hover {
          border-color: ${theme.colors.black00};
        }
      }

      &:not(:first-child) {
        border-color: ${theme.colors.error00};
        background-color: ${theme.colors.error00};
        color: ${theme.colors.white00};
        margin: 0 0 0 6px;
      }
    }
  `};
`;

export const SwitchControl = styled.div`
  margin: 0 18px 0 auto;
`;
