import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > label {
      ${theme.texts.paragraphXXS};
      margin: 0 0 8px 0;

      > small {
        color: ${theme.colors.black50};
        font-style: italic;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
      }
    }

    .react-datepicker-wrapper {
      width: 100%;

      .react-datepicker__input-container {
        width: 100%;
        > input {
          border-radius: 4px;
          background-color: ${theme.colors.black05};
          border: 0;
          height: 42px;
          padding: 12px 16px;
          transition: ${theme.transitions.default};
          ${theme.texts.paragraphXS}
          color: ${theme.colors.black00};
          width: 100%;

          &::placeholder {
            color: ${theme.colors.black30};
          }

          &:disabled {
            color: ${theme.colors.black00};
          }
        }
      }
    }
    .react-datepicker__tab-loop {
      .react-datepicker-popper {
        > div {
          .react-datepicker {
            border-radius: 4px;
            box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%),
              0 4px 11px hsl(0deg 0% 0% / 10%);
            box-sizing: border-box;
            border-color: white;

            .react-calendar__navigation {
              .react-calendar__navigation__label {
                ${theme.texts.paragraphXS}
              }
            }

            .react-calendar__viewContainer {
              ${theme.texts.paragraphXS}

              .react-calendar__month-view__days {
                > button {
                  ${theme.texts.paragraphXS}
                  border-radius: 4px;
                }

                .react-calendar__tile--now {
                  background-color: ${theme.colors.black10};
                }

                .react-calendar__tile--active {
                  background-color: ${theme.colors.black00};
                  color: ${theme.colors.white};
                }
              }
            }
          }
        }
      }
    }
  `};
`;
