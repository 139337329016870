import { faker } from '@faker-js/faker';
import { RepresentativeProps, CoreRepresentative } from '../@types';

// Table fields header & content
export const representativeTableHeaderTitles = ['Nome completo', 'CPF/CNPJ', 'Telefone', 'E-mail'];
export const representativeTableContentKeys = ['name', 'cpf', 'phone', 'email'];

export const partnerCompanyList = [
  { name: 'Conveniada 1', id: 1 },
  { name: 'Conveniada 2', id: 1 },
];

export const representativesItems: RepresentativeProps[] = [];
export const getOne: CoreRepresentative = createRandomRepresentative();

export function createRandomRepresentativeItem(): RepresentativeProps {
  return {
    id: faker.datatype.uuid(),
    name: faker.name.fullName(),
    cpf: faker.phone.number('9999999999'),
    phone: faker.phone.number(),
    email: faker.internet.email(),
  };
}

Array.from({ length: 4 }).forEach(() => {
  representativesItems.push(createRandomRepresentativeItem());
});

export function createRandomRepresentative(): CoreRepresentative {
  return {
    id: faker.datatype.uuid(),
    name: faker.name.fullName(),
    cpf: faker.phone.number('9999999999'),
    partnerCompany: faker.helpers.arrayElement(partnerCompanyList),
    email: faker.internet.email(),
    phone: faker.phone.number(),
    cellPhone: faker.phone.number(),
    observations: faker.lorem.text(),
  };
}

export default representativesItems;
