import { AgentDTO } from '../../../../../@types';
import { DynamicTagsProps } from '../../../../../@types/DynamicTags';
import { format } from '../../../../../utils/masks';

export function getInitialValues(agent: AgentDTO, agentID: number): AgentDTO {
  return {
    agencyID: agent?.agencyID || undefined,
    agencyOfficeID: agent?.agencyOfficeID || undefined,
    personID: agent?.personID || undefined,
    person: {
      email: agent?.person?.email || undefined,
      fullName: agent?.person?.fullName || undefined,
      displayName: agent?.person?.displayName || undefined,
      documentNumber: agent?.person?.documentNumber || undefined,
      mobilePhoneNumber: agent?.person?.mobilePhoneNumber || undefined,
      landlinePhoneNumber: agent?.person?.landlinePhoneNumber || undefined,
      mothersFullName: agent?.person?.mothersFullName || undefined,
      fathersFullName: agent?.person?.fathersFullName || undefined,
      nationality: agent?.person?.nationality || undefined,
      birthplace: agent?.person?.birthplace || undefined,
      birthdate: agent?.person?.birthdate || undefined,
      civilStatusID: agent?.person?.civilStatusID || undefined,
      educationLevelID: agent?.person?.educationLevelID || undefined,
      hasAuth: agent?.person?.hasAuth || false,
    },
  };
}

export const dinymacTags: DynamicTagsProps[] = [
  {
    title: '',
    field: 'customerTypeID',
    bgColor: '#EDF7FC',
  },
  {
    title: 'Taxa:',
    field: 'fee',
    bgColor: '#EDFCF2',
    suffix: '%',
    labelFormatter: (value: any) => format(value),
  },
  {
    title: 'TAC:',
    field: 'tac',
    bgColor: '#EDFCF2',
    suffix: '%',
    labelFormatter: (value: any) => format(value),
  },
  {
    title: 'Parc. mín:',
    field: 'minInstallmentQuantity',
    bgColor: '#FCEDED',
  },
  {
    title: 'Parc. máx:',
    field: 'maxInstallmentQuantity',
    bgColor: '#FCEDED',
  },
  {
    title: 'Valor mín:',
    field: 'minValue',
    prefix: 'R$',
    bgColor: '#FCF9ED',
    labelFormatter: (value: any) => format(value),
  },
  {
    title: 'Valor máx:',
    field: 'maxValue',
    prefix: 'R$',
    bgColor: '#FCF9ED',
    labelFormatter: (value: any) => format(value),
  },
];
