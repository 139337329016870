import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RiSearchLine } from 'react-icons/ri';

import {
  Navbar,
  Button,
  Breadcrumb,
  Table,
} from '../../../components';

import { customerService } from '../../../services';

import {
  customerTableContentKeys,
  customerTableHeaderTitles,
  customersBreadCrumbItems,
} from '../../../mock';

import {
  Container, Header, SubHeader, SearchInput,
} from './styles';

import { CoreCustomer } from '../../../@types';

const Partners: React.FC = () => {
  const [customers, setCustomers] = useState<CoreCustomer[]>([]);

  useEffect(() => {
    setCustomers(customerService.get());
  }, []);

  function handleDeleteCustomer(id: number): void {
    customers.splice(id, 1);
  }

  return (
    <Container>
      <Header>
        <h1>
          <Breadcrumb breadCrumbItems={customersBreadCrumbItems} />
          Clientes
        </h1>
        <Navbar />
      </Header>

      <SubHeader>
        <SearchInput>
          <RiSearchLine />
          <input type="text" placeholder="Buscar cliente" />
        </SearchInput>
        <Link to="/registration/customers/create">
          <Button message="Novo cliente" />
        </Link>
      </SubHeader>

      <Table
        tableHeader={customerTableHeaderTitles}
        tableData={customers}
        tableKeys={customerTableContentKeys}
        handleDeleteItem={(id: number) => {
          handleDeleteCustomer(id);
        }}
        excludeModalTexts={{
          type: 'customer',
          visibleValue: 'name',
        }}
      />
    </Container>
  );
};

export default Partners;
