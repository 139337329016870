import { faker } from '@faker-js/faker';
import { ProductDTO } from '../@types';

// Table fields header & content
export const productTableHeaderTitles = ['Nome', 'Modalidade', 'Submodalidade'];
export const productTableContentKeys = ['name', 'typeID', 'subtypeID'];

export const listSelect = [{ id: '1', name: 'item01' }, { id: '2', name: 'item02' }];

export const productItems: ProductDTO[] = [];
export const productMe: ProductDTO = createRandomProduct();

export function createRandomProductItem(): ProductDTO {
  return {
    id: faker.datatype.number(),
    name: faker.commerce.productName(),
    typeID: faker.datatype.number(),
    subtypeID: faker.datatype.number(),
  };
}

Array.from({ length: 4 }).forEach(() => {
  productItems.push(createRandomProductItem());
});

export function createRandomProduct(): any {
  return {

    id: faker.datatype.uuid(),
    name: faker.name.fullName(),
    status: faker.datatype.boolean(),
    modality: faker.helpers.arrayElement(listSelect),
    submodality: faker.helpers.arrayElement(listSelect),
    description: faker.commerce.productDescription(),
    parameterizations: [{
      id: faker.datatype.uuid(),
      name: faker.name.fullName(),
      status: faker.datatype.boolean(),
      customerType: faker.helpers.arrayElement(listSelect),
      rate: faker.datatype.number(),
      tacType: faker.helpers.arrayElement(listSelect),
      tac: faker.datatype.number(),
      minimumInstallment: faker.datatype.number(),
      maximumInstallment: faker.datatype.number(),
      minimumValue: faker.datatype.number(),
      maximumValue: faker.datatype.number(),
      shortageDays: faker.datatype.number(),
      minimumAge: faker.datatype.number(),
      maximumAge: faker.datatype.number(),
      indemnity: faker.datatype.number(),
      return: faker.datatype.number(),
      returnType: faker.helpers.arrayElement(listSelect),
      description: faker.commerce.productDescription(),
    }],
  };
}

export default productItems;
