import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import InputControl from '../../../../../components/Input';
import InputMask from '../../../../../components/MaskedInput';
import InputDate from '../../../../../components/DateInput';
import {
  cpfMask, phoneMask8, phoneMask9,
} from '../../../../../utils/masks';

import {
  Container,
  InputRow,
  InputSolo,
  ContentForm,
} from './styles';
import Select from '../../../../../components/Select';
import { UserContext } from '../../UserInfo';
import { UFList } from '../../../../../@types/Address';
import { selectService } from '../../../../../services';
import { InfoMode } from '../../../../../@types/InfoMode';

const PrimaryData = (): ReactElement => {
  const { t } = useTranslation();

  const { formik, mode } = useContext(UserContext);

  const user = formik?.values;

  const [selectJurisdiction, setSelectJurisdiction] = useState();
  const [selectEducation, setSelectEducation] = useState();
  const [selectGender, setSelectGender] = useState();

  useEffect(() => {
    setSelectJurisdiction(selectService.getJurisdiction());
    setSelectEducation(selectService.getEducation());
    setSelectGender(selectService.getGender());
  }, []);

  const modeView = mode === InfoMode.VIEW;

  return (
    <Container>
      <ContentForm>
        <h2>{t('userPrimaryData.titleUser') || ''}</h2>
        <InputRow>
          <Select
            name="typeUser"
            label={t('userPrimaryData.labelInputTypeUser') || ''}
            options={selectGender}
            value={user.person}
            onChange={(selectedOption: any) => formik.setFieldValue('typeUser', selectedOption)}
            isDisabled={modeView}
          />
          <Select
            name="partner"
            label={t('userPrimaryData.labelInputPartner') || ''}
            options={selectGender}
            value={user.person}
            onChange={(selectedOption: any) => formik.setFieldValue('partner', selectedOption)}
            isDisabled={modeView}
          />
          <Select
            name="status"
            label={t('userPrimaryData.labelInputStatus') || ''}
            options={selectGender}
            value={user.person?.enabled}
            onChange={(selectedOption: any) => formik.setFieldValue('status', selectedOption)}
            isDisabled={modeView}
          />
        </InputRow>
      </ContentForm>
      <ContentForm>
        <h2>{t('userPrimaryData.titlePersonalData') || ''}</h2>
        <InputRow>
          <InputControl
            name="name"
            label={t('userPrimaryData.labelInputName') || ''}
            placeholder={t('userPrimaryData.placeholderInputName') || ''}
            value={user.person?.fullName}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          <InputMask
            name="cpf"
            label={t('userPrimaryData.labelInputCPF') || ''}
            placeholder="000.000.000-00"
            mask={cpfMask}
            guide={false}
            value={user.person?.documentNumber}
            onChange={({ target: { value } }) => formik.setFieldValue('cpf', value?.replace(/\D/g, ''))}
            disabled={modeView}
          />

          <Select
            name="civilStatus"
            label={t('userPrimaryData.labelInputCivilStatus') || ''}
            options={selectGender}
            value={user.person?.civilStatusID}
            onChange={(selectedOption: any) => formik.setFieldValue('civilStatus', selectedOption)}
            isDisabled={modeView}
          />

          <InputDate
            name="person.birthdate"
            label={t('userPrimaryData.labelInputBirthDate') || ''}
            selected={user.person?.birthdate || new Date()}
            onChange={(value: any) => { formik.setFieldValue('person.birthdate', value); }}
            disabled={modeView}
          />
        </InputRow>
        <InputRow>
          <InputControl
            name="nationality"
            label={t('userPrimaryData.labelInputNationality') || ''}
            placeholder={
                t('userPrimaryData.placeholderInputNationality') || ''
              }
            value={user.person?.nationality}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          <Select
            name="gender"
            label={t('userPrimaryData.labelInputGender') || ''}
            options={selectGender}
            value={user.person?.birthplace}
            onChange={(selectedOption: any) => formik.setFieldValue('gender', selectedOption)}
            isDisabled={modeView}
          />

          <InputControl
            name="rg"
            label={t('userPrimaryData.labelInputRG') || ''}
            placeholder={t('userPrimaryData.placeholderInputRG') || ''}
            type="number"
            value={user.person?.documentNumber}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          {/* <InputControl
            name="issuingAgency"
            label={t('userPrimaryData.labelInputIssuingAgency') || ''}
            placeholder={
                t('userPrimaryData.placeholderInputIssuingAgency') || ''
              }
            value={user.person}
            onChange={formik.handleChange}
            disabled={modeView}
          /> */}
        </InputRow>
        <InputRow>
          <Select
            name="education"
            label={t('userPrimaryData.labelInputEducation') || ''}
            options={selectEducation}
            value={user.person?.educationLevelID}
            onChange={(selectedOption: any) => formik.setFieldValue('education', selectedOption)}
            isDisabled={modeView}
          />

          {/* <InputDate
            name="shippingDate"
            label={t('userPrimaryData.labelInputShippingDate') || ''}
            onChange={(value: any) => { formik.setFieldValue('shippingDate', value); }}
            value={user.person}
            disabled={modeView}
          /> */}

          <InputControl
            name="naturalness"
            label={t('userPrimaryData.labelInputNaturalness') || ''}
            placeholder={
                t('userPrimaryData.placeholderInputNaturalness') || ''
              }
            value={user.person?.birthplace}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          <Select
            name="ufNaturalness"
            label={t('userPrimaryData.labelInputUFNaturalness') || ''}
            placeholder={
                t('userPrimaryData.placeholderInputUFNaturalness') || ''
              }
            options={UFList}
            value={user.person}
            onChange={(selectedOption: any) => formik.setFieldValue('ufNaturalness', selectedOption)}
            isDisabled={modeView}
          />
        </InputRow>
      </ContentForm>

      <ContentForm>
        <h2>{t('userPrimaryData.titleJurisdiction')}</h2>

        <InputSolo>
          <Select
            name="jurisdiction"
            label={
                t('userPrimaryData.labelInputJurisdiction')
                || 'Alçada do usuário'
              }
            options={selectJurisdiction}
            value={user.person}
            onChange={(selectedOption: any) => formik.setFieldValue('jurisdiction', selectedOption)}
            isDisabled={modeView}
          />
        </InputSolo>
      </ContentForm>

      <ContentForm>
        <h2>{t('userPrimaryData.titleAddress')}</h2>
        {/* <InputRow>
           <InputMask
            name="address.postalCode"
            label={t('userPrimaryData.labelInputCEP') || ''}
            placeholder="00000-000"
            mask={cepMask}
            guide={false}
            value={user.person}
            onChange={({ target: { value } }) => formik.setFieldValue('address.postalCode', value?.replace(/\D/g, ''))}
            disabled={modeView}
          />

          <InputControl
            name="address.street"
            label={t('userPrimaryData.labelInputAddress') || ''}
            placeholder={t('userPrimaryData.placeholderInputAddress') || ''}
            value={user.address.street}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <InputControl
            name="address.number"
            label={t('userPrimaryData.labelInputNumber') || ''}
            placeholder={t('userPrimaryData.placeholderInputNumber') || ''}
            value={user.address.number}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <InputControl
            name="address.complement"
            label={t('userPrimaryData.labelInputComplement') || ''}
            placeholder={
                t('userPrimaryData.placeholderInputComplement') || ''
              }
            value={user.address.complement}
            onChange={formik.handleChange}
            disabled={modeView}
          />
        </InputRow> */}

        {/* <InputRow>
        <InputControl
            name="address.neighborhood"
            label={t('userPrimaryData.labelInputNeighborhood') || ''}
            placeholder={
                t('userPrimaryData.placeholderInputNeighborhood') || ''
              }
            value={user.address.neighborhood}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <InputControl
            name="address.city"
            label={t('userPrimaryData.labelInputCity') || ''}
            placeholder={t('userPrimaryData.placeholderInputCity') || ''}
            value={user.address.city}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <Select
            name="address.state"
            label={t('userPrimaryData.labelInputUF') || ''}
            placeholder={t('userPrimaryData.placeholderInputUF') || ''}
            options={UFList}
            value={user.address.state}
            onChange={(selectedOption: any) => formik.setFieldValue('address.state', selectedOption)}
            isDisabled={modeView}
          />
        </InputRow> */}
      </ContentForm>

      <ContentForm>
        <h2>{t('userPrimaryData.titleContacts')}</h2>
        <InputRow>
          <InputMask
            name="telephone"
            label={t('userPrimaryData.labelInputTelephone') || ''}
            placeholder="+55 51 9999 9999"
            mask={phoneMask8}
            guide={false}
            value={user.person?.landlinePhoneNumber}
            onChange={({ target: { value } }) => formik.setFieldValue('telephone', value?.replace(/\D/g, ''))}
            disabled={modeView}
          />
          <InputMask
            name="phone"
            label={t('userPrimaryData.labelInputPhone') || ''}
            placeholder="+55 51 9 9999 9999"
            mask={phoneMask9}
            guide={false}
            value={user.person?.mobilePhoneNumber}
            onChange={({ target: { value } }) => formik.setFieldValue('phone', value?.replace(/\D/g, ''))}
            disabled={modeView}
          />

          <InputControl
            name="email"
            label={t('userPrimaryData.labelInputEmail') || ''}
            placeholder={t('userPrimaryData.placeholderInputEmail') || ''}
            type="email"
            value={user.person?.email}
            onChange={formik.handleChange}
            disabled={modeView}
          />
        </InputRow>
      </ContentForm>
      <ContentForm>
        <h2>{t('userPrimaryData.titleFamily')}</h2>
        <InputRow>
          <InputControl
            name="motherName"
            label={t('userPrimaryData.labelInputMotherName') || ''}
            placeholder={
                t('userPrimaryData.placeholderInputMotherName') || ''
              }
            value={user.person?.mothersFullName}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <InputControl
            name="fatherName"
            label={t('userPrimaryData.labelInputFatherName') || ''}
            placeholder={
                t('userPrimaryData.placeholderInputFatherName') || ''
              }
            value={user.person?.fathersFullName}
            onChange={formik.handleChange}
            disabled={modeView}
          />
        </InputRow>
      </ContentForm>

    </Container>
  );
};

export default PrimaryData;
