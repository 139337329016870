import { useFormik } from 'formik';
import React, {
  ReactElement, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { RiAddFill, RiCheckboxCircleFill, RiCloseCircleFill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InfoMode } from '../../../../../@types/InfoMode';
import {
  ProductVariantDTO,
  ProductVariantSDTO,
} from '../../../../../@types/ProductVariant';
import { AppContext } from '../../../../../App';
import HandshakeIcon from '../../../../../assets/HandshakeIcon.svg';
import { Button, DynamicList } from '../../../../../components';
import TooltipComponent from '../../../../../components/Tooltip';
import { productVariantService } from '../../../../../services';
import { ProductContext } from '../../ProductInfo';
import FormProductVariant from './FormProductVariant';
import { dinymacTags, getInitialValues } from './ProductVariant.spec';
import {
  BoxLeft, BoxRight, Container, HeaderBox, NoContent,
} from './styles';

const ProductVariant = (): ReactElement => {
  const { t } = useTranslation();
  const { id: productID } = useParams();
  const { setLoading } = useContext(AppContext);
  const { mode } = useContext(ProductContext) as any;
  const [productVariantList, setProductVariantList] = useState<ProductVariantSDTO[]>([]);
  const [productVariant, setProductVariant] = useState<ProductVariantDTO>({});

  const initialValues = useMemo(
    () => getInitialValues(productVariant, productID ? +productID : 0),
    [productVariant, productID],
  );

  const fetchProductVariant = useCallback(async () => {
    setLoading(true);
    try {
      if (productID && +productID) {
        const { content } = await productVariantService.getPageByProductID(+productID);
        setProductVariantList(content);
      }
    } catch (err: any) {
      console.log(err);
      toast.error('Não foi possível carregar os dados', {
        className: 'toast-message',
        icon: RiCloseCircleFill,
        containerId: 'close-on',
      });
    } finally {
      setLoading(false);
    }
  }, [productID, setLoading]);

  useEffect((): void => {
    if (mode !== InfoMode.CREATE) {
      fetchProductVariant();
    }
  }, [mode, fetchProductVariant]);

  const formik = useFormik<ProductVariantDTO>({
    enableReinitialize: true,
    initialValues,
    onSubmit(values) {
      console.log(values);
    },
  });

  async function removeProductVariants(
    item: ProductVariantSDTO,
  ): Promise<void> {
    setLoading(true);
    try {
      await productVariantService.delete(item?.id);
      fetchProductVariant();
      toast.success('Parametrização removida com sucesso!', {
        className: 'toast-message',
        icon: RiCheckboxCircleFill,
        containerId: 'close-on',
      });
    } catch (error) {
      console.log(error);
      toast.error('Não foi possível remover a parametrização!', {
        className: 'toast-message',
        icon: RiCloseCircleFill,
        containerId: 'close-on',
      });
    } finally {
      setLoading(false);
    }
  }

  const onCancel = (variantID: any): void => {
    if (variantID > 0) {
      formik.resetForm();
    }
  };

  async function duplicate(name: string): Promise<void> {
    const data = formik.values;
    setLoading(true);
    try {
      await productVariantService.create({
        ...data,
        name: `${name} (Cópia)`,
      });
      toast.success('Parametrização duplicada com sucesso!', {
        className: 'toast-message',
        icon: RiCheckboxCircleFill,
        containerId: 'close-on',
      });
      fetchProductVariant();
    } catch (error) {
      console.log(error);
      toast.error('Não foi possível duplicar a parametrização!', {
        className: 'toast-message',
        icon: RiCloseCircleFill,
        containerId: 'close-on',
      });
    } finally {
      setLoading(false);
    }
  }

  async function edit(variantID: number): Promise<void> {
    const data = await productVariantService.getOne(variantID);
    setProductVariant(data);
  }

  async function onSubmitProductVariant(variantID?: number): Promise<void> {
    const data = formik.values;
    const newItem = formik.initialValues;
    try {
      setLoading(true);
      if (variantID) {
        await productVariantService.update(variantID, {
          ...data,
        });
        toast.success('Parametrização editada com sucesso!', {
          className: 'toast-message',
          icon: RiCheckboxCircleFill,
          containerId: 'close-on',
        });
      } else {
        await productVariantService.create({
          ...newItem,
          name: `Parametrização ${productVariantList.length + 1}`,
        });
        toast.success('Parametrização criada com sucesso!', {
          className: 'toast-message',
          icon: RiCheckboxCircleFill,
          containerId: 'close-on',
        });
      }
      fetchProductVariant();
    } catch (error) {
      console.log(error);
      toast.error('Erro!', {
        className: 'toast-message',
        icon: RiCloseCircleFill,
        containerId: 'close-on',
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <HeaderBox>
        <BoxLeft>
          <h2>{t('productVariant.titleVariant')}</h2>
          <p>
            <span>{productVariantList?.length}</span>
            {' '}
            {t('productVariant.textFoundVariant')}
            {' '}
          </p>
        </BoxLeft>
        <BoxRight>
          {mode === InfoMode.VIEW ? (
            <TooltipComponent
              text="Você está no modo de visualização, para editar os dados desse produto, clique em “Editar”."
              tooltip={(
                <Button
                  type="button"
                  message="Nova parametrização"
                  icon={<RiAddFill />}
                  onClick={() => onSubmitProductVariant()}
                  disabled={mode === InfoMode.VIEW}
                />
)}
            />
          ) : (
            <Button
              type="button"
              message="Nova parametrização"
              icon={<RiAddFill />}
              onClick={() => onSubmitProductVariant()}
            />
          )}

        </BoxRight>
      </HeaderBox>

      {productVariantList?.length > 0 ? (
        <>
          {productVariantList?.map((variant: any, index: number) => (
            <DynamicList
              item={variant}
              key={variant.id}
              onRemove={() => removeProductVariants(variant)}
              onSubmit={() => onSubmitProductVariant(variant.id)}
              onCancel={() => onCancel(variant.id)}
              duplicate={() => duplicate(variant.name)}
              edit={() => edit(variant.id)}
              mode={mode}
              type="variant"
              visibleValue={variant.name}
              dynamicTags={dinymacTags}
            >
              <FormProductVariant
                index={index}
                mode={mode}
                formik={formik}
              />
            </DynamicList>
          ))}
        </>
      ) : (
        <NoContent>
          <img src={HandshakeIcon} alt="Handshake Icon" />
          <h2>{t('productVariant.titleNoVariant')}</h2>
          <p>{t('productVariant.textNoVariant')}</p>
        </NoContent>
      )}
    </Container>
  );
};

export default ProductVariant;
