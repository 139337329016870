import React, {
  ReactElement, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CivilStatus } from '../../../../../../@types/CivilStatus';
import { EducationLevel } from '../../../../../../@types/EducationLevel';
import { InfoMode } from '../../../../../../@types/InfoMode';
import {
  Select,
  MaskedInput,
  Input,
  DateInput,
  // Textarea,
} from '../../../../../../components';
import { civilStatusService, educationLevelService } from '../../../../../../services';
import {
  // cepMask,
  cpfMask,
  phoneMask8,
  phoneMask9,
} from '../../../../../../utils/masks';
import { FormAgentProps } from './FormAgent.spec';

import {
  Container, InputRow, ContentForm, InputSolo,
} from './styles';

const FormBusinessPartner = ({
  formik,
  mode,
}: FormAgentProps): ReactElement => {
  const { t } = useTranslation();

  const modeView = mode === InfoMode.VIEW;

  const [selectEducationLevel, setSelectEducationLevel] = useState<EducationLevel[]>();
  const [selectCivilStatus, setSelectCivilStatus] = useState<CivilStatus[]>();

  useEffect(() => {
    fetchEducationLevel();
    fetchCivilStatus();
  }, []);

  async function fetchEducationLevel(): Promise<void> {
    try {
      const content = await educationLevelService.getList();
      setSelectEducationLevel(content);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchCivilStatus(): Promise<void> {
    try {
      const content = await civilStatusService.getList();
      setSelectCivilStatus(content);
    } catch (error) {
      console.log(error);
    }
  }

  function fieldName(name: string): string {
    return `person.${name}`;
  }
  return (
    <Container>
      <ContentForm>
        <h2>{t('partnerAgent.titleTypeBond') || ''}</h2>
        <InputRow>
          {/* <Select
            name={fieldName('responsability')}
            label={t('partnerAgent.labelInputResponsibility') || ''}
            options={options}
            value={formik.values.responsability}
            onChange={(selectedOption: any) => formik.setFieldValue(fieldName('responsability'), selectedOption)}
            isDisabled={modeView}
          /> */}
        </InputRow>
      </ContentForm>
      <ContentForm>
        <h2>{t('partnerAgent.titlePersonalData') || ''}</h2>
        <InputRow>
          <Input
            name={fieldName('fullName')}
            label={t('partnerAgent.labelInputName') || ''}
            placeholder={t('partnerAgent.placeholderInputName') || ''}
            value={formik.values.person?.fullName}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          <MaskedInput
            name={fieldName('cpf')}
            label={t('partnerAgent.labelInputCPF') || ''}
            placeholder="000.000.000-00"
            mask={cpfMask}
            guide={false}
            value={formik.values.person?.documentNumber}
            onChange={({ target: { value } }) => formik.setFieldValue(fieldName('cpf'), value?.replace(/\D/g, ''))}
            disabled={modeView}
          />

          <Select
            name={fieldName('civilStatus')}
            label={t('partnerAgent.labelInputCivilStatus') || ''}
            options={selectCivilStatus}
            value={
              formik.values.person?.civilStatusID
                ? selectCivilStatus?.find(
                  (e) => e.id === formik.values.person?.civilStatusID,
                )
                : null
            }
            onChange={(selectedOption: any) => formik.setFieldValue(fieldName('civilStatus'), selectedOption.id)}
            isDisabled={modeView}
          />

          <DateInput
            name={fieldName('birthDate')}
            label={t('partnerAgent.labelInputBirthDate') || ''}
            selected={formik.values.person?.birthdate}
            onChange={(value: any) => {
              formik.setFieldValue(fieldName('birthDate'), value);
            }}
            disabled={modeView}
          />
        </InputRow>
        <InputRow>
          <Input
            name={fieldName('nationality')}
            label={t('partnerAgent.labelInputNationality') || ''}
            placeholder={t('partnerAgent.placeholderInputNationality') || ''}
            value={formik.values.person?.nationality}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          {/* <Select
            name={fieldName('gender')}
            label={t('partnerAgent.labelInputGender') || ''}
            options={options}
            value={formik.values.person?.gender}
            onChange={(selectedOption: any) => formik.setFieldValue(fieldName('gender'), selectedOption)}
            isDisabled={modeView}
          /> */}

          {/* <Input
            name={fieldName('documentNumberAlt')}
            label={t('partnerAgent.labelInputRG') || ''}
            placeholder={t('partnerAgent.placeholderInputRG') || ''}
            type="number"
            value={formik.values.person?.documentNumberAlt}
            onChange={formik.handleChange}
            disabled={modeView}
          /> */}

          {/* <Input
            name={fieldName('issuingAgency')}
            label={t('partnerAgent.labelInputIssuingAgency') || ''}
            placeholder={
              t('partnerAgent.placeholderInputIssuingAgency') || ''
            }
            value={item.issuingAgency}
            onChange={formik.handleChange}
            disabled={modeView}
          /> */}
        </InputRow>
        <InputRow>
          <Select
            name={fieldName('educationLevelID')}
            label={t('partnerAgent.labelInputEducation') || ''}
            options={selectEducationLevel}
            value={
              formik.values.person?.educationLevelID
                ? selectEducationLevel?.find(
                  (e) => e.id === formik.values.person?.educationLevelID,
                )
                : null
            }
            onChange={(selectedOption: any) => formik.setFieldValue(
              fieldName('educationLevelID'),
              selectedOption.id,
            )}
            isDisabled={modeView}
          />

          {/* <DateInput
            name={fieldName('birthplace')}
            label={t('partnerAgent.labelInputShippingDate') || ''}
            onChange={(value: any) => {
              formik.setFieldValue(fieldName('birthplace'), value);
            }}
            value={formik.values.person?.birthplace}
            disabled={modeView}
          /> */}

          <Input
            name={fieldName('birthplace')}
            label={t('partnerAgent.labelInputNaturalness') || ''}
            placeholder={t('partnerAgent.placeholderInputNaturalness') || ''}
            value={formik.values.person?.birthplace}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          {/* <Select
            name={fieldName('ufNaturalness')}
            label={t('partnerAgent.labelInputUFNaturalness') || ''}
            placeholder={
              t('partnerAgent.placeholderInputUFNaturalness') || ''
            }
            options={UFList}
            value={formik.values.person?.ufNaturalness}
            onChange={(selectedOption: any) => formik.setFieldValue(fieldName('ufNaturalness'), selectedOption)}
            isDisabled={modeView}
          /> */}
        </InputRow>
      </ContentForm>

      <ContentForm>
        <h2>{t('partnerAgent.titleAddress')}</h2>
        <InputRow>
          {/* <MaskedInput
            name={fieldName('address.postalCode')}
            label={t('partnerAgent.labelInputCEP') || ''}
            placeholder="00000-000"
            mask={cepMask}
            guide={false}
            value={item.address?.postalCode}
            onChange={({ target: { value } }) => formik.setFieldValue(
              fieldName('address.postalCode'),
              value?.replace(/\D/g, ''),
            )}
            disabled={modeView}
          />

          <Input
            name={fieldName('address.street')}
            label={t('partnerAgent.labelInputAddress') || ''}
            placeholder={
              t('partnerAgent.placeholderInputAddress') || ''
            }
            value={item.address?.street}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <Input
            name={fieldName('address.number')}
            label={t('partnerAgent.labelInputNumber') || ''}
            placeholder={
              t('partnerAgent.placeholderInputNumber') || ''
            }
            value={item.address?.number}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          <Input
            name={fieldName('address.complement')}
            label={t('partnerAgent.labelInputComplement') || ''}
            placeholder={
              t('partnerAgent.placeholderInputComplement') || ''
            }
            value={item.address?.complement}
            onChange={formik.handleChange}
            disabled={modeView}
          /> */}
        </InputRow>

        <InputRow>
          {/* <Input
            name={fieldName('address.neighborhood')}
            label={t('partnerAgent.labelInputNeighborhood') || ''}
            placeholder={
              t('partnerAgent.placeholderInputNeighborhood') || ''
            }
            value={item.address?.neighborhood}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <Input
            name={fieldName('address.city')}
            label={t('partnerAgent.labelInputCity') || ''}
            placeholder={t('partnerAgent.placeholderInputCity') || ''}
            value={item?.address?.city}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <Select
            name={fieldName('address.state')}
            label={t('partnerAgent.labelInputUF') || ''}
            placeholder={t('partnerAgent.placeholderInputUF') || ''}
            options={UFList}
            value={item?.address?.state}
            onChange={(selectedOption: any) => formik.setFieldValue(fieldName('address.state'), selectedOption)}
            isDisabled={modeView}
          /> */}
        </InputRow>
      </ContentForm>

      <ContentForm>
        <h2>{t('partnerAgent.titleContacts')}</h2>
        <InputRow>
          <MaskedInput
            name={fieldName('landlinePhoneNumber')}
            label={t('partnerAgent.labelInputTelephone') || ''}
            placeholder="+55 51 9999 9999"
            mask={phoneMask8}
            guide={false}
            value={formik.values.person?.landlinePhoneNumber}
            onChange={({ target: { value } }) => formik.setFieldValue(
              fieldName('landlinePhoneNumber'),
              value?.replace(/\D/g, ''),
            )}
            disabled={modeView}
          />
          <MaskedInput
            name={fieldName('mobilePhoneNumber')}
            label={t('partnerAgent.labelInputPhone') || ''}
            placeholder="+55 51 9 9999 9999"
            mask={phoneMask9}
            guide={false}
            value={formik.values.person?.mobilePhoneNumber}
            onChange={({ target: { value } }) => formik.setFieldValue(
              fieldName('mobilePhoneNumber'),
              value?.replace(/\D/g, ''),
            )}
            disabled={modeView}
          />

          <Input
            name={fieldName('email')}
            label={t('partnerAgent.labelInputEmail') || ''}
            placeholder={t('partnerAgent.placeholderInputEmail') || ''}
            type="email"
            value={formik.values.person?.email}
            onChange={formik.handleChange}
            disabled={modeView}
          />
        </InputRow>
      </ContentForm>
      <ContentForm>
        <h2>{t('partnerAgent.titleAdditionalInformation')}</h2>
        <InputSolo>
          {/* <Textarea
            name={fieldName('description')}
            label={t('partnerAgent.labelInputObservation') || ''}
            placeholder={
              t('partnerAgent.placeholderInputObservation') || ''
            }
            value={formik.values.person?.description}
            onChange={formik.handleChange}
          /> */}
        </InputSolo>
      </ContentForm>
    </Container>
  );
};

export default FormBusinessPartner;
