import React from 'react';
import { TabElementProps } from '../@types/TabNavigation';
import PrimaryData from '../pages/Registration/Customers/Breadcrumb/PrimaryData';
import Documents from '../pages/Registration/Customers/Breadcrumb/Documents';
import Guarantors from '../pages/Registration/Customers/Breadcrumb/Guarantors';

export const tabNavigationItemsCustomers = [
  { title: 'Dados primários', element: <PrimaryData /> },
  { title: 'Documentos', element: <Documents /> },
  { title: 'Avalista(s)', element: <Guarantors /> },
] as TabElementProps;
