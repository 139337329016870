import React, { useState } from 'react';
import { RiEyeCloseLine, RiEyeLine, RiArrowRightUpLine } from 'react-icons/ri';

import { useTranslation } from 'react-i18next';
// import { useAuth } from '../../contexts/AuthContext';

import Button from '../../components/Button';

import Logo from '../../assets/LogoWLabel.svg';

import {
  Container,
  SignInForm,
  FormHeader,
  Form,
  FormTexts,
  InputControl,
  Brand,
} from './styles';

const NewPassword: React.FC = () => {
  // const { Login } = useAuth();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  async function handleLogin(): Promise<void> {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }

  return (
    <Container>
      <SignInForm>
        <FormHeader>
          <Brand>
            <img src={Logo} alt="Logo" />
          </Brand>
          <a href="/">
            {t('newPassword.back')}
            <RiArrowRightUpLine />
          </a>
        </FormHeader>
        <FormTexts>
          <h1>{t('newPassword.title')}</h1>
          <p>{t('newPassword.subTitle')}</p>
        </FormTexts>

        <Form>
          <InputControl>
            <small>{t('newPassword.label')}</small>
            <input type={showPassword ? 'text' : 'password'} placeholder="*************" />
            <button type="button" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <RiEyeLine /> : <RiEyeCloseLine />}
            </button>
          </InputControl>
          <InputControl>
            <small>{t('newPassword.repeatLabel')}</small>
            <input type={showPassword ? 'text' : 'password'} placeholder="*************" />
            <button type="button" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <RiEyeLine /> : <RiEyeCloseLine />}
            </button>
          </InputControl>

          <Button
            message={t('newPassword.button')}
            type="button"
            onClick={() => {
              setIsLoading(true);
              handleLogin();
            }}
            isLoading={isLoading}
            loadingMessage={t('buttonLoadingMessage')}
          />
        </Form>
      </SignInForm>
    </Container>
  );
};

export default NewPassword;
