import React, { ReactElement } from 'react';

import {
  Container,
} from './styles';

import { InputProps } from './input.spec';
import ErrorMessage from '../ErrorMessage';

const InputControl = ({
  label = '', type = 'text', placeholder = '', className, message, field, required, ...inputProps
}: InputProps): ReactElement => (
  <Container className={className}>
    <label>
      {label}
      {' '}
      {required && (
        <small>
          (obrigatório)
        </small>
      )}

    </label>
    <input type={type} placeholder={placeholder} {...field} {...inputProps} />
    <ErrorMessage message={message} />
  </Container>
);

export default InputControl;
