import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowDownSFill } from 'react-icons/ri';
import Select, { StylesConfig, components, DropdownIndicatorProps } from 'react-select';
import ErrorMessage from '../ErrorMessage';
import { Props } from './Select.spec';
import { Container } from './styles';

const SelectField = ({
  field, label = '', className, message, required, ...props
}: Props): any => {
  const { t } = useTranslation();

  const colourStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      border: 'none',
      height: '42px',
      fontSize: '1.2rem',
      fontWeight: '500',
      boxShadow: 'none',
      ':active': {
        ...styles[':active'],
        border: 'none',
      },
      ':hover': {
        ...styles[':hover'],
        border: 'none',
      },
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: '8px 15px 8px 8px',
      svg: {
        fontSize: '1.4rem',

      },
    }),
    option: (styles, {
      isDisabled,
    }) => ({
      ...styles,
      cursor: isDisabled ? 'not-allowed' : 'default',
    }),
  };

  const DropdownIndicator = (propsDrop: DropdownIndicatorProps): any => (
    <components.DropdownIndicator {...propsDrop}>
      <RiArrowDownSFill />
    </components.DropdownIndicator>
  );

  return (
    <Container className={className}>
      {label && (
        <label>
          {label}
          {' '}
          {required && (
          <small>
            (obrigatório)
          </small>
          )}

        </label>
      )}

      <Select
        {...field}
        {...props}
        isSearchable={false}
        placeholder={t('selectComponent.placeholder') || 'Selecione'}
        components={{ DropdownIndicator }}
        styles={colourStyles}
        noOptionsMessage={() => t('selectComponent.noOptionsMessage') || 'Não foi há opções'}
        getOptionLabel={(option: any) => `${option.text}`}
        getOptionValue={(option: any) => option.id}
        classNamePrefix="react-select"
      />
      <ErrorMessage message={message} />
    </Container>
  );
};

export default SelectField;
