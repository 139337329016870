import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

`;

export const TabNavigationRow = styled.div`
`;

export const TabNavigationHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: 24px;

   position: relative;

   > p {
    position: absolute;
    bottom: 0;
    z-index: 1;

    width: 100%;

    border-bottom: 2px solid ${theme.colors.black10};
   }
  `};
`;

export const TabNavigationHeaderItem = styled.div`
  ${({ theme }) => css`
    ${theme.texts.headingXXS};
    color: ${theme.colors.black50};
    padding-bottom: 16px;
    display: flex;
    align-items: center;

    cursor: pointer;

    :not(:last-child) {
      margin-right: 24px;
    }

    &.active {
      z-index: 2;
      color: ${theme.colors.black00};
      border-bottom: 2px solid ${theme.colors.black00};
    }

    > svg {
      font-size: 1.4rem;
      margin-left: 8px;
    }
  `};
`;

export const TabNavigationContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
