import { FormikProps } from 'formik';
import { InfoMode } from '../../../../@types/InfoMode';
import { ProductDTO } from '../../../../@types';

export interface ProductInfoProps {
  mode: InfoMode;
}

export interface ProductContextValue {
  formik: FormikProps<ProductDTO>;
  mode: InfoMode;
}

export function getInitialValues(
  product: ProductDTO,
): ProductDTO {
  return {
    enabled: product?.enabled || false,
    name: product?.name || undefined,
    typeID: product?.typeID || undefined,
    subtypeID: product?.subtypeID || undefined,
    description: product?.description || undefined,
  };
}
