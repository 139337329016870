import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { ForgotPassword, SignIn, NewPassword } from '../pages';

const OtherRoutes: React.FC = () => (
  <Routes>
    <Route path="" element={<SignIn />} />
    <Route path="forgot-password" element={<ForgotPassword />} />
    <Route path="new-password" element={<NewPassword />} />
    <Route path="*" element={null} />
  </Routes>
);

export default OtherRoutes;
