import { faker } from '@faker-js/faker';
import { AgentDTO, OperationSDTO } from '../@types';

// Table fields header & content
export const operationTableHeaderTitles = ['Produto', 'Operação', 'Cliente', 'Valor', 'Status', 'Data', 'Agente'];
export const operationTableContentKeys = ['productName', 'operationID', 'customer', 'value', 'status', 'date', 'agencyOffice'];

const userTypes = ['Pago', 'Bancarização'];

export const operationItems: OperationSDTO[] = [];

export function createRandomOperationItem(): any {
  return {
    productName: faker.commerce.productName(),
    operationID: `#${faker.datatype.number()}`,
    customer: faker.name.fullName(),
    value: `R$ ${faker.commerce.price()}`,
    status: faker.helpers.arrayElement(userTypes),
    date: faker.helpers.fake('03/03/2022 às 14:03'),
    agencyOffice: faker.helpers.fake('AG'),
  };
}

Array.from({ length: 4 }).forEach(() => {
  operationItems.push(createRandomOperationItem());
});

export default operationItems;
