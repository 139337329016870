import React from 'react';
import {
  RiCarFill,
  RiMoneyDollarBoxFill,
  RiUserSearchFill,
  RiMedalFill,
  RiListSettingsLine,
  RiHome3Fill,
  RiMapPin2Fill,
} from 'react-icons/ri';

import BoaVista from '../assets/BoaVista.svg';
import Quod from '../assets/Quod.svg';
import SCR from '../assets/SCR.svg';
import Serasa from '../assets/Serasa.svg';

import { ItemTypes } from '../pages/Registration/Products/Breadcrumb/Workflow/ItemTypes';

import { WorkflowItemProps } from '../@types';

export const workflowItems = [
  {
    id: 1,
    mainTitle: 'Análise de informações do cliente',
    items: [
      {
        id: 1,
        title: 'Análise SCR',
        icon: <img src={SCR} alt="SCR" />,
        type: ItemTypes.BEAUREAUS,
      },
      {
        id: 2,
        title: 'Análise Quod',
        icon: <img src={Quod} alt="Quod" />,
        type: ItemTypes.BEAUREAUS,
      },
      {
        id: 3,
        title: 'Análise Boa Vista',
        icon: <img src={BoaVista} alt="BoaVista" />,
        type: ItemTypes.BEAUREAUS,
      },
      {
        id: 4,
        title: 'Análise Serasa',
        icon: <img src={Serasa} alt="Serasa" />,
        type: ItemTypes.BEAUREAUS,
      },
    ],
  },
  {
    id: 2,
    mainTitle: 'Etapas customizáveis',
    items: [
      {
        id: 5,
        title: 'Garantia de veículo',
        icon: <RiCarFill />,
        type: ItemTypes.STEPSAVAILABLE,
      },
      {
        id: 6,
        title: 'Valor de entrada',
        icon: <RiMoneyDollarBoxFill />,
        type: ItemTypes.STEPSAVAILABLE,
      },
      {
        id: 7,
        title: 'Análise PEP',
        icon: <RiUserSearchFill />,
        type: ItemTypes.ENTRYFUNNEL,
      },
      {
        id: 8,
        title: 'Parametrização de score',
        icon: <RiMedalFill />,
        type: ItemTypes.ENTRYFUNNEL,
      },
      {
        id: 9,
        title: 'Campos customizados',
        icon: <RiListSettingsLine />,
        type: ItemTypes.STEPSAVAILABLE,
      },
      {
        id: 12,
        title: 'Garantia de imóvel',
        icon: <RiHome3Fill />,
        type: ItemTypes.STEPSAVAILABLE,
      },
      {
        id: 13,
        title: 'Parametrização de localização',
        icon: <RiMapPin2Fill />,
        type: ItemTypes.STEPSAVAILABLE,
      },
    ],
  },
] as WorkflowItemProps[];

export const initialWorkflowItems = [
  {
    id: 0,
    name: 'Análise de informações do cliente',
    type: ItemTypes.BEAUREAUS,
    items: [],
  },
  {
    id: 1,
    name: 'Funil de entrada de cliente',
    type: ItemTypes.ENTRYFUNNEL,
    items: [],
  },
  {
    id: 2,
    name: 'Etapas customizáveis',
    type: ItemTypes.STEPSAVAILABLE,
    items: [
      {
        id: 14,
        title: 'Dados da operação',
        icon: <RiMapPin2Fill />,
        type: ItemTypes.STEPSAVAILABLE,
        default: true,
      },
      {
        id: 15,
        title: 'Dados do cliente',
        icon: <RiMapPin2Fill />,
        type: ItemTypes.STEPSAVAILABLE,
        default: true,
      },
      {
        id: 16,
        title: 'Endereço',
        icon: <RiMapPin2Fill />,
        type: ItemTypes.STEPSAVAILABLE,
        default: true,
      },
      {
        id: 17,
        title: 'Anexos/documentos',
        icon: <RiMapPin2Fill />,
        type: ItemTypes.STEPSAVAILABLE,
        default: true,
      },
    ],
  },
  {
    id: 3,
    name: 'Etapas obrigatórias',
    type: ItemTypes.MANDATORYSTEPS,
    items: [
      {
        id: 10,
        title: 'Checklist',
        icon: <RiListSettingsLine />,
        type: ItemTypes.MANDATORYSTEPS,
        required: true,
      },
      {
        id: 11,
        title: 'Confirmação de dados',
        icon: <RiListSettingsLine />,
        type: ItemTypes.MANDATORYSTEPS,
        required: true,
      },
    ],
  },
];
