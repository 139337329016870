import React, {
  ReactElement, useEffect, useState, useMemo,
} from 'react';

import {
  RiCheckLine,
  RiDeleteBin5Line,
  RiEdit2Line,
  RiFile2Line,
  RiFilePdfLine,
  RiFileWordLine,
  RiFileExcelLine,
  RiImage2Line,
} from 'react-icons/ri';
import { InfoMode } from '../../../@types/InfoMode';

import Button from '../../Button';
import ExcludeModal from '../../ExcludeModal';
import { Props } from './ListDocument.spec';

import {
  BoxDocument,
  BoxDocumentContent,
  EditName,
  ActionsButtons,
} from './styles';

const ListDocument = ({ file, onRemove, mode }: Props): ReactElement => {
  const [editable, setEditable] = useState<boolean>(false);
  const [docName, setDocName] = useState('');
  const [removeDoc, setRemoveDoc] = useState(false);

  function formatBytes(bytes: any): string {
    if (!bytes) return '0 Bytes';
    const k = 1024;
    const dm = 2;
    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }

  useEffect(() => {
    setDocName(file.filename);
  }, [file.filename]);

  const icon = useMemo(() => {
    if (file.mimeType === 'application/pdf') {
      return <RiFilePdfLine className="red-icon" />;
    } if (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.mimeType === 'application/msword') {
      return <RiFileWordLine className="blue-icon" />;
    } if (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.mimeType === 'application/vnd.ms-excel') {
      return <RiFileExcelLine className="green-icon" />;
    } if (file.mimeType.match(/^image\//)) {
      return <RiImage2Line />;
    }
    return <RiFile2Line />;
  }, [file.mimeType]);

  return (

    <BoxDocument key={file.id}>
      {icon}
      <BoxDocumentContent>
        {editable ? (

          <EditName>
            <input
              type="text"
              onChange={(e) => setDocName(e.target.value)}
              value={docName}
            />

            <RiCheckLine onClick={() => setEditable(false)} />
          </EditName>

        ) : (

          <EditName>
            <p>
              {file.filename }
            </p>
            <RiEdit2Line onClick={() => setEditable(true)} />
          </EditName>

        )}

        <small>{formatBytes(file.size)}</small>
      </BoxDocumentContent>

      <ActionsButtons>
        {/* // Waiting for a story to enable button // */}
        {/* <Button type="button" icon={<RiEyeLine />} message="" variant="secondary" /> */}

        {mode !== InfoMode.VIEW && (
          <Button type="button" icon={<RiDeleteBin5Line />} message="" className="delete" onClick={() => setRemoveDoc(true)} />
        )}
      </ActionsButtons>

      {removeDoc && (
      <ExcludeModal
        handleCloseModal={() => setRemoveDoc(false)}
        handleDeleteConfirmation={() => onRemove(file)}
        type="document"
        visibleValue={file.filename}
      />
      )}
    </BoxDocument>

  );
};

export default ListDocument;
