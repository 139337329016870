import React from 'react';
import DatePicker from 'react-datepicker';

import ErrorMessage from '../ErrorMessage';
import { InputDateProps } from './DateInput.spec';

import { Container } from './styles';

const InputDate = ({
  label = '',
  field,
  required,
  className,
  message,
  ...inputProps
}: InputDateProps): any => (
  <Container className={className}>
    <label>
      {label}
      {' '}
      {required && (
      <small>
        (obrigatório)
      </small>
      )}

    </label>
    <DatePicker
      {...inputProps}
      {...field}
      dateFormat="dd/MM/yy"
    />
    <ErrorMessage message={message} />
  </Container>
);

InputDate.defaultProps = {
  field: {},
};

export default InputDate;
