import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const CustomizableSteps = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    margin: 0 0 24px 0;

    > small {
      ${theme.texts.paragraphSM};
      color: ${theme.colors.black00};
      margin: 0 0 14px 0;
    }
  `};
`;

export const CustomizableStepItems = styled.div`
  width: 100%;
`;

export const DraggableBox = styled.div<{ isActive?: boolean }>`
  ${({ theme, isActive }) => css`
    background-color: ${theme.colors.info05};
    border: 1px dashed ${theme.colors.info05};
    border-radius: 6px;
    height: 58px;
    margin: 0 0 24px 0;
    transition: ${theme.transitions.default};

    display: flex;
    align-items: center;
    justify-content: center;

    > h3 {
      ${theme.texts.buttonSM};
      color: ${theme.colors.black00};
    }

    ${isActive
    && css`
      border-color: ${theme.colors.info00};
    `};
  `};
`;

export const EmptySteps = styled.div`
  ${({ theme }) => css`
    border-radius: 6px;
    background-color: ${theme.colors.black05};
    border-radius: 6px;
    height: 58px;
    margin: 0 0 24px 0;
    transition: ${theme.transitions.default};

    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      color: ${theme.colors.black00};
      ${theme.texts.paragraphMD};
    }
  `};
`;
