import React, {
  ReactElement, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { RiAddFill, RiCheckboxCircleFill, RiCloseCircleFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Button, DynamicList } from '../../../../../components';

import { PartnerContext } from '../../AgencyOfficeInfo';

import HandshakeIcon from '../../../../../assets/HandshakeIcon.svg';

import {
  Container, HeaderBox, BoxLeft, BoxRight, NoContent,
} from './styles';
import { AgentDTO, AgentSDTO } from '../../../../../@types';
import FormBusinessPartner from './FormAgent';
import { getInitialValues } from './Agent.spec';
import { InfoMode } from '../../../../../@types/InfoMode';
import { AppContext } from '../../../../../App';
import { agentService } from '../../../../../services';
import TooltipComponent from '../../../../../components/Tooltip';

const Agent = (): ReactElement => {
  const { mode } = useContext(PartnerContext);
  const { id: agentID } = useParams();
  const { setLoading } = useContext(AppContext);
  const { t } = useTranslation();

  const [agentList, setAgentList] = useState<AgentSDTO[]>([]);
  const [agent, setAgent] = useState<AgentDTO>({});

  const initialValues = useMemo(
    () => getInitialValues(agent, agentID ? +agentID : 0),
    [agent, agentID],
  );

  const fetchAgent = useCallback(async () => {
    setLoading(true);
    try {
      if (agentID && +agentID) {
        const { content } = await agentService.getPageByAgentID(+agentID);
        setAgentList(content);
      }
    } catch (err: any) {
      console.log(err);
      toast.error('Não foi possível carregar os dados', {
        className: 'toast-message',
        icon: RiCloseCircleFill,
        containerId: 'close-on',
      });
    } finally {
      setLoading(false);
    }
  }, [agentID, setLoading]);

  useEffect((): void => {
    if (mode !== InfoMode.CREATE) {
      fetchAgent();
    }
  }, [mode, fetchAgent]);

  const formik = useFormik<AgentDTO>({
    enableReinitialize: true,
    initialValues,
    onSubmit(values) {
      console.log(values);
    },
  });

  async function removeAgent(
    item: AgentSDTO,
  ): Promise<void> {
    setLoading(true);
    try {
      await agentService.delete(item?.id);
      fetchAgent();
      toast.success('Agente removida com sucesso!', {
        className: 'toast-message',
        icon: RiCheckboxCircleFill,
        containerId: 'close-on',
      });
    } catch (error) {
      console.log(error);
      toast.error('Não foi possível remover o agente!', {
        className: 'toast-message',
        icon: RiCloseCircleFill,
        containerId: 'close-on',
      });
    } finally {
      setLoading(false);
    }
  }

  const onCancel = (itemID: any): void => {
    if (itemID > 0) {
      formik.resetForm();
    }
  };

  async function edit(itemID: number): Promise<void> {
    const data = await agentService.getOne(itemID);
    setAgent(data);
  }

  async function onSubmitAgent(itemID?: number): Promise<void> {
    const data = formik.values;
    const newItem = formik.initialValues;
    try {
      setLoading(true);
      if (itemID) {
        await agentService.update(itemID, {
          ...data,
        });
        toast.success('Agente editado com sucesso!', {
          className: 'toast-message',
          icon: RiCheckboxCircleFill,
          containerId: 'close-on',
        });
      } else {
        await agentService.create({
          ...newItem,
          person: { fullName: `Agente ${agentList.length + 1}` },
        });
        toast.success('Agente criado com sucesso!', {
          className: 'toast-message',
          icon: RiCheckboxCircleFill,
          containerId: 'close-on',
        });
      }
      fetchAgent();
    } catch (error) {
      console.log(error);
      toast.error('Erro!', {
        className: 'toast-message',
        icon: RiCloseCircleFill,
        containerId: 'close-on',
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <HeaderBox>
        <BoxLeft>
          <h2>{t('partnerAgent.titleAgent')}</h2>
          <p>
            <span>{agentList?.length || 0}</span>
            {' '}
            {t('partnerAgent.textFoundAgent')}
            {' '}
          </p>
        </BoxLeft>
        <BoxRight>
          {mode === InfoMode.VIEW ? (
            <TooltipComponent
              text="Você está no modo de visualização, para editar os dados desse agente, clique em “Editar”."
              tooltip={(
                <Button
                  type="button"
                  message="Novo sócio/representante"
                  icon={<RiAddFill />}
                  onClick={() => onSubmitAgent()}
                  disabled={mode === InfoMode.VIEW}
                />
)}
            />
          ) : (
            <Button
              type="button"
              message="Novo sócio/representante"
              icon={<RiAddFill />}
              onClick={() => onSubmitAgent()}
            />
          )}

        </BoxRight>
      </HeaderBox>
      {agentList?.length > 0 ? (
        <>
          {agentList?.map((item: any, index: any) => (
            <DynamicList
              item={item}
              key={item.id}
              onRemove={() => removeAgent(item)}
              onSubmit={() => onSubmitAgent(item.id)}
              onCancel={() => onCancel(item.id)}
              edit={() => edit(item.id)}
              mode={mode}
              type="agent"
              visibleValue={item.name}
            >
              <FormBusinessPartner index={index} formik={formik} mode={mode} />

            </DynamicList>
          ))}
        </>
      ) : (
        <NoContent>
          <img src={HandshakeIcon} alt="Handshake Icon" />
          <h2>{t('partnerAgent.titleNoAgent')}</h2>
          <p>{t('partnerAgent.textNoAgent')}</p>
        </NoContent>
      )}
    </Container>
  );
};

export default Agent;
