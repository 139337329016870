import styled from 'styled-components';

export const Container = styled.div`
  div {
    display: flex;
    align-items: center;

    > svg {
      margin-left: 8px;
    }
  }
`;
