import { useFormik } from 'formik';
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiCloseFill,
  RiInformationFill,
  RiMoneyDollarCircleLine,
} from 'react-icons/ri';

import { toast } from 'react-toastify';
import {
  getInitialValues,
  ProductContextValue,
  ProductInfoProps,
} from './productInfo.spec';

import { InfoMode } from '../../../../@types/InfoMode';
import { ProductDTO } from '../../../../@types/Product';
import { TabElementProps } from '../../../../@types/TabNavigation';
import {
  AnalysisCostModal,
  Breadcrumb,
  Button,
  TabNavigation,
} from '../../../../components';
import { productsBreadCrumbItems } from '../../../../mock';
import { productService } from '../../../../services';
import ProductVariant from '../Breadcrumb/ProductVariant';
import ProductData from '../Breadcrumb/ProductData';
import Workflow from '../Breadcrumb/Workflow';
import {
  ActionsButton,
  Container,
  CostControlButton,
  Divider,
  Form,
  Header,
} from './styles';
import { AppContext } from '../../../../App';

export const ProductContext = createContext({} as ProductContextValue);

const ProductInfo = ({ mode }: ProductInfoProps): ReactElement => {
  const { t } = useTranslation();
  const { id: productID } = useParams();
  const { setLoading } = useContext(AppContext);

  const [product, setProduct] = useState<ProductDTO | any>();

  const [visibleCostModal, setVisibleCostModal] = useState<boolean>(false);

  const initialValues = useMemo(() => getInitialValues(product), [product]);
  const navigate = useNavigate();
  const tabNavigationItemsProducts = [
    {
      title: 'Dados do produto',
      element: <ProductData />,
      enabled: true,
    },
    {
      title: 'Parametrização',
      element: <ProductVariant />,
      enabled: mode !== InfoMode.CREATE,
    },
    {
      title: 'Workflow',
      element: <Workflow />,
      enabled: mode !== InfoMode.CREATE,
    },
  ] as TabElementProps;

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (productID && +productID) {
        const response = await productService.getOne(+productID);
        setProduct(response);
      }
    } catch (err: any) {
      console.log(err);
      toast.error('Não foi possível carregar os dados', {
        className: 'toast-message',
        icon: RiCloseCircleFill,
        containerId: 'close-on',
      });
    } finally {
      setLoading(false);
    }
  }, [productID, setLoading]);

  useEffect((): void => {
    if (mode !== InfoMode.CREATE) {
      fetchData();
    }
  }, [mode, fetchData]);

  const formik = useFormik<ProductDTO>({
    initialValues,
    enableReinitialize: true,
    async onSubmit(values) {
      if (mode === InfoMode.CREATE) {
        try {
          const response: any = await productService.create(values);
          toast.success('Produto criado com sucesso!', {
            className: 'toast-message',
            icon: RiCheckboxCircleFill,
            containerId: 'close-on',
          });
          navigate(`/registration/products/edit/${response?.id}`);
        } catch (error) {
          console.log(error);
          toast.error('Não foi possível criar o produto', {
            className: 'toast-message',
            icon: RiCloseCircleFill,
            containerId: 'close-on',
          });
        }
      } else {
        const productData = Object.entries(values).reduce(
          (acc, [key, value]) => {
            if (initialValues[key as keyof ProductDTO] !== value) {
              return {
                ...acc,
                [key]: value,
              };
            }
            return acc;
          },
          {} as Partial<ProductDTO>,
        );

        try {
          if (productID && +productID) {
            await productService.update(+productID, productData);
            toast.success('Produto editado com sucesso!', {
              className: 'toast-message',
              icon: RiCheckboxCircleFill,
              containerId: 'close-on',
            });
            navigate('/registration/products');
          }
        } catch (err: any) {
          console.log(err);
          toast.error('Não foi possível editar o produto', {
            className: 'toast-message',
            icon: RiCloseCircleFill,
            containerId: 'close-on',
          });
        }
      }
    },
  });

  return (
    <Container>
      <Header>
        <h1>
          <Breadcrumb breadCrumbItems={productsBreadCrumbItems} />
          {t(`productInfo.${mode}.title`)}
        </h1>
        <Link to="/registration/products">
          <RiCloseFill />
        </Link>
      </Header>

      <ProductContext.Provider
        value={{
          formik,
          mode,
        }}
      >
        <Form onSubmit={formik.handleSubmit}>
          <TabNavigation tabNavigationItems={tabNavigationItemsProducts} />
          <Divider />
          {' '}
          <ActionsButton>
            <p>
              <RiInformationFill />
              {' '}
              Em Workflow, você define quais atributos são
              ou não necessários na inclusão de uma operação que contenha esse
              produto.
            </p>
            <CostControlButton
              onMouseOver={() => setVisibleCostModal(true)}
              onMouseLeave={() => setVisibleCostModal(false)}
            >
              <button type="button">
                <RiMoneyDollarCircleLine />
              </button>
              {visibleCostModal && (
                <AnalysisCostModal
                  handleCloseModal={() => setVisibleCostModal(true)}
                />
              )}
            </CostControlButton>

            <>
              {mode !== InfoMode.VIEW ? (
                <Button
                  type="submit"
                  message={t(`productInfo.${mode}.button`)}
                />
              ) : (
                <Link to={`/registration/products/edit/${productID}`}>
                  {t(`productInfo.${mode}.button`)}
                </Link>
              )}
            </>
          </ActionsButton>
        </Form>
      </ProductContext.Provider>
    </Container>
  );
};

export default ProductInfo;
