import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ScoreContext } from '..';
import { InputCurrency } from '../..';
import { selectService } from '../../../services';
import InputControl from '../../Input';
import SelectField from '../../Select';
import { FormScoreParameterizationProps } from './FormScoreParameterizations.spec';

import {
  Container, ContentForm, InputRow, InputSolo,
} from './styles';

const FormScoreParameterizations = ({
  index,
  parameterization,
}: FormScoreParameterizationProps): ReactElement => {
  const { t } = useTranslation();
  const { formik } = useContext(ScoreContext);
  const [selectGender, setSelectGender] = useState();

  useEffect(() => {
    setSelectGender(selectService.getGender());
  }, []);

  function fieldName(name: string): string {
    return `scoreParameterizations[${index}].${name}`;
  }

  return (
    <Container>
      <ContentForm>
        <InputSolo>
          <InputControl
            name={fieldName('name')}
            label={t('scoreParameterizations.labelInputName') || ''}
            placeholder={t('scoreParameterizations.placeholderInputName') || ''}
            onChange={formik.handleChange}
            value={parameterization.name}
          />
        </InputSolo>
        <InputRow>
          <InputControl
            name={fieldName('scoreMinium')}
            type="number"
            label={t('scoreParameterizations.labelInputMinimumScore') || ''}
            placeholder={
              t('scoreParameterizations.placeholderInputMinimumScore') || ''
            }
            onChange={formik.handleChange}
            value={parameterization.scoreMinimum}
          />
          <InputControl
            name={fieldName('scoreMaximum')}
            type="number"
            label={t('scoreParameterizations.labelInputMaximumScore') || ''}
            placeholder={
              t('scoreParameterizations.placeholderInputMaximumScore') || ''
            }
            onChange={formik.handleChange}
            value={parameterization.scoreMaximum}
          />
        </InputRow>
        <InputRow>
          <SelectField
            name={fieldName('classification')}
            label={t('scoreParameterizations.labelInputClassification') || ''}
            value={parameterization.classification}
            onChange={(selectedOption: any) => formik.setFieldValue(fieldName('classification'), selectedOption)}
            options={selectGender}
          />
          <InputCurrency
            name={fieldName('fee')}
            label={t('scoreParameterizations.labelInputRate') || ''}
            prefix="%"
            value={parameterization.fee}
            onStringChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
          />
        </InputRow>
        <InputRow>
          <InputCurrency
            name={fieldName('maximumLoanAmount')}
            label={
              t('scoreParameterizations.labelInputMaximumLoanAmount') || ''
            }
            value={parameterization.maximumLoanAmount}
            onStringChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
          />
          <SelectField
            name={fieldName('operationEntryStatus')}
            label={
              t('scoreParameterizations.labelInputOperationEntryStatus') || ''
            }
            value={parameterization.operationEntryStatus}
            onChange={(selectedOption: any) => formik.setFieldValue(fieldName('operationEntryStatus'), selectedOption)}
            options={selectGender}
          />
        </InputRow>
      </ContentForm>
    </Container>
  );
};

export default FormScoreParameterizations;
