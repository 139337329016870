import styled, { css, keyframes } from 'styled-components';

export const Container = styled.button<{ variant?: string }>`
  ${({ theme, variant }) => css`
    padding: 10px 20px;
    width: auto;
    border-radius: 10px;
    max-height: 60px;
    ${theme.texts.defaultBoldButton};
    transition: ${theme.transitions.default};
    position: relative;
    border: 0;

    ${variantStyles(variant)};

    display: flex;
    align-items: center;
  `};
`;

export const LoadingCircle = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 16px;

    padding: 1.2px;
    border-radius: 50%;
    display: inline-block;
    background: conic-gradient(
      from 180deg at 50% 50%,
      #fff 0deg,
      rgba(255, 255, 255, 0) 360deg
    );

    transition: ${theme.transitions.default};
    animation: ${spinAnimation} 0.6s linear infinite;
  `}
`;

export const Circle = styled.div`
  ${({ theme }) => css`
    width: 12px;
    height: 12px;
    background-color: ${theme.colors.black00};
    border-radius: 50%;
    transition: ${theme.transitions.default};
  `}
`;

export const Icon = styled.div<{ position?: any }>`
  ${({ position }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;

    ${positionStyles(position)};

    > svg {
      font-size: 14px;
    }
  `};
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
`;

const variantStyles = (variant = 'primary'): any => ({
  primary: css`
      background-color: ${({ theme }) => theme.colors.black00};
      color: ${({ theme }) => theme.colors.white00};

      :hover {
        background-color: ${({ theme }) => theme.colors.black80};

        ${Circle} {
          background-color: ${({ theme }) => theme.colors.white30};
        }
      }

      :active {
        background-color: ${({ theme }) => theme.colors.black50};
        color: ${({ theme }) => theme.colors.light50};

        ${Circle} {
          background-color: ${({ theme }) => theme.colors.white30};
        }
      }

      :disabled {
        background-color: ${({ theme }) => theme.colors.black50};
        color: ${({ theme }) => theme.colors.light50};
        cursor: not-allowed;

        ${Circle} {
          background-color: ${({ theme }) => theme.colors.white30};
        }
      }
    `,
  secondary: css`
      background-color: ${({ theme }) => theme.colors.white00};
      border: 1px solid ${({ theme }) => theme.colors.black20};
      color: ${({ theme }) => theme.colors.black00};

      ${LoadingCircle} {
        background: conic-gradient(
          from 180deg at 50% 50%,
          #000 0deg,
          rgba(0, 0, 0, 0) 360deg
        );
      }

      ${Circle} {
        background-color: ${({ theme }) => theme.colors.white00};
      }

      :hover {
        border: 1px solid ${({ theme }) => theme.colors.black00};
      }

      :active {
        border: 1px solid ${({ theme }) => theme.colors.black00};
      }

      :disabled {
        border: 1px solid ${({ theme }) => theme.colors.black30};
        background-color: ${({ theme }) => theme.colors.white00};
        color: ${({ theme }) => theme.colors.black30};
        cursor: not-allowed;
      }
    `,
}[variant]);

const positionStyles = (position: 'right'): any => ({
  right: css`
      right: 16px;
    `,
  left: css`
      left: 16px;
    `,
}[position]);
