import React, { ReactElement } from 'react';

import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { Container } from './styles';

interface Props {
  text: string;
  tooltip: any;
}

const TooltipComponent = ({ text, tooltip }: Props): ReactElement => {
  const styles = {
    borderColor: 'red',
    marginLeft: 0,
  };
  return (
    <Container>
      <Tooltip
        placement="bottom"
        trigger={['hover']}
        overlay={<span>{text}</span>}
        overlayClassName="tooltip-component"
        overlayStyle={styles}
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
      >
        <div>{tooltip}</div>
      </Tooltip>
    </Container>
  );
};

export default TooltipComponent;
