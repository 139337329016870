export interface Pageable {
  pageNumber?: number;
  pageSize?: number;
  sort?: Sort;
  paged?: boolean;
  unpaged?: boolean;
}

export interface Sort {
  orders: Array<SortOrder>;
  sorted?: boolean;
  unsorted?: boolean;
  empty?: boolean;
}

export interface SortOrder {
  direction: SortDirection;
  column: string;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface FindOptions {
  pageNumber?: number;
  pageSize?: number;
  sort?: string;
  filters? : Record<string, any>;
  searchString?: string;
}

export type Page<T extends Record<string, any>> = {
  content: Array<T>;
  pageable?: Pageable;
  totalElements?: number;
  number?: number;
  size?: number;
  numberOfElements?: number;
  totalPages?: number;
  first?: boolean;
  last?: boolean;
}

export interface CoreDTO {
  id?: number;
}

export interface BaseDTO extends CoreDTO {
  createdAt?: string;
  updatedAt?: string;
  enabled?: boolean;
}
