import { SummaryItemProps } from '../@types';

const summaryItems: SummaryItemProps[] = [
  {
    id: 0,
    title: 'Em análise',
    number: 2,
    value: 'R$ 15.000,00',
  },
  {
    id: 1,
    title: 'Pendente',
    number: 2,
    value: 'R$ 381.800,00',
  },
  {
    id: 2,
    title: 'Rec. Alçada',
    number: 121,
    value: 'R$ 4.100.000,00',
  },
  {
    id: 3,
    title: 'Cancelada',
    number: 29,
    value: 'R$ 944.700,00',
  },
  {
    id: 4,
    title: 'Pago',
    number: 252,
    value: 'R$ 60.000,00',
  },
];

export default summaryItems;
