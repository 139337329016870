import { WorkflowTableProps } from '../@types';
import { workflowTableItems } from '../mock';

export default {
  get(): WorkflowTableProps[] | void {
    try {
      return workflowTableItems;
    } catch (error) {
      return console.log('Unable to list representative items!');
    }
  },
};
