import styled, { css } from 'styled-components';

interface ContainerProps {
  toggled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${({ theme }) => css`
    background-color: ${theme.colors.white00};
    flex: 1;
  `};
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h1 {
      color: ${theme.colors.black00};
      letter-spacing: -0.01em;
      line-height: 3rem;
      font-size: 2.4rem;
      font-weight: 600;

      display: flex;
      flex-direction: column;

      > small {
        font-weight: 400;
        opacity: 0.5;
        font-size: 1.3rem;
        line-height: 1.6rem;
        margin-bottom: 5px;
      }
    }

    > button {
      border: 1px solid rgba(20, 18, 30, 0.1);
      border-radius: 4px;
      height: 36px;
      color: ${theme.colors.black00};
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.6rem;
      padding: 10px 18px;
      background-color: transparent;
      margin-top: auto;
    }
  `};
`;

export const InfoCardsList = styled.div`
  margin: 26px 0;

  display: flex;
  flex-wrap: wrap;
  gap: 18px;
`;

export const MainTable = styled.div`
  margin-top: 26px;
`;

export const MainTableHeader = styled.div`
  ${({ theme }) => css`
    background: rgba(20, 18, 30, 0.05);
    border-radius: 4px;
    height: 64px;
    color: ${theme.colors.black00};
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;
