import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  flex: 1;

  display: flex;
  flex-direction: column;
`;

export const MainTableHeader = styled.div`
  ${({ theme }) => css`
    background: rgba(20, 18, 30, 0.05);
    border-radius: 4px;
    height: 64px;
    color: ${theme.colors.black00};
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 600;
    padding: 0 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;

export const MainTableHeaderItem = styled.div`
  ${({ theme }) => css`
    /* text-align: center; */
    flex: 1;
    ${theme.texts.headingXXXS};
  `};
`;

export const MainTableContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainTableContentRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    height: 54px;
    margin: 18px 0 0 0;
    position: relative;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;
    padding: 0 24px;

    &:hover {
      background-color: ${theme.colors.black05};
      border-color: ${theme.colors.black10};
    }
  `};
`;

export const MainTableContentRowItem = styled.div`
  ${({ theme }) => css`
    flex: 1;
    /* text-align: center; */
    color: ${theme.colors.black00};
    ${theme.texts.paragraphXS};
  `};
`;

export const MainTableActionsTab = styled.div`
  ${({ theme }) => css`
    border-radius: 4px;
    padding: 0 24px 0 12px;
    background-color: ${theme.colors.black05};

    display: flex;
    align-items: center;
    justify-content: flex-end;

    position: absolute;
    right: 0;

    > svg {
      cursor: pointer;
    }

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      
      margin: 0 6px 0 0;
      border-radius: 4px;
      width: 30px;
      height: 30px;
      border: 1px solid ${theme.colors.black10};
      background-color: transparent;
      font-size: 1.2rem;
      color: ${theme.colors.black00};

      transition: ${theme.transitions.default};

      &:hover {
        border-color: ${theme.colors.black00};
      }

      &:last-child {
        color: ${theme.colors.white00};
        background-color: ${theme.colors.error00};
        border-color: ${theme.colors.error00};

        &:hover {
          opacity: 0.8;
        }
      }
    }

    > button {
      margin: 0 6px 0 0;
      border-radius: 4px;
      width: 30px;
      height: 30px;
      border: 1px solid ${theme.colors.black10};
      background-color: transparent;
      font-size: 1.2rem;
      color: ${theme.colors.black00};

      transition: ${theme.transitions.default};

      &:hover {
        border-color: ${theme.colors.black00};
      }

      &:last-child {
        color: ${theme.colors.white00};
        background-color: ${theme.colors.error00};
        border-color: ${theme.colors.error00};

        &:hover {
          opacity: 0.8;
        }
      }
    }
  `};
`;

export const EmptyTableState = styled.div`
  ${({ theme }) => css`
    margin: 18px 0 0 0;
    padding: 44px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > img {
      max-width: 148px;
      width: 100%;
      margin: 0 0 24px 0;
    }

    > h4 {
      margin: 0 0 8px 0;
      ${theme.texts.buttonLG};
      color: ${theme.colors.black00};
      letter-spacing: -0.2px;
    }

    > p {
      ${theme.texts.paragraphMD};
      color: ${theme.colors.black00};
    }
  `};  
`;
