import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RiSearchLine } from 'react-icons/ri';

import {
  Navbar,
  Button,
  Breadcrumb,
  Table,
} from '../../../components';

import { workflowService } from '../../../services';

import {
  workflowTableHeaderTitles,
  workflowTableContentKeys,
  workflowBreadCrumbItems,
} from '../../../mock';

import {
  Container, Header, SubHeader, SearchInput,
} from './styles';

import { WorkflowTableProps } from '../../../@types';

const Workflow: React.FC = () => {
  const [workflows, setWorkflows] = useState<WorkflowTableProps[] | any>([]);

  useEffect(() => {
    setWorkflows(workflowService.get());
  }, []);

  function handleDeleteWorkflows(id: number): void {
    workflows.splice(id, 1);
  }

  return (
    <Container>
      <Header>
        <h1>
          <Breadcrumb breadCrumbItems={workflowBreadCrumbItems} />
          Workflows
        </h1>
        <Navbar />
      </Header>

      <SubHeader>
        <SearchInput>
          <RiSearchLine />
          <input type="text" placeholder="Buscar workflow" />
        </SearchInput>
        <Link to="/">
          <Button message="Novo workflow" />
        </Link>
      </SubHeader>

      <Table
        tableHeader={workflowTableHeaderTitles}
        tableData={workflows}
        tableKeys={workflowTableContentKeys}
        handleDeleteItem={(id: number) => {
          handleDeleteWorkflows(id);
        }}
        excludeModalTexts={{
          type: 'workflow',
          visibleValue: 'title',
        }}
      />
    </Container>
  );
};

export default Workflow;
