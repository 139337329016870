import { FormikProps } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { CoreFile } from '../../../../@types';
import { AgencyOfficeDTO } from '../../../../@types/AgencyOffice';
import { InfoMode } from '../../../../@types/InfoMode';

export interface AgencyOfficeInfoProps {
  mode: InfoMode;
}

export interface PartnerContextValue {
  formik: FormikProps<AgencyOfficeDTO>;
  setDocuments: Dispatch<SetStateAction<CoreFile[]>>;
  documents: CoreFile[];
  mode: InfoMode;
}

export function getInitialValues(agencyOffice: AgencyOfficeDTO): AgencyOfficeDTO {
  return {
    agencyID: agencyOffice?.agencyID || undefined,
    companyID: agencyOffice?.companyID || undefined,
    company: {
      documentNumber: agencyOffice?.company?.documentNumber || undefined,
      legalName: agencyOffice?.company?.legalName || undefined,
      tradeName: agencyOffice?.company?.tradeName || undefined,
      description: agencyOffice?.company?.description || undefined,
      incorporationDate: agencyOffice?.company?.incorporationDate || undefined,
      shareCapital: agencyOffice?.company?.shareCapital || undefined,
      equity: agencyOffice?.company?.equity || undefined,
      stateTaxID: agencyOffice?.company?.stateTaxID || undefined,
      companyRegistrationStatusID: agencyOffice?.company?.companyRegistrationStatusID || undefined,
      employeeCountRangeID: agencyOffice?.company?.employeeCountRangeID || undefined,
    },
    // address: {
    //   postalCode: partner?.address?.postalCode || undefined,
    //   street: partner?.address?.street || undefined,
    //   number: partner?.address?.number || undefined,
    //   complement: partner?.address?.complement || undefined,
    //   neighborhood: partner?.address?.neighborhood || undefined,
    //   city: partner?.address?.city || undefined,
    //   state: partner?.address?.state || undefined,
    // },
    // documents: partner?.documents || [],
    // accessControl: {
    //   accessDays: partner?.accessControl?.accessDays || [],
    //   accessTime: partner?.accessControl?.accessTime || '',
    //   accessExternal: partner?.accessControl?.accessExternal || '',
    //   changeRates: partner?.accessControl?.changeRates || '',
    // },
    // social: {
    //   urlCompany: partner?.social?.urlCompany || '',
    //   urlFacebook: partner?.social?.urlFacebook || '',
    //   urlInstagram: partner?.social?.urlInstagram || '',
    //   urlLinkedIn: partner?.social?.urlLinkedIn || '',
    // },
  };
}
